/**
 * 
 * 
 * NOTES: 
 * - plugins are not expected to have plugins
 */

 class BridgeImpl {
    $receiver = null;
    $events = null;
    receiver = null;
    contentWindow = null;
    channel = null;
    /**
     * IMPORTANT: #BridgeImpl.type refers to whom 
     */
    destination = null;
    destinations = {
        IFRAME: 'iframe',
        POPUP: 'popup',
        WINDOW: 'window'
    };

    methods = {}


    /**
     * 
     * @param {*} receiver What the top page connecting to. The top page may be connection to 
     *                      an iframe in which cases the expected value is CSS selector or 
     *                      an HTMLIFrame element, or it could be and Window instance from the
     *                      iframes or from a modal (popup). When a modal is created, the window
     *                      instance is provided from the top page as well as from the modal
     *                      itself. That means that in both scenarios, top and modal, the 
     *                      provided window object is the same.
     */
    constructor(receiver) {

        let scope = this;
        this.connect(receiver);
        // this.oldConstructor(receiver);

        window.addEventListener('message', function(e) {
            // console.log(window.document.location.href, e.data);
            scope.process(e.data, e);
        });
    }


    /**
     * Where the message is going to be sent.
     * 
     * @param {*} receiver 
     */
    connect(receiver) {
        this.$events = window.$({});
        this.receiver = typeof receiver == 'string'? document.querySelector(receiver) : receiver;

        let channel = Number(new Date());
        let isIFrameContext = parent != window? true : false;
        let isWindowContext = parent == window && window == receiver?.opener? true : false; // true for modal too
        let isModalContext  = receiver?.opener && !isWindowContext? true : false;

        // debugger;


        if( (isModalContext || isIFrameContext) ) {
            this.destination = this.destinations.WINDOW;

            /**
             * Messages will be sent to the top WINDOW
             */
            this.contentWindow = isIFrameContext? window.parent : receiver?.opener;
            this.channel = null;

        // } else if(this.$receiver[0]?.tagName?.toLowerCase() == 'iframe') {
        } else if(this.receiver instanceof HTMLIFrameElement) {
            this.destination = this.destinations.IFRAME;

            /**
             * Messages will be sent to a IFRAME
             */
            this.contentWindow = this.receiver.contentWindow;
            this.channel = channel;

        // } else if(this.receiver instanceof Window || this.receiver?.opener) { // &&  this.receiver instanceof Window
        } else if(this.receiver instanceof Window || this.receiver?.opener) { // &&  
            this.destination = this.destinations.POPUP;

            /**
             * Messages will be sent to a POPUP (MODAL)
             */
            this.contentWindow = this.receiver;
            this.channel = channel;
        }


    }







    setup() {
        this.send('bridge.channel', [this.channel]);
    }

    destroy() {
        if(this.receiver instanceof HTMLIFrameElement) {
            this.receiver.remove();
        }
    }



    /**
     * @private
     */
    whereTo() {
        let dest = this.contentWindow
        // console.log('dest', this.destination, dest?.document?.URL)

        return dest;
    }

    /**
     * @private
     */
    whereFrom() {
        let from = window;
        // console.log('dest', this.destination, from?.document?.URL)
        return from;
    }


    /**
     * 
     * @param {String} method 
     * @param {Array} params Array containing the parameters to be used by @method
     * @returns 
     */
    send(method, params) {
        let token = 'token_' + Math.random();
        let request = {
            method: method,
            params: params, 
            token: token, 
            channel: this.channel
        };

        let message = this.serialize(request);

        // console.log('send to...', this.whereTo().document.URL)
        // console.log('awaiting reply from...', this.whereFrom().document.URL);
        
        this.whereTo().postMessage(message, '*');

        /**
         * Wait for a reply
         */
        return new Promise((res)=>{
            window.addEventListener('message', (e) => {
                let json = e.data;
                let request = this.deserialize(json);
                // console.log('awaiting from...', window.document.URL, request);

                if(request && request?.method == 'bridge.reply' && request?.token == token) {
                    // console.log('resolved...', request)
                    res(request.params[0]);
                }

            }, { once: true });
        });

    }

    /**
     * @private
     * 
     * @param {Object} data Data from the requeted method
     * @param {String} method 
     * @param {String} token Token uses to initiate the request
     * @param {Event} event postMessage event
     */
    reply(data, trigger, token, event) {
        event;
        let scope = this;

        if(data instanceof Promise) {
            data.then(function(dataReady) {
                // console.log('Promise', dataReady);
                let request = {
                    method: 'bridge.reply',
                    trigger: trigger,
                    params: [dataReady], 
                    token: token,
                    channel: scope.channel
                };
                
                let message = scope.serialize(request);
                scope.whereTo().postMessage(message, '*');
            });
        } else {
            let request = {
                method: 'bridge.reply',
                trigger: trigger,
                params: [data], 
                token: token,
                channel: scope.channel
            };
    
            let message = this.serialize(request);

            let whereTo = this.whereTo();
            whereTo.postMessage(message, '*');
        }
    }



    process(json, event) {
        let request = this.deserialize(json);
        if(!this.canHandle(request)) {
            return;
        }

        // console.log('%cChannel', request, json);

        let response = this.methods[request.method].apply(null, request.params);

        if(request?.trigger) {
            /**
             * This is a reply
             */
            this.$events.trigger('bridge.reply.' + request.trigger, [response]);
            // this.$events.trigger('bridge.reply', [response]);
        } else {
            /**
             * on message received
             */
            this.$events.trigger(request.method, [response]);
        }

        if(request?.method != 'bridge.reply') {
            /** 
             * Not a reply, then reply
             */
            this.reply(response, request.method, request.token, event);
        } 
        return response
    }


    /**
     * 
     * @public
     * 
     * @param {String} method 
     * @param {Function} callback 
     * @param {Object} context 
     */
    expose(method, callback, context) {
        if(!this.methods?.[method]) {
            this.methods[method] = function() {
                return callback.apply(context, arguments);
            }
        }
    }

    /**
     * 
     * @param {*} obj 
     * @returns 
     */
    canHandle(request) {
        if(request 
            && request?.method 
            && request.method == 'bridge.channel' 
            && this.channel == null) {
            /**
             * First time stablishing connection (attempting to set channel ID)
             */
            return true;

        } else if(request 
            && request?.method 
            && request.method in this.methods
            && request.channel == this.channel
            ) {
            return true;

        } else {
            return false;
        }
    }

    serialize(obj) {
        return JSON.stringify(obj);
    }

    deserialize(message) {
        let obj = {};
        try {
            obj = JSON.parse(message);
        } catch (e) { obj }
        return obj;
    }
}



/**
 * 
 * @param {String} selector 
 * @param {Object} options
 * @param {Object} options.handlers
 * @param {Function} options.handlers.canHandle
 * @param {Function} options.handlers.serialize
 * @param {Function} options.handlers.deserialize
 * 
 */
const Bridge = {
    create(selector, options = {}) {
        let instance = new BridgeImpl(selector);
        instance.constructor = ()=>{ return '[appcropolis code]'};

        if(options?.handlers) {
            instance = {...instance, ...options.handlers }
        }

        /** Bind events */

        /**
         * If "autoresize" and the receiver is an IFRAME, then bind the 
         * "bridge.height" listener.
         */
        // if( /*options?.autoresize &&*/ instance.$receiver[0]?.tagName?.toLowerCase() == 'iframe') {
        if(instance.receiver instanceof HTMLIFrameElement) {
            let $iframe = window.$(instance.receiver);

            // set the height of the iframe
            instance.$events.bind('bridge.height', function(e, data) {
                $iframe.css('height', data);
            }); 
            
            // set the width of the iframe
            instance.$events.bind('bridge.width', function(e, data) {
                $iframe.css('width', data);
            }); 
            
            // set the width and height of the iframe
            instance.$events.bind('bridge.size', function(e, width, height) {
                // alert (width);
                // alert (height);
                $iframe.css('width', width);
                $iframe.css('height', height);
            }); 
        }

        instance.expose('bridge.reply', function(data) {
            return data;
        });

        instance.expose('bridge.channel', function(channel) {
            instance.channel = channel;
            return channel;
        });

        instance.expose('bridge.height', function(data) {
            return data;
        });

        instance.expose('bridge.width', function(data) {
            return data;
        });

        instance.expose('bridge.size', function(data) {
            return data;
        });

        instance.expose('bridge.ping', function() {
            return {
                time: Number(new Date()),
                url: window.location.href,
                channel: instance.channel
            }
        });

        instance.expose('bridge.resize', function() {
            // console.log('> bridge.resize');
        });

        return instance;
    }, 

    /**
     * Create a brridge from a URL
     * 
     * @param {String} url
     * 
     * @param {Object} options
     * @param {Object} options.mode Whether or the bridge is an IFRAME or a popup.
     *                              Valid values are "popup", "iframe"
     * 
     * @param {Object} options.selector The CSS selector of the container where the IFRAME will 
     *                                  be appended OR the name of the window that will be opened
     *                                  if mode is set to 'popup'
     * 
     * @returns {Promise}
     */
    open(url, options) {
        return new Promise((resolve)=>{
            let iframeName = 'ifr_' + Number(new Date())
            let $iframe = window.$(`<iframe id="${iframeName}" src="${url}"style="position: fixed; top: 0; left: -10000px; displayt: none"></iframe>`);
                $iframe.bind('load', ()=>{
                    let instance = Bridge.create('#' + iframeName, options);
                    resolve(instance);
                });
            window.$('body').append($iframe);
        });
    },

    link(url, request) {
        url, request
    }
}


if (typeof exports === 'object') {
    module.exports = Bridge;
}
// export default Bridge;

