<template>
    <div class="">
        <div class="dropdown d-inline">
            <IconButton class="dropdown-toggle no-carret" 
                icon="apps" 
                data-bs-toggle="dropdown"
                ></IconButton>

            <ul class="dropdown-menu shadow border-minimal dropdown-menu-end border-radius-8 p-3 pb-2 mt-3">
                <span v-for="(item, index) in apps" :key="index" class="d-inline">
                    <li v-if="showItem(item)" class="btn-dial pull-left text-center --w-50 p-2 position-relative">
                        <IconButton class="stretched-link btn-icon" 
                            @click="action(item.action)"
                            :class="[!$route?.params?.opportunity && 'reports' == item.label.toLowerCase()? 'disabled pointer-events-none text-muted' : '']"
                            :icon="item.icon" 
                            :title="item.label"></IconButton>
                        <p class="ellipsis w-100 lh-sm py-2 m-0 icon-label">{{item.label}}</p>
                    </li>
                </span>
            </ul>
        </div>
    </div>
</template>

<script>
import IconButton from "@/components/IconButton.vue";

export default {
    name: 'ButtonUserDropdown', 

    components: {
        IconButton
    },
    
    data() {
        return {
            apps: [
                { label: this.$root.labels.DASHBOARD, icon: 'dashboard', action: 'dashboard', admin: false},
                { label: this.$root.labels.OFFERS, icon: 'attach_money', action: 'opportunities', admin: false},
                { label: 'Content', icon: 'auto_awesome', action: 'strategies', admin: false},
                // { label: 'Library', icon: 'library_books', action: 'library', admin: false},
                //
                { label: 'AI', icon: 'psychology', action: 'ai', admin: false},
                { label: 'Ads', icon: 'ads_click', action: 'ads', admin: false},
                { label: 'Emails', icon: 'forward_to_inbox', action: 'email', admin: false},
                // { label: 'Pages', icon: 'request_page', action: 'pages', admin: false},
                { label: 'Company', icon: 'store', action: 'company', admin: false},
                { label: 'Profile', icon: 'person', action: 'profile', admin: false},
                { label: 'Keys', icon: 'vpn_key', action: 'keys', admin: false},
                //
                { label: 'Extensions', icon: 'extension', action: 'extensions', admin: true},

                { label: 'Forms', icon: 'list_alt', action: 'forms', admin: true},
                { label: 'Forms (S)', icon: 'list_alt', action: 'formsStatic', admin: true},
                { label: 'Apps', icon: 'slideshow', action: 'apps', admin: true},
                { label: 'Reports', icon: 'pie_chart', action: 'reports', admin: true},
                { label: 'Workbooks', icon: 'library_books', action: 'workbook', admin: true},
                { label: 'Admin', icon: 'admin_panel_settings', action: 'admin', admin: true},
                // { label: 'Orders', icon: 'receipt_long', action: 'orders', admin: false},
            ] 
        };
    }, 

    computed: {
        user() {
            return this.$store.getters.user;
        },

        profileImgURL() {
            let url = "https://dummyimage.com/36x36/fd7e14/FFFFFF?text=";
            if (this.user?.ID != null) {
                url += this.user?.user_login?.toUpperCase().substr(0, 1);
            }
            return url;
        },
    }, 


    methods: {
        action(name) {
            let path = null;
            switch (name) {
                case 'dashboard':
                    path = this.$root.links.DASHBOARD;
                    break;

                case 'opportunities':
                    path = this.$root.links.MY_OPPORTUNITIES;
                    break;

                case 'strategies':
                    path = this.$root.links.LIBRARY_STRATEGIES_AI;
                    break;

                case 'apps':
                    path = this.$root.links.APPS;
                    break;

                case 'library':
                    path = '/library/opportunities/all';
                    break;

                case 'keys':
                    path = this.$root.links.SETTINGS_KEYS;
                    break;

                case 'company':
                    path = this.$root.links.SETTINGS_COMPANY;
                    break;

                case 'profile':
                    path = this.$root.links.SETTINGS_PROFILE;
                    break;

                case 'pages':
                    path = `/library/strategies/sales-pages`;
                    break;

                case 'ai':
                    path = this.$root.links.LIBRARY_STRATEGIES_AI;
                    break;

                case 'ads':
                    path = this.$root.links.LIBRARY_STRATEGIES_ADS;
                    break;

                case 'email':
                    path = this.$root.links.LIBRARY_STRATEGIES_EMAILS;
                    break;

                case 'reports':
                    if(this.$route?.params?.opportunity) {
                        path = `/reports/opportunities/${this.$route?.params?.opportunity}/swot`;
                    }
                    break;

                case 'formsStatic':
                    path = `/forms_static/company`;
                    break;

                case 'forms':
                    path = `/forms`;
                    break;

                case 'orders':
                    path = `/orders`;
                    break;

                case 'extensions':
                    path = this.$root.links.EXTENSIONS;
                    break;

                case 'workbook':
                    path = this.$root.links.WORKBOOKS;
                    break;

                case 'admin':
                    path = this.$root.links.ADMIN;
                    break;

                default:
                    break;
            }
            path && this.$router.push( { path: path } );
        }, 

        showItem(item) {
            let show = false;
            if(item.admin === false) {
                show = true;
            } else if(item.admin === true && this.$store.getters.user?.is_admin) {
                show = true;
            }
            return show;
        }
    },

    created() {
    },

    async mounted() {
        let dropdown = this.$el?.querySelector('.dropdown');
        dropdown.addEventListener('shown.bs.dropdown', async ()=> {
            // console.log('dropdown shown');
        });
    }
}
</script>

<style lang="css" scoped>

.dropdown-menu {
    width: 335px;
    transform: translateX(48px);
}

.btn-dial {
    width: 100px;
}
.btn-dial .btn-icon {
    zoom: 1.2;
}

.btn-dial .icon-label {
    font-size: 15px;
}



.btn-dial:hover > a:not(.disabled) {
    color: #fff !important;
    background-color: var(--primary);
}

.dropdown-toggle.show  {
    color: #fff !important;
    background-color: var(--secondary);
}


.dropdown-menu .dropdown-item-last:hover {
   background-color: transparent !important;
}

.dropdown-item.disabled {
    opacity: 0.5;
}

</style>