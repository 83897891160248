<template>
	<span>
		<svg :width="size" :height="size" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="127" height="127" rx="15.5" fill="white" stroke="#EBEBEB"></rect><rect x="11.5" y="11.5" width="105" height="105" rx="7.5" stroke="#E4E6EB" stroke-dasharray="3 3"></rect><line x1="5.35355" y1="5.64645" x2="122.354" y2="122.646" stroke="#E4E6EB" stroke-dasharray="3 3"></line><line x1="4.64645" y1="122.646" x2="122.646" y2="4.64645" stroke="#E4E6EB" stroke-dasharray="3 3"></line></svg>
	</span>
</template>

<script>
export default {
	name: 'PlaceholderBox',

	props: {
		size: {
			type: Number,
			default() {
				return 100;
			}
		}
	}

}
</script>

<style>

</style>