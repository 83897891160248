
// import defaultForm from './DefaultForm.js';
import wp from '@/services/WordpressService.js';
import { EventBus } from './EventBus.js';

export default {

    /**
     * 
     * @param {Function} store.commit
     *
     * @param {String} data.post_title
     * @param {String} data.post_excerpt
     * @param {String} data.post_content
     * @param {Object} data.meta_input
     * @param {String} data.meta_input.workbook
     * @param {String} data.meta_input.form
     *
     */
    async createEntry({ commit }, data) {
        console.log('>>> createEntry', data)

        commit('SET_LOADING_STATE', true);
        // let form =  {...defaultForm, ...data};
        let response = await wp.createEntry(data);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        // await this.dispatch('getEntries');

        EventBus.trigger(EventBus.events.ENTRY_CREATED, [response]);

        return response;
    },




    /**
     * 
     * @param {*} param0 
     * @param {*} collection 
     * @param {*} collection[0].post_title 
     * @param {*} collection[0].post_content 
     * @param {*} collection[0].post_excerpt 
     * @param {*} collection[0].meta_input 
     * @param {*} collection[0].meta_input.form The ID of the form
     *  
     * @returns 
     */
    async createEntries({ commit }, collection) {
        console.log('>>> createEntries', collection)

        commit('SET_LOADING_STATE', true);
        // let form =  {...defaultForm, ...data};
        let response = await wp.createEntries(collection);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        // await this.dispatch('getEntries');

        EventBus.trigger(EventBus.events.ENTRIES_CREATED, [response]);

        return response;
    },







    /**
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {Boolean} forceRefresh 
     * 
     * @returns 
     */
    async getEntries({ commit , state }, forceRefresh=false) {
        let collection;

        if(state.entries?.length > 0 && forceRefresh === false) {
            collection = state.entries;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getEntries();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_ENTRIES', collection);

        return collection;
    },


    
    /** TODO */
    async getEntry({ commit , state }, name) {
        let form;

        if(typeof state.active.entries[name] != 'undefined') {
            form = state.active.entries[name];
        } else {
            commit('SET_LOADING_STATE', true);
            form = await wp.getEntry(name);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_FORM', {
            name: name, 
            data: form
        });

        commit('SET_CURRENT_FORM', form );

        return form;
    }, 





    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.ID Name of the form
     * @param {Object} request.post_title
     * @param {Object} request.post_excerpt
     *
     *
     */
    /** TODO */
    async updateEntry({ commit, state }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.updateEntry(request);
        commit('SET_LOADING_STATE', false);


        if(typeof state.active.entries[response.name] != 'undefined') {
            state.active.entries[response.name] = response;
        }

        commit('SET_ENTRY', {
            name: response.name, 
            data: response
        });

        EventBus.trigger(EventBus.events.ENTRY_UPDATED, [response]);

        return response;
    }, 

    /**
     * 
     * @param {Object} context 
     * @param {Object} context.state 
     * @param {Function} context.commit
     * 
     * @param {Object} data 
     * @param {Number} data.ID
     * @param {String} data.name
     * @param {String} data.title
     */
    /** TODO */
    async deleteEntry({ commit, state }, data) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.deleteEntry(data.ID, false /** forceDelete */);
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current?.entry?.name == data.name) && (delete state.current?.entry);

        /** remove if entry is in active list */
        (state.active.entries[data.name]) && delete(state.active.entries[data.name]);

        EventBus.trigger(EventBus.events.ENTRY_DELETED, [response]);
        return true;
    }, 




    /**
     * 
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {Number} formId 
     * 
     * @returns 
     */
     async getEntriesByFormId({ commit , state }, formId) {
        state

        // if(!request || !request?.ID) {
        //     return
        // }

        commit('SET_LOADING_STATE', true);
        let entries = await wp.getEntriesByFormId(formId);
        commit('SET_LOADING_STATE', false);

        commit('SET_ENTRIES', entries);

        return entries;
    }, 



}