

export default {
    ID: 0,
    name: 'test',
    title: 'Test',
    excerpt: 'The excerpt',
    content: {
        'company_info': {
            name: 'company_info',
            title: 'Company Info', 
            fields: {
                done: false
            }
        }, 
    }
}


