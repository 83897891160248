<template>
    <div id="modal-template-preview" class="cmp-modal-video modal fade amk center from-center r0" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content border-0 shadow-lg">
                <div class="modal-header border-0 px-3 pb-0">
                    <!-- close modal -->
                    <a class="btn-close-popup"
                        @click="close"
                        ></a>


                    <div class="button-wrapper d-flex flex-row justify-content-center"> 
                        <a @click="register"
                            class="btn btn-primary btn-use-template py-3 text-uppercase" target="_top">
                            Use this template
                        </a>

                        <div class="viewport-buttons pt-3">
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item px-1"> 
                                    <a @click="viewport('desktop')" class="viewport-button button-tooltip" data-size="desktop"
                                        data-tooltip="desktopView" title=""><img
                                            src="https://static.web-repository.com/images/small-icons/desktop-white.png">
                                    </a>
                                </li>
                                <li class="list-inline-item px-1">  
                                    <a @click="viewport('tablet')" class="viewport-button button-tooltip" data-size="tablet"
                                        data-tooltip="tabletView" title=""><img
                                            src="https://static.web-repository.com/images/small-icons/tablet-white.png">
                                    </a>
                                </li>
                                <li class="list-inline-item px-1"> 
                                    <a @click="viewport('mobile')" class="viewport-button button-tooltip" data-size="mobile"
                                        data-tooltip="phoneView" title=""><img
                                            src="https://static.web-repository.com/images/small-icons/phone-white.png">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <iframe ref="iframe-preview"
                        class="viewport viewport-desktop h-100 d-block mx-auto"
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const $ = window.$;

export default {
    name: "ModalTemplatePreview",

    props: {},

    data() {
        return {
            emptyURL: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
            current: {
                viewport: 'desktop', 
                template: {}
            }
        };
    },


    methods: {
        reset(size) {
            size = size || 'desktop';
            this.$iframe
                .removeClass('viewport-desktop')
                .removeClass('viewport-tablet')
                .removeClass('viewport-mobile')
                .addClass('viewport-' + size);

            this.current.viewport = size;
            $(this.$el).find('.viewport-button').removeClass('active');
            $(this.$el).find('[data-size="'+ size +'"]').addClass('active');

            this.$iframe.attr('src', this.emptyURL);
        },
        
        viewport(size) {
            this.$iframe
                .removeClass('viewport-desktop')
                .removeClass('viewport-tablet')
                .removeClass('viewport-mobile')
                .addClass('viewport-' + size);
            this.current.viewport = size;

            $(this.$el).find('.viewport-button').removeClass('active');
            $(this.$el).find('[data-size="'+ size +'"]').addClass('active');

        },

        open(data) {
            this.current.template = data;
            this.reset();
            $('#modal-template-preview').modal('show');
            setTimeout(()=>{
                let url = 'https://' + data.previewURL;
                this.$iframe.attr('src', url);
            }, 500);
        },

        register() {
            var self = this;
            var template = this.current.template;

            this.$root.notify({
                title: 'Confirm',
                message: 'You will be redirected to appcropolis.com',
                callback: function(okay) { 
                    if(okay) {
                        setTimeout(function() {
                            self.close();
                        }, 2000)

                        let winName = 'register.' + Number(new Date());
                        let url = 'https://appcropolis.com' + template.registerURL;
                        window.open(url, winName);
                    }
                } 
            });
        },

        close() {
            $('#modal-template-preview').modal('hide');
        },
    },


    mounted() {
        this.$iframe = $(this.$refs['iframe-preview']);
        this.$iframe.addClass('viewport-desktop');

        window.$(this.$el).on('shown.bs.modal', ()=> {
            window
        });

        window.$(this.$el).on('hidden.bs.modal', ()=> {
            window
        });
    },
};
</script>

<style scoped>
.btn-use-template {
    width: 275px;
    background-color: var(--primary)
}
.viewport-buttons {
    width: 120px;
    padding: 0;
    margin-top: 0px;
    margin-left: 10px;
}

.viewport-button {
    opacity: .5;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.viewport-button:hover {
    opacity: 1;
}

.viewport-button.active {
    opacity: 1;
    pointer-events: none;
}

.btn-close-popup {
    background: url(https://static.web-repository.com/images/feed-close2.png?v=9) no-repeat !important;
    width: 50px !important;
    height: 50px !important;
    position: absolute !important;
    opacity: .5;
    transition: opacity 0.3s ease;
    border: 0px none;
    top: 20px;
    right: 20px;
}
.btn-close-popup:hover {
    opacity: 1;
}
.button-wrapper {
    width: 100%;
    margin: 0px auto 8px;
    float: left;
    display: block;
    text-align: center;
}
.modal-content {
    background-color: rgba(30,30,30,1);
}

.viewport {

}
.viewport-desktop {
    width: 100%;
}
.viewport-tablet {
    width: 765px;
}
.viewport-mobile {
    width: 350px;
}
</style>
