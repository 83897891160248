
var defaultPreferences = {
    screen_size: 100,
    show_welcome_screen: true
};

export default {
    install: (app) => {
        app.config.globalProperties.$preferences = {
            get() {
                let $session = app.config.globalProperties.$session;
                let preferences;
                if($session && $session.get('preferences')) {
                    preferences = $session.get('preferences');
                } else {
                    preferences = defaultPreferences;
                    $session.set('preferences', preferences);
                    this.update(preferences);
                }

                return preferences;
            }, 

            set(data) {
                let $session = app.config.globalProperties.$session;
                $session.set('preferences', data);
                console.log('preferences.set', data);
            }, 

            getValue(key) {
                let value = undefined;
                let preferences = this.get();
                if(key in preferences) {
                    value = preferences[key];
                }

                return value;
            }, 

            setValue(key, value) {
                let preferences = this.get();
                preferences[key] = value;
                this.set(preferences);
                this.update(preferences);

                return true;
            }, 


            update(preferences) {
                preferences = !preferences || this.get();

                Object.keys(preferences).forEach( (key)=> {
                    let value = preferences[key];

                    switch (key) {
                        case 'screen_size':
                            
                            console.log('UPDATE SCREEN SIZE: ', value);
                            break;
                    
                        default:
                            break;
                    }
                });


            }

        }
    }
}