<template lang="">
    <ul class="list-group list-group-flush">
        <li v-for="(item, index) in collection" :key="index"
            class="list-group-item position-relative rounded-3 border-0 mt-0 user-select-none d-flex align-items-center"
            :guid="item.name"
            :class="[item.name == active?.name ? 'active': '', item.disabled? 'disabled pointer-events-none' : '']">

            <ButtonCircle class="btn-icon" :icon="item?.icon" :onClick="()=>select(item)"></ButtonCircle>
            
            <a @click.prevent="select(item)" class="text-reset text-decoration-none fw-500 font-size-18 flex-fill">
                <span class="ms-2">
                    {{item.title}}      
                </span>
            </a>

            <span v-if="options?.length > 0" class="list-group-item-options">
                <OptionsDropdown class="" :context="item" :collection="options" :onClick="onOption"></OptionsDropdown>
            </span>
        </li>
    </ul>
</template>
<script>

import common from './Common.js';

export default {
    props: { ...common.props, ...{
        options: {
            type: Array,
            default() {
                return []
            }
        }, 
        active: {
            type: Object
        }
    }}, 

    data() {
        return { ...common.data }
    },

    computed: { ...common.computed }, 


    methods: { 
        ...common.methods, 
        ...{
            select(item) {
                let items = this.$el.querySelectorAll('.list-group-item');
                    items.forEach(item => {
                        item.classList.remove('active')
                    });
                this.$el.querySelector('[guid="' + item.name +'"]')?.classList.add('active');
                this.onClick?.(item);
            }
        } 
    }
}
</script>


<style scoped>


.list-group-item {
    cursor: pointer;
    margin-bottom: 2px;
    transition: all 0.2s;
}
.list-group-item.active {
    background-color: var(--primary-light) !important;
}
.list-group-item:not(.active):hover {
    background-color: var(--primary-lighter) !important;
}
.list-group-item.active a {
    color: var(--text-main) !important;
}
.list-group-item.active .btn-icon {
    background-color: var(--primary) !important;
    color: #ffffff !important;
}


/* options */
.list-group-item .list-group-item-options {
    opacity: 0;
    transition: all 0.2s;
}
.list-group-item:hover .list-group-item-options {
    opacity: 1;
}

/* .list-group-item .list-group-item-options .appcui-btn-icon-circle {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: red !important
} */


/* completed */
.list-group-item.active.completed {
    background-color: hsl(134 61% 41%/.2)!important;
    color: var(--success)!important;
}
.list-group-item.completed:hover {
    background-color: hsl(134 61% 41%/.3)!important;
}
.list-group-item.completed > a {
    color: var(--bs-success)!important;
}
.list-group-item.completed .btn-icon {
    background-color: var(--success)!important;
    color: var(--bs-white)!important;
}

/* disabled */
.list-group-item.disabled {
    opacity: 0.5;
}
</style>