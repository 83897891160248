

export default {
    ID: 0,
    version: '1.0.0',
    name: 'test',
    title: 'Test',
    excerpt: 'Test opportunity',
    content: {
        overview: {
            name: 'overview',
            title: 'Solution Overview', 
            group: 'business',
            fields: {
                keywords: "",
                product_description: "",
                product_features: "",
                product_name: "",
                website_description: "",
                website_name: "",
                done: false
            }
        }, 

        hypothesis: {
            name: 'hypothesis',
            title: 'Business Hypothesis', 
            group: 'business',
            fields: {
                audience: "",
                problem: "",
                emotion: "",
                solution: "",
                transformation: "",
                preview: "",
                summary: "Opportunity template",
                done: false
            }
        }, 

        /**
         * The fields for this form are created in Wordpress They are not
         * Intended to be included in marketing strategies
         */
        analysis: {
            name: 'analysis',
            title: 'Hypothesis Analysis', 
            group: 'business',
            fields: {
                done: false
            }
        }, 

        audience: {
            name: 'audience',
            title: 'Audience', 
            group: 'audience',
            fields: {
                name: "",
                type: "",
                gender: "",
                education: "",
                role: "",
                age_min: "",
                age_max: "",
                income_min: "",
                income_max: "",
                children: "",
                marriage_status: "",
                industry: "",
                industry_segment: "",
                employee_range_min: "",
                employee_range_max: "",

                /** What makes you believe you are aiming for the right audience. Values will be concatenated by pipe ("|") */
                qualifiers: "",
                done: false
            }
        }, 

        problems: {
            name: 'problems',
            title: 'Problems', 
            group: 'audience',
            fields: {
                main: "",

                /** things that customers tried but did not work */
                problem_entries: 3,
                
                tried_1: "",
                result_1: "",

                tried_2: "",
                result_2: "",

                tried_3: "",
                result_3: "",

                /** alternative market solutions */
                solution_entries: 3,

                alternative_solution_1: "",
                alternative_price_1: "",
                alternative_frequency_1: "",

                alternative_solution_2: "",
                alternative_price_2: "",
                alternative_frequency_2: "",

                alternative_solution_3: "",
                alternative_price_3: "",
                alternative_frequency_3: "",

                /** What's at risk. Values will be concatenated by pipe ("|") */
                loses: "",
                done: false
            }
        }, 

        goals: {
            name: 'goals',
            title: 'Goals', 
            group: 'audience',
            fields: {
                main: "",
                /** Tangigble or measurable results your customer would like to achieve */
                entries: 5,

                aspiration_1: "",
                result_1: "",

                aspiration_2: "",
                result_2: "",

                aspiration_3: "",
                result_3: "",

                aspiration_4: "",
                result_4: "",

                aspiration_5: "",
                result_5: "",

                /** Other goals. Values will be concatenated by pipe ("|") */
                desires: "",

                /** What's in it for the user. Values will be concatenated by pipe ("|") */
                gains: "",
                done: false
            }
        }, 

        transformation: {
            name: 'transformation',
            title: 'Transformation', 
            group: 'audience',
            fields: {
                /**  */
                entries: 5,

                obstacle_1: "",
                goal_1: "",
                vehicle_1: "",

                obstacle_2: "",
                goal_2: "",
                vehicle_2: "",

                obstacle_3: "",
                goal_3: "",
                vehicle_3: "",

                obstacle_4: "",
                goal_4: "",
                vehicle_4: "",

                obstacle_5: "",
                goal_5: "",
                vehicle_5: "",

                /** Other areas of transformation. Values will be concatenated by pipe ("|") */
                others: "",
                done: false
            }
        }, 

        product: {
            name: 'product',
            title: 'Details', 
            group: 'product',
            fields: {
                type: "",
                name: "",
                idea: "",
                goal: "",
                audience: "",
                deliverable: "",
                timeframe_amount: "",
                timeframe_unit: "",
                done: false
            }
        }, 

        benefits: {
            name: 'benefits',
            title: 'Benefits', 
            group: 'product',
            fields: {
                guarantee: "",

                entries: 5,

                feature_1: "",
                benefit_1: "",
                price_1: "",

                feature_2: "",
                benefit_2: "",
                price_2: "",

                feature_3: "",
                benefit_3: "",
                price_3: "",

                feature_4: "",
                benefit_4: "",
                price_4: "",

                feature_5: "",
                benefit_5: "",
                price_5: "",

                done: false
            }
        }, 

        offer: {
            name: 'offer',
            title: 'Offer', 
            group: 'product',
            fields: {
                sender: "",
                scarcity: "",
                amount: "",
                reason: "",
                call_to_action: "",
                sender_role: "",
                start_date: "",
                start_time: "",
                start_time_zone: "",
                end_date: "",
                end_time: "",
                end_time_zone: "",
                done: false
            }
        }, 

        incentives: {
            name: 'incentives',
            title: 'Incentives', 
            group: 'product',
            fields: {
                /** the amount of incentives to be displayed (1-6) */
                entries: 6,

                bonus_1: "",
                benefit_1: "",
                price_1: "",

                bonus_2: "",
                benefit_2: "",
                price_2: "",
                
                bonus_3: "",
                benefit_3: "",
                price_3: "",
                
                bonus_4: "",
                benefit_4: "",
                price_4: "",
                
                bonus_5: "",
                benefit_5: "",
                price_5: "",
                
                bonus_6: "",
                benefit_6: "",
                price_6: "",
                
                done: false
            }
        }, 

        testimonials: {
            name: 'testimonials',
            title: 'Testimonials', 
            group: 'product',
            fields: {
                /** the amount of testimonials to be displayed (1-6) */
                entries: 6,

                firstname_1: "",
                lastname_1: "",
                comment_1: "",
                image_url_1: "",
                company_1: "",
                role_1: "",
                logo_url_1: "",
                website_url_1: "",
                more_url_1: "",

                firstname_2: "",
                lastname_2: "",
                comment_2: "",
                image_url_2: "",
                company_2: "",
                role_2: "",
                logo_url_2: "",
                website_url_2: "",
                more_url_2: "",

                firstname_3: "",
                lastname_3: "",
                comment_3: "",
                image_url_3: "",
                company_3: "",
                role_3: "",
                logo_url_3: "",
                website_url_3: "",
                more_url_3: "",

                firstname_4: "",
                lastname_4: "",
                comment_4: "",
                image_url_4: "",
                company_4: "",
                role_4: "",
                logo_url_4: "",
                website_url_4: "",
                more_url_4: "",

                firstname_5: "",
                lastname_5: "",
                comment_5: "",
                image_url_5: "",
                company_5: "",
                role_5: "",
                logo_url_5: "",
                website_url_5: "",
                more_url_5: "",

                firstname_6: "",
                lastname_6: "",
                comment_6: "",
                image_url_6: "",
                company_6: "",
                role_6: "",
                logo_url_6: "",
                website_url_6: "",
                more_url_6: "",
                
                done: false
            }
        }, 

        custom: {
            name: 'custom',
            title: 'Custom Data', 
            group: 'custom',
            fields: {
                done: false
            }
        }
    }
}


