
import Outlet from "./Outlet.vue";

export default {
    install: (app) => {

        app.component('Outlet', Outlet);

        app.mixin({
            data() {
                return {
                    // foo: 'foooooo',
                }
            }, 

            mounted() {
                /**
                 * IMPORTANAT: you must include the <Outlet> tag in the App.vue and assign
                 * a reference with the value of "outlet"
                 */
                this.$outlet = this.$root.$refs?.outlet;
            }
        })
    }
}