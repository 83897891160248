<template>
    <div
        id="modal-video"
        class="cmp-modal-video modal fade amk center from-center r8"
        tabindex="-1"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 shadow-lg">
                <div class="modal-header border-0 px-3 pb-0">
                    <h4 class="modal-title font-size-heading-md font-weight-normal">{{ videoTitle }}</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="ratio ratio-16x9">
                                <iframe :src="videoURL" 
                                    class="embed-responsive-item"
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer px-3 border-0">
                    <button
                        type="button"
                        class="btn btn-link text-uppercase"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalVideo",

    props: {},

    data() {
        return {
            defaultURL: 'https://www.youtube.com/embed/oIJ5m1_6E24',
            embedURL: ''
        };
    },

    computed: {
        videoTitle() {
            // return 'Video: ' + this.$root.current.formTitle
            return 'Video Title'
        }, 

        videoURL: {
            get() {
                return this.embedURL;
            }, 

            set(value) {
                this.embedURL = value;
            }
        }
    },

    methods: {
        done() {
            
        },
    },

    created() {},

    mounted() {
        window.$(this.$el).on('shown.bs.modal', ()=> {
            let url = window.$(this.$el).data('video') || this.defaultURL;
            this.videoURL = url;
        });

        window.$(this.$el).on('hidden.bs.modal', ()=> {
            this.videoURL = '';
        });
    },
};
</script>

<style scoped></style>
