

export default {
    ID: 0,
    version: '1.0.0',
    post_name: 'test',
    post_title: 'Test',
    post_excerpt: 'Test form',
    post_content: {
        fields: [
            {
                name: 'title',
                title: 'Title',
                key: 'title',
                description: 'The name field',
                hint: 'Enter the title',
                type: 'text',
                group: '',
                private: false
            }
        ], 

        primaryField: 'title'
    }, 
    // meta_input: {
    //     iconURL: 'https://domain.com/icon',
    //     moreURL: 'https://domain.com/more',
    //     videoURL: 'https://domain.com/video',
    // }
}
