
// import defaultForm from './DefaultForm.js';
import wp from '@/services/WordpressService.js';
import { EventBus } from './EventBus.js';

export default {

    /**
     * 
     * @param {Function} store.commit
     *
     * @param {String} data.post_title
     * @param {String} data.post_excerpt
     * @param {String} data.post_content
     * @param {Object} data.meta_input
     * @param {String} data.meta_input.workbook
     * @param {String} data.meta_input.form
     *
     */
    async ___createEntry({ commit }, data) {
        console.log('>>> createEntry', data)

        commit('SET_LOADING_STATE', true);
        // let form =  {...defaultForm, ...data};
        let response = await wp.createEntry(data);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        // await this.dispatch('getEntries');

        EventBus.trigger(EventBus.events.ENTRY_CREATED, [response]);

        return response;
    },



    /**
     * Choose an IA strategy
     *
     */

    AIChooseStrategy() {
        // let scope = this;
        return new Promise((resolve, reject)=>{
            var modal = this.modal({
                title: 'Choose AI Strategy',
                size: 'xl',
                okay: {
                    label: 'CHOOSE', 
                    async callback(content) {
                        let usecase = content?.current?.item;
                        if(usecase) {
                            // setTimeout(()=>{
                            // 	scope.AIGenerateStrategy(usecase, callback);
                            // }, 500);
                            resolve(usecase);
                        } else {
                            reject()
                        }
                    }
                }, 

                cancel: {
                    label: 'CLOSE'
                }
            }, 'ModalContentAIStrategies');

            /**
             * reset content
             */
            setTimeout(()=>{
                let content = modal.content();
                    content.current.item = null;
                    content.current.selection = null;
            }, 100);
            // return modal;
        });
    },



    /**
     * Generate IA strategy
     *
     * @param {Object} usecase
     * @param {Object} usecase.name
     * @param {Object} usecase.slug
     * @param {Object} usecase.description
     * 
     * @param {Object} opportunity
     * @param {Object} opportunity.opportunity_id
     * @param {Object} opportunity.opportunity_name
     * @param {Object} opportunity.opportunity_title
     * 
     */
    AIGenerateStrategy(usecase, opportunity) {
        return new Promise((resolve, reject)=>{
            let title = usecase.name || 'Generate AI Content';
            var modal = this.modal({
                title: title,
                size: 'xl',
                okay: {
                    label: 'USE SELECTION', 
                    async callback(content) {
                        let selection = content?.current?.selection;
                        if(selection) {
                            // typeof callback == 'function' && callback.apply(null, [selection, usecase]);
                            resolve(selection);
                        } else {
                            reject();
                        }
                    }
                }, 

                cancel: {
                    label: 'CLOSE'
                }
            }, 'ModalContentAIGenerate');

            /**
             * reset content
             */
            setTimeout(()=>{
                let content = modal.content();
                    content.current.usecase = usecase;
                    content.current.opportunity = {
                        opportunity_id: opportunity?.opportunity_id,
                        opportunity_name: opportunity?.opportunity_name,
                        opportunity_title: opportunity?.opportunity_title
                    };
            }, 100);

            // return modal;
        });
    },

    // AIGenerateStrategy(usecase, callback) {
    // 	let title = usecase.name || 'Generate AI Content';
    // 	var modal = this.modal({
    // 		title: title,
    // 		size: 'xl',
    // 		okay: {
    // 			label: 'USE SELECTION', 
    // 			async callback(content) {
    // 				let selection = content?.current?.selection;
    // 				if(selection) {
    // 					typeof callback == 'function' && callback.apply(null, [selection, usecase]);
    // 				}
    // 			}
    // 		}, 

    // 		cancel: {
    // 			label: 'CLOSE'
    // 		}
    // 	}, 'ModalContentAIGenerate');

    // 	/**
    // 	 * reset content
    // 	 */
    // 	setTimeout(()=>{
    // 		let content = modal.content();
    // 			content.current.usecase = usecase;
    // 	}, 100);
    // 	return modal;
    // },




    /**
     * Call AI API to gerenrate content. Depending of the value of "USECASE", different parameters will be needed.
     * 
     * IMPORTANT: an opportunity must be selected and its data must be available from <code>app.$store.getters.currentOpportunity</code>
     * 
     * @param {Object} params
     * @param {Object} params.USECASE The slug value of the usecase. Possible values are:
     * 
     * - blog-idea-outline
     * - blog-writing
     * - brand-name
     * - business-idea-pitch
     * - business-ideas
     * - call-to-action
     * - copywriting-framework-aida
     * - copywriting-framework-pas
     * - email
     * - facebook-twitter-linkedin-ads
     * - google-search-ads
     * - interview-questions
     * - job-description
     * - website-landing-page
     * - magic-command
     * - post-captions
     * - product-description
     * - product-description-bullets
     * - social-media-bio
     * - question-answer-generator
     * - reply-response-generator
     * - seo-description
     * - seo-title
     * - sms-notifications
     * - song-lyrics
     * - story-plot
     * - tagline
     * - testimonial-review-generator
     * - ai-text-completer
     * - text-continue-ryting
     * - text-expander-and-lengthener
     * - grammar-checker-and-readability-improver
     * - paragraph-content
     * - text-rephraser
     * - text-summarizer
     * - video-channel-description
     * - video-description
     * - video-ideas
     * 
     * 
     * @param {Object} params.PRIMARY_KEYWORD_LABEL 	(blog-idea-outline)
     * 
     * @param {Object} params.SECTION_TOPIC_LABEL 		(blog-writing)
     * @param {Object} params.SECTION_KEYWORDS_LABEL 	(blog-writing)
     * 
     * @param {Object} params.BRAND_DESCRIPTION_LABEL 	(brand-name)
     * 
     * @param {Object} params.BUSINESS_IDEA_LABEL 		(business-idea-pitch)
     * 
     * @param {Object} params.INTEREST_LABEL 			(business-ideas)
     * @param {Object} params.SKILLS_LABEL 				(business-ideas)
     * 
     * @param {Object} params.DESCRIPTION_LABEL			(call-to-action)
     * 
     * @param {Object} params.PRODUCT_OR_BRAND_DESCRIPTION_LABEL	(copywriting-framework-aida)
     * 
     * @param {Object} params.PRODUCT_OR_BRAND_DESCRIPTION_LABEL	(copywriting-framework-pas)
     * 
     * @param {Object} params.KEY_POINTS_LABEL			(email)
     * 
     * @param {Object} params.PRODUCT_NAME_LABEL		(facebook-twitter-linkedin-ads)
     * @param {Object} params.PRODUCT_DESCRIPTION_LABEL	(facebook-twitter-linkedin-ads)
     * 
     * @param {Object} params.PRODUCT_NAME_LABEL		(google-search-ads)
     * @param {Object} params.PRODUCT_DESCRIPTION_LABEL	(google-search-ads)
     * @param {Object} params.TARGET_KEYWORD_LABEL		(google-search-ads)
     * 
     * @param {Object} params.INTERVIEWEE_BIO_LABEL		(interview-questions)
     * @param {Object} params.INTERVIEW_CONTEXT_LABEL	(interview-questions)
     * 
     * @param {Object} params.JOB_ROLE_LABEL					(job-description)
     * 
     * @param {Object} params.WEBSITE_NAME_LABEL					(website-landing-page)
     * @param {Object} params.ABOUT_WEBSITE_LABEL					(website-landing-page)
     * @param {Object} params.FEATURES_LABEL					(website-landing-page)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(magic-command)
     * 
     * @param {Object} params.POST_TOPIC_LABEL	(post-captions)
     * 
     * @param {Object} params.PRODUCT_NAME_LABEL	(product-description)
     * @param {Object} params.ABOUT_PRODUCT_LABEL	(product-description)
     * 
     * @param {Object} params.PRODUCT_NAME_LABEL	(product-description-bullets)
     * @param {Object} params.PRODUCT_FEATURES_LABEL	(product-description-bullets)
     * 
     * @param {Object} params.ABOUT_YOU_LABEL	(social-media-bio)
     * 
     * @param {Object} params.TOPIC_DESCRIPTION_LABEL	(question-answer-generator)
     * 
     * @param {Object} params.MESSAGE_LABEL	(reply-response-generator)
     * 
     * @param {Object} params.PAGE_META_TITLE_LABEL	(seo-description)
     * 
     * @param {Object} params.TARGET_KEYWORDS_LABEL	(seo-title)
     * 
     * @param {Object} params.CONTEXT_LABEL	(sms-notifications)
     * 
     * @param {Object} params.SONG_IDEA_LABEL	(song-lyrics)
     * 
     * @param {Object} params.STORY_IDEA_LABEL	(story-plot)
     * 
     * @param {Object} params.DESCRIPTION_LABEL	(tagline)
     * 
     * @param {Object} params.NAME_LABEL	(testimonial-review-generator)
     * @param {Object} params.REVIEW_TITLE_LABEL	(testimonial-review-generator)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(ai-text-completer)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(text-continue-ryting)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(text-expander-and-lengthener)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(grammar-checker-and-readability-improver)
     * 
     * @param {Object} params.TOPIC_LABEL	(paragraph-content)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(text-rephraser)
     * 
     * @param {Object} params.INPUT_TEXT_LABEL	(text-summarizer)
     * 
     * @param {Object} params.CHANNEL_PURPOSE_LABEL	(video-channel-description)
     * 
     * @param {Object} params.VIDEO_TITLE_LABEL	(video-description)
     * 
     * @param {Object} params.KEYWORDS_LABEL	(video-ideas)
     * 
     * 
     * 
     * 
     * @param {Object} opportunity (optional)
     * @param {Object} opportunity.opportunity_id
     * @param {Object} opportunity.opportunity_name
     * @param {Object} opportunity.opportunity_title
     * 
     * 
     * TODO: unify API signature to match AIGenerateU(usecaseId, inputs, opportunity)
     */
    async AIGenerate(params, opportunity) {
        // console.log('*** AIGenerate(...) ***');
        // console.log(params);
        // console.log(opportunity);

        if(!opportunity) {
            params['opportunity_id'] = this.$store.getters.currentOpportunity?.ID;
            params['opportunity_name'] = this.$store.getters.currentOpportunity?.name;
            params['opportunity_title'] = this.$store.getters.currentOpportunity?.title;
        } else {
            params['opportunity_id'] = opportunity?.opportunity_id;
            params['opportunity_name'] = opportunity?.opportunity_name;
            params['opportunity_title'] = opportunity?.opportunity_title;
        }

        let aiSuggestion = await this.$store.getters.wp.run('AIGenerate', [ params ]);

        return aiSuggestion;
    },



    /**
     * Uses app.rytr.me unlimited account credentials to generate content
     * 
     */
    async AIGenerateU(usecaseId, inputs, opportunity) {
        // console.log('*** AIGenerate(...) ***');
        // console.log(usecaseId);
        // console.log(inputs);
        // console.log(opportunity);

        if(!opportunity) {
            opportunity = {};
            opportunity['opportunity_id'] = this.$store.getters.currentOpportunity?.ID;
            opportunity['opportunity_name'] = this.$store.getters.currentOpportunity?.name;
            opportunity['opportunity_title'] = this.$store.getters.currentOpportunity?.title;
        }


        let aiSuggestion = await this.$store.getters.wp.run('AIGenerateU', [ usecaseId, inputs,  opportunity ]);

        return aiSuggestion;
    },



    AIShowHistory(callback) {
        // let scope = this;
        var modal = this.modal({
            title: 'AI Strategy History',
            size: 'AIHistory',
            okay: {
                label: 'CHOOSE', 
                async callback(content) {
                    let selection = content?.current?.selection;
                    if(selection) {
                        setTimeout(()=>{
                            typeof callback == 'function' && callback.apply(null, [selection]);
                        }, 500);
                    }
                }
            }, 

            cancel: {
                label: 'CLOSE'
            }
        }, 'ModalContentAIHistory');

        /**
         * reset content
         */
        setTimeout(()=>{
            let content = modal.content();
                content.current.item = null;
                content.current.selection = null;
        }, 100);


        modal.$el.setAttribute('data-modal', 'AIShowHistory');

        return modal;
    },




    /**
     * Get AI recommendations for a give opportunity ID
     * 
     * @params {Number} opportunity_id
     * 
     * @returns {Array}
     * 
     */
    async AIGetHistory(opportunity_id) {
        let history = await this.$store.getters.wp.run('AIHistory', [opportunity_id]);
        return history;
    },




}