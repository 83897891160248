export default [{
        title: 'Business',
        url: 'business',
        trigger: null,
        forms: [
            {
                title: 'Overview',
                name: 'overview',
                icon: 'attach_money'
            }, 
            {
                title: 'Business Hypothesis',
                name: 'hypothesis',
                icon: 'lightbulb'
            },
            {
                title: 'Hypothesis Analysis',
                name: 'analysis',
                icon: 'trending_up'
            },
            {
                title: 'Report',
                name: 'report',
                icon: 'pie_chart'
            },
        ]
    },
    {
        title: 'Audience',
        url: 'audience',
        trigger: null,
        forms: [{
                title: 'Details',
                name: 'audience',
                icon: 'group',
                completed: false,
                active: false
            },
            {
                title: 'Problems',
                name: 'problems',
                icon: 'pest_control',
                completed: false,
                active: false
            },
            {
                title: 'Goals',
                name: 'goals',
                icon: 'my_location',
                completed: false,
                active: false
            },
            {
                title: 'Transformation',
                name: 'transformation',
                icon: 'mood',
                completed: false,
                active: false
            }
        ]
    },
    {
        title: 'Product',
        url: 'product',
        trigger: null,
        forms: [{
                title: 'Details',
                name: 'product',
                icon: 'straighten',
                completed: false,
                active: false
            },
            {
                title: 'Benefits',
                name: 'benefits',
                icon: 'exposure_plus_1',
                completed: false,
                active: false
            },
            {
                title: 'Offer',
                name: 'offer',
                icon: 'redeem',
                completed: false,
                active: false
            },
            {
                title: 'Incentives',
                name: 'incentives',
                icon: 'thumb_up',
                completed: false,
                active: false
            },
            {
                title: 'Testimonials',
                name: 'testimonials',
                icon: 'chat',
                completed: false,
                active: false
            },
            {
                title: 'FAQ',
                name: 'faq',
                icon: 'help_outline',
                completed: false,
                active: false
            }
        ]
    }
];