<template>
    <div class="">
        <div class="dropdown d-inline">
            <a class="dropdown-toggle no-carret"  to="/settings/profile"
                data-bs-toggle="dropdown"
                ><img
                    class="img-fluid rounded-circle"
                    :src="profileImgURL"
                    alt="profile-pic"
            /></a>

            <ul class="dropdown-menu shadow border-minimal dropdown-menu-end border-radius-8 px-0 py-2 mt-3">
                <li><a 
                    @click.prevent="action('password')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate disabled">
                    Notifications
                    </a>
                </li>

                <li><hr class="bg-light dropdown-divider"></li>

                <li><a 
                    @click.prevent="action('profile')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    Profile
                    </a>
                </li>

                <li><a 
                    @click.prevent="action('password')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate disabled">
                    Subscription
                    </a>
                </li>


                <li><a 
                    @click.prevent="action('password')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate disabled">
                    Billling
                    </a>
                </li>


                <li><a 
                    @click.prevent="action('password')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate disabled">
                    Password
                    </a>
                </li>

                <li><hr class="bg-light dropdown-divider"></li>

                <li><a 
                    @click.prevent="action('grantAccess')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    Grant Access
                    </a>
                </li>

                <li><a 
                    @click.prevent="action('revokeAccess')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    Revoke Access
                    </a>
                </li>


                <li><a 
                    @click.prevent="action('loginAs')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    Login As
                    </a>
                </li>

                <li><hr class="bg-light dropdown-divider"></li>

                <li><a 
                    href="#"
                    @click.prevent="action('logout')"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    Log Out
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ButtonUserDropdown', 
        
        data() {
            return {
            };
        }, 

        computed: {
            user() {
                return this.$store.getters.user;
            },

            profileImgURL() {
                let url = "https://dummyimage.com/36x36/fd7e14/FFFFFF?text=";
                if (this.user?.ID != null) {
                    url += this.user?.user_login?.toUpperCase().substr(0, 1);
                }
                return url;
            },
        }, 


        methods: {
            action(name) {
                let path = null;
                switch (name) {
                    case 'profile':
                        path = '/settings/profile';
                        break;

                    case 'grantAccess':
                        this.$root.grantAccessToUser();
                        break;

                    case 'revokeAccess':
                        this.$root.revokeAccessToUser();
                        break;

                    case 'loginAs':
                        this.$root.loginAs();
                        break;

                    case 'password':
                        path = '/settings/password';
                        break;
                
                    case 'logout':
                        this.$root.logoutIntent();
                        break;

                    default:
                        break;
                }

                path && this.$router.push( { path: path } );
            }
        },

        created() {
        },

        async mounted() {
            let dropdown = this.$el?.querySelector('.dropdown');
            dropdown.addEventListener('shown.bs.dropdown', async ()=> {
                // console.log('dropdown shown');
            });
        }
    }
</script>

<style lang="css" scoped>

.dropdown-menu {
    width: 200px;
}
.dropdown-menu .dropdown-item-last:hover {
   background-color: transparent !important;
}

.dropdown-item.disabled {
    opacity: 0.5;
}
/* 
.hover\:btn-outline-primary:hover {
    color: #ffffff !important;
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
} */
</style>