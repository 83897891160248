<template lang="">
	<div class="modal fade amk center from-center r8" id="modal-register" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content border-0 shadow-lg">
				<div class="modal-header border-0">
					<h5 class="modal-title fs-4 font-size-heading-md font-weight-normal">{{ title }}</h5>
				</div>
				<div class="modal-body py-0 px-0">
                    <form @submit.prevent="" class="">
                        <div class="pt-3">
                            <ul class="nav nav-tabs d-flex flex-row text-center">
                                <li v-for="(tab, index) in tabs" :key="index" class="nav-item flex-fill">
                                    <a class="nav-link"  :class="[tab.name == current.tab? 'active': '']"
                                        @click="( current.tab = tab.name)"
                                    href="#">{{ tab.title }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="pt-4 mt-2 px-5">
                            <div class="mb-3 d-flex " 
                                v-for="field in fields" :key="field.name"
                                :class="[field.group == current.tab? '' : 'd-none']"
                                >
                                <label :for="field.name" class="form-label">{{field.title}}</label>
                                <span class="flex-fill">
                                    <input :id="field.name" v-if="field.type == 'text'" v-model="data[field.name]" type="text" class="form-control align-self-start">
                                    <input :id="field.name" v-if="field.type == 'checkbox'" v-model="data[field.name]" type="checkbox" class="form-check-input align-self-start">
                                    <input :id="field.name" v-if="field.type == 'number'" v-model="data[field.name]" step="50" min="500" type="number" class="form-control align-self-start">
                                    <textarea :id="field.name" v-if="field.type == 'textarea'" v-model="data[field.name]" type="text" class="form-control align-self-start"></textarea>
                                    <select :id="field.name" v-if="field.type == 'select'" class="form-select" v-model="data[field.name]">
                                        <option v-for="option in field.options" :key="option">{{option}}</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </form>
				</div>
				<div class="modal-footer px-3 border-0">
					<button type="button" class="btn btn-link text-decoration-none text-uppercase" data-bs-dismiss="modal">CLOSE</button>
					<button @click.prevent="register"  type="button" class="btn btn-link text-decoration-none text-uppercase">REGISTER</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default { 

    props: {
        callback: Function, 
    },

    data() {
        return {
            current: {
                tab: 'general'
            },
            
            tabs: [
                { title: 'General', name: 'general' },
                { title: 'Display', name: 'display' },
                { title: 'Options', name: 'options' },
            ],

            fields: [
                /** general */
                { name: 'title', title: 'Name', type: 'text', group: 'general'},
                { name: 'description', title: 'Description', type: 'textarea', group: 'general'},
                { name: 'canvasURL', title: 'Canvas URL', type: 'text', group: 'general'},
                { name: 'iconURL', title: 'Icon URL', type: 'text', group: 'general'},
                { name: 'coverURL', title: 'Cover URL', type: 'text', group: 'general'},
                { name: 'termsURL', title: 'Terms URL', type: 'text', group: 'general'},
                /** display */
                { name: 'ui', title: 'UI', type: 'select', options: ['modal', 'window', 'hidden', 'inline', 'left-panel', 'right-panel'], group: 'display'},
                { name: 'minWidth', title: 'Min Width', type: 'number', group: 'display'},
                { name: 'minHeight', title: 'Min Height', type: 'number', group: 'display'},
                { name: 'allowFullscreen', title: 'Fullscreen', type: 'checkbox', group: 'display'},
                { name: 'allowPopout', title: 'Popout', type: 'checkbox', group: 'display'},
                /** options */
                { name: 'options.package', title: 'Package', type: 'text', group: 'options'},
                { name: 'options.standalone', title: 'Standalone', type: 'select', options: ['Yes', 'No'], group: 'options'},
            ], 

            data: {
                /** general */
                title: '',
                description: '',
                canvasURL: '',
                coverURL: '',
                iconURL: '',
                termsURL: '',
                /** display */
                ui: '',
                minWidth: '',
                minHeight: '',
                allowFullscreen: '',
                allowPopout: '',
                /** options */
                'options.package': '',
                'options.standalone': '',
            }, 

            title: 'Register Extension'
        }
    },


    methods: {
        register() {
            this.hide(()=>{
                let request = JSON.parse(JSON.stringify(this.data));
                request = this.parseOptions(request);
                typeof this.callback == 'function' && this.callback.apply(null, [request]);
            })
        }, 

        show() {
            this.current.tab = 'general';
            window.$('#modal-register').modal('show');
        },

        hide(callback) {
            window.$('#modal-register').modal('hide');
            setTimeout(()=>{
                typeof callback == 'function' && callback.apply(null);
                this.reset();
            }, 500)
        }, 

        parseOptions(data) {
            data.options = {};
            for(let key in data) {
                let isOption = /options\./.test(key);
                if(isOption) {
                    let option = key.replace(/^options\./, '');
                    let value = data[key];
                        value = typeof value == 'string' && value?.toLowerCase() == 'yes'? true : value;
                        value = typeof value == 'string' && value?.toLowerCase() == 'no'? false : value;

                    data.options[option] = value;
                    delete data[key];
                }
            }

            return data;
        },

        reset() {
            for(let i in this.data) {
                this.data[i] = ''
            }
        }
    },
}
</script>
<style scoped>
.form-label {
    min-width: 130px;
}  
.modal .modal-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.modal .modal-body {
    margin-left: -16px;
    margin-right: -16px;
    min-height: 480px;
}
</style>