// import {Dispatcher} from "@/plugins/helper.js";
// const EventBus = new Dispatcher();

// import {createApp} from "vue";
// const element = document.createElement('div');
// const EventBus = createApp({}).mount(element);


const $ = window.$;
const EventBus = $({});

EventBus.events = {
    OPPORTUNITY_CREATED: 'onOpportunityCreated',
    OPPORTUNITY_UPDATED: 'onOpportunityUpdated',
    OPPORTUNITY_DELETED: 'onOpportunityDeleted',
    OPPORTUNITY_TRASHED: 'onOpportunityTrashed',
    OPPORTUNITY_RENAMED: 'onOpportunityUpdated',
    OPPORTUNITY_DUPLICATED: 'onOpportunityDuplicated',
    OPPORTUNITY_TEMPLATE_CREATED: 'onOpportunityTemplateCreated',

    COMPANY_CREATED: 'onCompanyCreated',
    COMPANY_UPDATED: 'onCompanyUpdated',
    COMPANY_DELETED: 'onCompanyDeleted',
    USER_COMPANY_UPDATED: 'onUserCompanyUpdated',


    APP_CREATED: 'onAppCreated',
    APP_DELETED: 'onAppDeleted',
    APP_UPDATED: 'onAppUpdated',
    APP_DUPLICATED: 'onAppDuplicated',
    APP_TRASHED: 'onAppTrashed',
    APP_RENAMED: 'onAppRenamed',



    WORKBOOK_CREATED: 'onWorkbookCreated',
    WORKBOOK_DELETED: 'onWorkbookDeleted',
    WORKBOOK_UPDATED: 'onWorkbookUpdated',
    WORKBOOK_DUPLICATED: 'onWorkbookDuplicated',
    WORKBOOK_TRASHED: 'onWorkbookTrashed',
    WORKBOOK_RENAMED: 'onWorkbookRenamed',


    FORM_CREATED: 'onFormCreated',
    FORM_DELETED: 'onFormDeleted',
    FORM_UPDATED: 'onFormUpdated',
    FORM_DUPLICATED: 'onFormDuplicated',
    FORM_TRASHED: 'onFormTrashed',
    FORM_RENAMED: 'onFormRenamed',
    FORM_FIELD_ADDED: 'onFormFieldAdded',
    FORM_FIELD_DELETED: 'onFormFieldDeleted',
    FORM_FIELD_UPDATED: 'onFormFieldUpdated',


    ENTRY_CREATED: 'onEntryCreated',
    ENTRY_DELETED: 'onEntryDeleted',
    ENTRY_UPDATED: 'onEntryUpdated',
    ENTRY_DUPLICATED: 'onEntryDuplicated',
    ENTRY_TRASHED: 'onEntryTrashed',
    ENTRY_RENAMED: 'onEntryRenamed',
    ENTRIES_CREATED: 'onEntriesCreated',
};

export {
    EventBus
}