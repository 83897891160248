<template lang="">
    <div class="px-2 cmpCreateStrategyContent">
        <div class="mb-3">
            <label for="strategy-title" class="form-label">Name</label>
            <input v-model="data.title" class="form-control text-input" id="strategy-title" type="text">
            <small class="fs-6 text-muted">What would you like to call this strategy?</small>
        </div>  


        <div class="mb-3">
            <label for="strategy-description" class="form-label">Description</label>
            <textarea v-model="data.excerpt" class="form-control text-input" id="strategy-description" style="resize: none;"></textarea>
            <small class="fs-6 text-muted">Add a brief description to this strategy</small>
        </div>  


        <div class="mb-3">
            <label for="opportunity" class="form-label">Choose your offer</label>
            <select ref="offerSelector" v-model="data.opportunity_id" class="form-select form-control text-input w-100" name="opportunity">
                <option></option>
                <option v-for="(opportunity, index) in opportunities" 
                    :key="index" 
                    :value="opportunity.ID">{{ opportunity.title }}</option>
            </select>
            <small class="fs-6 text-muted">Choose the offer for which you would like to create this strategy</small>
            <!-- force reading current opportunity ID -->
            <!-- {{currentOpportunityID}} -->
            <!-- {{data}} -->
        </div>  
    </div>
</template>
<script>
export default {

    name: 'CreateStrategyContent',

    data() {
        return {
            data: {
                opportunity_id: null, 
                opportunity_name: null, 
                opportunity_title: null, 
                excerpt: null, 
                title: null
            }
        }
    },
    
    computed: {
        opportunities() {
            return this.$store.getters.opportunities;
        }, 

        currentOpportunityID() { 
            let scope = this;
            let id = null;
            if(this.$store.state?.current?.opportunity) {
                id = this.$store.state.current.opportunity.ID;
                this.setCurrentOpportunityData(this.$store.state.current.opportunity);
            }

            (function(){ scope.data.opportunity_id = id; })();

            return '';
        }
    },

    methods: {
        validate() {
            let isValid = false;
            if('opportunity_id' in this.data 
                && parseInt(this.data.opportunity_id) > 0
                && 'title' in this.data 
                && this.data.title?.length > 0) 
            {
                isValid = true;
            }

            if(!isValid) {
                alert('Please fill out all required fields');
            }

            return isValid;
        }, 


        setCurrentOpportunityData(opportunity) {
            this.data.opportunity_id = +opportunity.ID;
            this.data.opportunity_name = opportunity.name;
            this.data.opportunity_title = opportunity.title;

        },

        dropdown() {
            let scope = this;
            document.querySelector('.cmpCreateStrategyContent .form-select').addEventListener('change', function() {
                let opportunityId = +this.value;
                scope.opportunities.forEach(opportunity => {
                    if(opportunityId == +opportunity.ID) {
                        scope.setCurrentOpportunityData(opportunity);
                    }
                });
            });
        }
    },

    async mounted() {
        await this.$store.dispatch('fetchOpportunities');
        setTimeout(()=>{
            this.dropdown();
        }, 200);
        /localhost/.test(document.location.host) && (window.modal = this);
    },
}
</script>