<template lang="">
    <div class="d-flex flex-row ai-generate border-top">
        <!-- Sidebar: Parameters -->
        <div class="params overflow-scroll pe-3 border-end">
            <small class="fs-6 d-block pt-3">{{ current?.usecase?.caption }}</small>
            <form @submit.prevent="" class="h-100 pt-3 form-generator">

                <InputCounter 
                    class="mb-3 context-input" v-for="(input, index) in current?.usecase?.contextInputs"
                    :id="input?._id" 
                    :ref="input?._id" 
                    :input="input" 
                    :key="index"></InputCounter>



                <button type="submit"
                    class="btn btn-primary btn-pill btn-generate btn-unlimited py-2 w-100"
                    @click="generate"
                    :class="[allowSubmit? '' : 'disabled']"
                    >
                    <div class="spinner-border spinner-border-sm me-3" role="status"
                        v-if="isLoading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    
                    Generate Content</button>


                <!--
                <button type="submit"
                    v-if="!useUnlimited"
                    class="btn btn-primary btn-pill btn-generate py-2 w-100"
                    @click="generate"
                    :class="[allowSubmit? '' : 'disabled']"
                    >
                    <div class="spinner-border spinner-border-sm me-3" role="status"
                        v-if="isLoading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    
                    Generate Content</button>

                <button type="submit"
                    v-if="useUnlimited"
                    class="btn btn-primary btn-pill btn-generate btn-unlimited py-2 w-100"
                    @click="unlimited"
                    :class="[allowSubmit? '' : 'disabled']"
                    >
                    <div class="spinner-border spinner-border-sm me-3" role="status"
                        v-if="isLoading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    
                    Generate Content</button>
                -->

                    <!-- @@@{{current.opportunity}} -->
                <div class="d-flex flex-row alert alert-warning fs-6 mt-4 p-2 text-black-50" role="alert"
                    v-if="lastError.length">
                    <div class="material-icons-outlined me-2 text-warning">
                    warning
                    </div>
                    <div class="flex-fill" v-html="lastError"></div>
                </div>
            </form>
        </div>

        <!-- Results -->
        <div class="results overflow-scroll px-3 flex-fill">
            <h4 class="fs-5 text-dark py-3">Results {{ results?.length }}</h4>

            
            <div v-for="(entry, index) in results" 
                :key="index" 
                :id="`usecase-${index}`"
                class="usecase-entry pt-2 pb-3 px-3 mb-5 border rounded-3">

                <!-- column right -->
                <div>
                    <div class="text-end pt-1 mb-3">
                        <a class="btn btn-light px-1 py-0 me-2 btn-delete-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="remove(entry, 'usecase-' + index, $event)">
                            <span class="material-icons-outlined float-start" :style="{
                                'margin-top': '-2px'
                            }">
                            delete
                            </span>
                        </a>
                        <a class="btn px-3 py-0 btn-select-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select($event)">
                            <span>
                                SELECT
                            </span>
                        </a>
                        <a class="btn btn-light text-primary px-3 py-0 btn-deselect-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select(null)">
                            <span>
                                DESELECT
                            </span>
                        </a>
                    </div>

                    <div class="text-content w-100" v-html="entry.text"></div>
                    <sub class="d-none text-end text-secondary pb-1">{{entry.ID}}</sub>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import InputCounter from "@/components/forms/fields/InputCounter.vue";

const $ = window.$;

export default {

    name: 'ModalContentAIGenerate',
    components: {
        InputCounter
    },

    data() {
        return {
            current: {
                usecase: null,
                selection: null,
                /** set from outside the components (e.g. moda.content().current.opportunity = {...}) */
                opportunity: null,
                overview: null,
                html: ''
            },
            results: [],
            lastError: '',
            /**
             * Tracks how many time the user has unsuccessfully
             * tried to get AI content
             */
            attempts: 0,
            /**
             * TODO: Move the switching logid to the store
             * 
             * If user requested AI content too soon for the amount specified, the the request
             * will use the API URL which does not have a time restriction.
             */
            switchAfeter: 2,
            allowSubmit: false,
            isLoading: false, 
            // useUnlimited: true
        }
    },


    computed: {
        useUnlimited() {
            let unlimited, safe_mode = this.$session.get('safe_mode');
            if(safe_mode && safe_mode === true) {
                unlimited = false; 
            } else {
                unlimited = true;
            }
            return unlimited;
        }
    },



    methods: {
        select(event) {
            if(!event) {
                this.current.html = '';
                this.current.selection = null;
                $('.usecase-entry').removeClass('active');
                return;
            }

            let target = $(event.currentTarget).attr('target');

            $('.ai-generate .usecase-entry').removeClass('active');
            $('.ai-generate .usecase-entry[id="'+ target +'"]').addClass('active');

            this.current.html = $('.ai-generate .usecase-entry[id="'+ target +'"] .text-content').html();
            this.current.selection = {text: this.current.html};
        },


        async remove(usecase, elId, event) {
            usecase, elId, event

            if(!confirm('Are you sure you want to delete this entry?')) {
                return;
            }

            let deleted = await this.$store.getters.wp.run('deleteAIStrategy', [usecase.ID]);

            if(deleted) {
                for(let i=0; i<this.results.length; i++) {
                    if(this.results[i].ID == usecase.ID) {
                        this.results.splice(i, 1);
                        break;
                    }
                }
                this.$root.toast('Success! AI suggestion was deleted.');

            } else {
                alert('ERROR: unable to delete content.');
            }
        },



        async generate() {
            this.lastError = '';

            let usecaseIdentifier = this.useUnlimited? 'USECASE_ID' : 'USECASE';
            let usecaseKeys = {USECASE_ID: '_id', USECASE: 'slug'};
            let inputs = {};
            let $form = $('.ai-generate .form-generator');
            let $inputs = $form.find('.context-input .form-control');
                $inputs.each((i, item)=>{
                    let $item = $(item);
                    let name = $item.prop('name');
                    let value = $item.val();
                    inputs[name] = value;
                });

            // if multiple attempts, then swith to API request
            if(this.attempts == this.switchAfeter) {
                usecaseIdentifier = 'USECASE';
                this.attempts = 0;
            }

            /**
             * Add the identifyer for the usecase (USECASE_ID for KEY option, and USECASE for API option). E.g.
             * 
             * inputs['USECASE_ID'] = this.current?.usecase?.[ '_id' ]; OR
             * inputs['USECASE'] = this.current?.usecase?.[ 'slug' ];
             */
            inputs[usecaseIdentifier] = this.current?.usecase?.[ usecaseKeys[usecaseIdentifier] ];

            this.inProgress(true);
            let aiContent = await this.$root.generateAIContent(inputs, this.current.opportunity);

            // console.log('AI Content', aiContent);

            this.addContent(aiContent);
            this.inProgress(false);
        },


        /* generateWithAPI */
        // async generate() {
        //     this.lastError = '';
        //     let inputs = {};
        //     let $form = $('.ai-generate .form-generator');
        //     let $inputs = $form.find('.context-input .form-control');
        //         $inputs.each((i, item)=>{
        //             let $item = $(item);
        //             let name = $item.prop('name');
        //             let value = $item.val();
        //             inputs[name] = value;
        //         });

        //     inputs['USECASE'] = this.current?.usecase?.slug;


        //     this.inProgress(true);
        //     let aiContent = await this.$root.AIGenerate(inputs, this.current.opportunity);

        //     this.addContent(aiContent);
        //     this.inProgress(false);
        // },


        // async unlimited() {
        //     this.lastError = '';
        //     let inputs = {};
        //     let $form = $('.ai-generate .form-generator');
        //     let $inputs = $form.find('.context-input .form-control');
        //         $inputs.each((i, item)=>{
        //             let $item = $(item);
        //             let name = $item.prop('name');
        //             let value = $item.val();
        //             inputs[name] = value;
        //         });

        //     let usecaseId = this.current?.usecase?._id; usecaseId;
        //     this.inProgress(true);




        //     /**
        //      * Forrce error to allow handling
        //      * 
        //      * 
        //      * 
        //      * 
        //      */
        //     // let aiContent = await new Promise(function(res) {
        //     //     setTimeout(()=>{
        //     //         let aiContent = {
        //     //             "success": false,
        //     //             "message": "Uh-oh, no output \ud83d\ude41. Looks like you beat the AI this time, try changing the text or generating again."
        //     //         };
        //     //         res(aiContent);
        //     //     }, 2000);
        //     // });



        //     let aiContent = await this.$root.AIGenerateU(usecaseId, inputs, this.current.opportunity);
        //     console.log(aiContent);


        //     this.addContent(aiContent);
        //     this.inProgress(false);
        // },


        /**
         * 
         * 
         * @param {Object} content
         * @param {Object} content.success
         * @param {Object} content.message
         * @param {Object} content.ID
         * @param {Object} content.text
         * 
         */
        addContent(content) {
            this.select(null);

            if(!content?.text) {
                this.showError(content);
                this.attempts += 1;
                return;
            } else {
                // Replace rytr from all responses
                content.text = content.text.replace(/rytr/ig, 'CoreOffer');
            }


            // if(!content.success) {
            //     content.text = 'ERROR: ' + content.text;
            // }
            this.results.unshift(content);
            this.attempts = 0;


            setTimeout(()=>{
                // scroll the results DIV to the top
                $('.ai-generate .results')[0].scrollTop = 0;

                // call select and pass a fake event object
                let btn = $('.ai-generate .usecase-entry:first .btn-select-strategy');
                this.select({
                    currentTarget: btn
                })
            }, 100);
        },


        inProgress(flag) {
            if(flag) {
                $('.btn-generate').addClass('disabled');
                $('.ai-generate').addClass('loading');
                this.isLoading = true;
            } else {
                $('.btn-generate').removeClass('disabled');
                $('.ai-generate').removeClass('loading');
                this.isLoading = false;
            }
        },


        validate() {
            return (this.current?.html?.length ? true : false);
        },

        validateInput() {
            let valid = true;
            let inputs = this.current?.usecase?.contextInputs;
            
            if(inputs?.length === 0) {
                return false;
            }

            inputs.forEach((item)=>{
                let ref = this.$refs[item._id];
                if(ref?.getValue()?.length === 0 || ref.hasError()) {
                    valid = false;
                }
                // console.log(ref);
            });

            this.allowSubmit = valid;
            return valid;
        }, 


        showError(content) {
            let error = 'Sorry, and unexpected has happened. Please wait a few seconds and try again.';
            let tooSoon = content.message?.indexOf('Looks like you beat the AI this time') >= 0? true : false;
            if(tooSoon) {
                error = 'Unable to process your request. Please wait a few seconds and try again.';
            }

            this.lastError = error;
        },


        autofill() {
            // console.log('contextInputs', this.current?.usecase?.contextInputs);
            // console.log('overview', this.current.overview);

            let inputs = this.current?.usecase?.contextInputs;

            for (let index = 0; index < inputs.length; index++) {
                let value = '';
                let input = inputs[index];
                let keyLabel = input.keyLabel;

                switch(keyLabel) {
                    /**
                     * call to action
                     * 
                     * brand name
                     * 
                     * Copywriting Framework: AIDA
                     * Copywriting Framework: PAS
                     * 
                     * Facebook, Twitter, LinkedIn Ads
                     * 
                     * Landing Page & Website Copies
                     */
                    case 'DESCRIPTION_LABEL':
                    case 'BRAND_DESCRIPTION_LABEL':
                    case 'PRODUCT_OR_BRAND_DESCRIPTION_LABEL':
                    case 'PRODUCT_DESCRIPTION_LABEL':
                    case 'ABOUT_PRODUCT_LABEL':
                        value = this.current.overview?.product_description || ''
                    break;





                    /**
                     * blog idea & outline
                     * 
                     * Facebook, Twitter, LinkedIn Ads
                     */
                    case 'PRIMARY_KEYWORD_LABEL':
                    case 'TARGET_KEYWORD_LABEL':
                        value = this.current.overview?.keywords || ''
                    break;



                    /**
                     * Facebook, Twitter, LinkedIn Ads
                     */
                    case 'PRODUCT_NAME_LABEL':
                        value = this.current.overview?.product_name || ''
                    break;

                    /**
                     * Landing Page & Website Copies
                     */
                    case 'WEBSITE_NAME_LABEL':
                        value = this.current.overview?.website_name || ''
                    break;

                    /**
                     * Landing Page & Website Copies
                     */
                    case 'ABOUT_WEBSITE_LABEL':
                        value = this.current.overview?.website_description || ''
                    break;

                    /**
                     * Product Description (bullet points)
                     * 
                     * Landing Page & Website Copies
                     */
                    case 'PRODUCT_FEATURES_LABEL':
                    case 'FEATURES_LABEL':
                        value = this.current.overview?.product_features || ''
                    break;
                }

                $('.form-generator #' + keyLabel).val(value).keyup();
            }


            this.validateInput();
        },

        async overview() {
            let opportunityId = this.$store.getters.currentOpportunity?.ID || this.current?.opportunity?.opportunity_id;
            if(!opportunityId) {
                return;
            }

            let opportunityData = await this.$store.getters.wp.getOpportunity(opportunityId);
            let overview = opportunityData?.content?.overview?.fields || {};

            this.current.overview = overview;
        },

        async init() {
            this.validateInput();
            let inputs = $('.ai-generate .context-input');
                inputs.each((i, item)=>{
                    $(item).on('keyup', ()=>{
                        this.validateInput();
                    });
                });
            let opportunityId = this.$store.getters.currentOpportunity?.ID || this.current?.opportunity?.opportunity_id;
            this.results = await this.$root.AIGetHistory(opportunityId);
        },

    },

    mounted() {
        setTimeout( async()=> {
            await this.overview();
            this.autofill();
            await this.init();
        }, 500);
        // window.results = this;
    }
}
</script>

<style>

.ai-generate {
    max-height: 650px;
    min-height: 650px;
    overflow: hidden;
}
.ai-generate .params {
    width: 400px;
}


.ai-generate .usecase-entry .text-content {
    font-size: 16px;
}


.ai-generatey .usecase-entry .text-content h1, 
.ai-generatey .usecase-entry .text-content h2, 
.ai-generatey .usecase-entry .text-content h3, 
.ai-generatey .usecase-entry .text-content h4, 
.ai-generatey .usecase-entry .text-content h5, 
.ai-generatey .usecase-entry .text-content ul, 
.ai-generatey .usecase-entry .text-content ol, 
.ai-generatey .usecase-entry .text-content li, 
.ai-generatey .usecase-entry .text-content pre, 
.ai-generatey .usecase-entry .text-content p {
    margin-bottom: 0.8rem;
}

.ai-generate .usecase-entry .text-content h1 { font-size: 2em; }
.ai-generate .usecase-entry .text-content h2 { font-size: 1.5em; }
.ai-generate .usecase-entry .text-content h3 { font-size: 1.17em; }
.ai-generate .usecase-entry .text-content h4 { font-size: 1em; }
.ai-generate .usecase-entry .text-content h5 { font-size: 0.83em; }



.ai-generate .results {
    width: calc( 100% - 400px);
}


.ai-generate .usecase-entry {
    transition: all 0.15s;
    background-color: #f5f9ff;
}

.ai-generate .usecase-entry:hover {
    border-color: #aaa;
    box-shadow: 0 4px 8px #404fff2b !important;
}

.ai-generate .usecase-entry.active {
    border-color: #404fff !important;
    background-color: #404fff;
    color: #fff;
}
.ai-generate .usecase-entry .text-content {
    cursor: pointer;
    border: dotted 1px transparent;
    pointer-events: none;
}

.ai-generate .usecase-entry:hover .text-content {
    border-color: rgba(255, 255, 255, 0.2);
}
.ai-generate .usecase-entry:hover .text-content .material-icons-outlined {
    font-size: 28px !important;
}


/* buttons: delete */
.ai-generate .usecase-entry .btn-delete-strategy {
    color: var(--secondary);
}
.ai-generate .usecase-entry .btn-delete-strategy:hover {
    color: var(--danger) !important;
}
/* buttons: select */
.ai-generate .usecase-entry .btn-select-strategy {
    display: inline-block;
    background-color: #dceaff;
}
.ai-generate .usecase-entry .btn-select-strategy:hover {
    color: #fff;
    background-color: #404fff;
}
.ai-generate .usecase-entry.active .btn-select-strategy {
    display: none;
}
/* buttons: deselect */
.ai-generate .usecase-entry .btn-deselect-strategy {
    display: none;
}

.ai-generate .usecase-entry.active .btn-deselect-strategy {
    display: inline-block;
}


.ai-generate .usecase-entry sub {
    opacity: 0;
    transition: opacity 0.2s;
}
.ai-generate .usecase-entry:hover sub {
    opacity: 1;
}

/* .ai-generate .usecase-entry .icon-unchecked {
    color: #ddd;
}
.ai-generate .usecase-entry .icon-checked {
    color: #fff;
}
.ai-generate .usecase-entry:not(.active) .icon-checked,
.ai-generate .usecase-entry .icon-unchecked {
    display: none;
}
.ai-generate .usecase-entry:not(.active) .icon-unchecked,
.ai-generate .usecase-entry.active .icon-checked {
    display: inline-block;
} */
</style>