<template lang="">
    <a class="btn appcui-bg-secondary appcui-btn-icon-circle ui-btn-circle"
        :class="[classes]" 
        @click="action?.(uid)">
        <span class="material-icons-outlined">
        {{icon}}
        </span>
    </a>
</template>
<script>

import common from './Common.js';

export default {
    props: { ...common.props }, 

    data() {
        return { ...common.data }
    },

    computed: { ...common.computed }, 


    methods: { ...common.methods }
}
</script>