<template lang="">
	<div class="modal fade amk center from-center r8" id="modal-plugin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
		<div class="modal-dialog d-flex" 
            :style="styleModalDialog"
            >
			<div class="modal-content border-0 shadow-lg">
				<div class="modal-header border-0">
                    <h5 class="modal-title fs-4 font-size-heading-md font-weight-normal">{{data.title}}</h5>
					<a href="#" @click.prevent="fullscreen" class="float-right btn-fullscreen"
                        v-if="data?.allowFullscreen">
                        <span class="material-icons">
                        fullscreen
                        </span>
                    </a>
				</div>

				<div class="modal-body p-0 d-flex flex-column extension-container">
				</div>
				<div class="modal-footer px-3 border-0">
					<button type="button" class="btn btn-link text-decoration-none text-uppercase" data-bs-dismiss="modal">CLOSE</button>
					<button @click.prevent="update"  type="button" class="btn btn-link text-decoration-none text-uppercase"
                        v-if="!data?.options?.standalone"
                    >UPDATE</button>
				</div>
			</div>
		</div>
	</div>

	<!-- right panel -->
    <div class="offcanvas offcanvas-end border-0 shadow" tabindex="-1" id="right-panel"
		:class="[data?.ui == 'left-panel'? 'offcanvas-start' : 'offcanvas-end']"
		:style="styleOffCanvas">
        <div class="offcanvas-header border-bottom px-4">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">{{data.title}}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body extension-container d-flex flex-column p-0"></div>
    </div>



	<!-- left panel -->
    <div class="offcanvas offcanvas-start border-0 shadow" tabindex="-1" id="left-panel"
		:style="styleOffCanvas">
        <div class="offcanvas-header border-bottom px-4">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">{{data.title}}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body extension-container d-flex flex-column p-0"></div>
    </div>
</template>


<script>
export default {
	data() {
		return {
			/**
			 * Plugin data (JSON)
			 */
			data: {},

			/**
			 * The plugin specifies the height of the IFRAME, this will
			 * take into account the height of the modal header and footer.
			 *
			 * NOTE: this will be different depending on the design.
			 */
			// HEIGHT_PADDING: 160,
		};
	},

	computed: {
		styleModalDialog() {
			// debugger
			let maxWidth = 1280;
			let minWidth = this.data?.["minWidth"] || 500;
				minWidth = +minWidth > maxWidth ? maxWidth : +minWidth;
				minWidth = minWidth === 0 ? "100vw" : minWidth + "px";

			// let minHeight = this.data?.["minHeight"] || 650;
				// minHeight = +minHeight === 0? '100vh' : minHeight + "px";

			maxWidth = maxWidth + "px";

			// console.log("styleDialog", this.data);
			// console.log(minWidth, minHeight, maxWidth);

			return { 
				"min-width": minWidth,
			};
		},

		styleOffCanvas() {
			let maxWidth = 720;
			let minWidth = +this.data?.["minWidth"] || 500;
				minWidth = minWidth > maxWidth ? maxWidth : minWidth;

			// console.log("styleOffCanvas", this.data);
			// console.log(minWidth, maxWidth);

			let style = {
				"min-width": minWidth + "px",
			};

			// this.data?.ui == 'right-panel' && (style['right'] = '0px');
			// this.data?.ui == 'left-panel'  && (style['left']  = '0px');

			return style;
		},

		modalClasses() {
			let classes = "";
				classes += 
					+this.data?.["minWidth"] === 0 && +this.data?.["minHeight"] === 0
					? "fullscreen"
					: "";


			return [classes];
		},
	},

	methods: {
		// action(plugin) {
		//     this.hide(()=>{
		//         this.$root.$events.trigger(this.$root.$events.PLUGIN_SELECTED, [plugin]);
		//     })
		// },

		target() {

		},

		/**
		 * Set/Get the current BridgeImpl.
		 *
		 * IMPORTANT: _bridge is defined at runtime to prevent the property from
		 * being reactive. When reactive, brigde causes cors issues.
		 */
		bridge(bridge) {
			if (bridge) {
				this._bridge = bridge;
			} else {
				return this._bridge;
			}
		},

		/**
		 * @param {String} what Valid values are:
		 * 	- modal
		 *  - window
		 *  - hidden
		 *  - inline
		 *  - left-panel
		 *  - right-panel
		 */
		container() {
			let selector = '#modal-plugin .extension-container';
			if (/(left|right)-panel/.test(this.data?.ui)) {
				selector = '#'+ this.data?.ui +' .extension-container';
			}
			let $container = window.$(selector);
			return $container;
		},

		/**
		 *
		 * the Plugin component will call update in the extension IFrame
		 * and the reply can be capture by "bridge.reply.update"
		 */
		async update() {
			let bridge = this.bridge();
			let data = await bridge.send("update");
			data;
			// console.log(data);
		},

		show() {
			console.log('*** show() ***');
			let $panel, panel;
			switch (this.data?.ui) {
				case 'modal':
					document.querySelector("#modal-plugin").classList.remove("fullscreen");
					document.querySelector("#modal-plugin iframe").style["min-height"] = (this.data?.minHeight || 500) + "px";
					this.modalClasses.forEach((cls)=>{
						cls && document.querySelector("#modal-plugin").classList.add(cls);
					});
					window.$("#modal-plugin").modal("show");
					break;

				case 'right-panel':
				case 'left-panel':
					panel = document.querySelector('#' + this.data?.ui);
					$panel = new window.bootstrap.Offcanvas(panel);
					$panel.toggle();
					break;
				default:
					break;
			}
		},

		hide(callback) {
			switch (this.data?.ui) {
				case 'modal':
					window.$("#modal-plugin").modal("hide");
					break;

				case 'right-panel':
				case 'left-panel':
					window.$('#' + this.data?.ui + ' [data-bs-dismiss="offcanvas"]').click();
					break;
				default:
					break;
			}
			setTimeout(() => {
				typeof callback == "function" && callback.apply(null);
				this.reset();
			}, 500);
		},

		close() {
			// console.trace();
			// debugger
			this.hide();
		},

		fullscreen() {
			let isFullscreen = document.querySelector("#modal-plugin").classList.contains("fullscreen");
			if (!isFullscreen) {
				document.querySelector("#modal-plugin").classList.add("fullscreen");
			} else {
				document.querySelector("#modal-plugin").classList.remove("fullscreen");
			}
		},

		iframe(url, onload) {
			console.log('*** iframe() ***');
			let $iframe = window.$(
				`<iframe class="flex-fill" style="width: 100%; transition: all .2s" src="${url}" id="outlet-extension"></iframe>`
			);
			$iframe[0].addEventListener(
				"load",
				() => {
					typeof onload == "function" && onload.apply(null);
				},
				{ one: true }
			);

			let $container = this.container();
			//   window.$("#modal-plugin .extension-container").empty();
			//   window.$("#modal-plugin .extension-container").append($iframe);
			$container.empty();
			$container.append($iframe);

			/**
			 * Return the actual HTMLIFrameElement
			 */
			return $iframe[0];
		},

		reset() {
			this.data = {};
			this.container().empty();
		},
	},

	mounted() {
		// this.$modal = window.$('#modal-select-plugin')
		// console.log(this.$modal);
		window.extension = this;
	},
};
</script>
<style lang="css" scoped>
iframe {
  border: none;
}

.btn-fullscreen {
  width: 36px;
  height: 36px;
  display: inline-block;
  color: rgb(150, 150, 150);
  background-color: #fff;
  border: solid 1px #e4e6eb !important;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  margin-right: -10px;
  transition: all 0.2s;
}

.btn-fullscreen:hover {
  background-color: #e4e6eb;
  color: #333;
}

.btn-fullscreen .material-icons {
  margin-top: 5px;
}

.modal .modal-content {
  padding: 0.5rem 0rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.modal.fullscreen {
  border-radius: 0px !important;
}
.modal.fullscreen .modal-content {
  /* margin-top: 0; */
  margin-top: 0 !important;
}

.modal .modal-header,
.modal .modal-footer {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
</style>