<template>

    <div class="ai-input"
         :class="[maxed]"
        >
        <label for="offer_name" class="form-label">{{ input.label }}</label>
        <input v-if="input.inputType == 'text'" type="text" class="form-control bg-light" :name="input.keyLabel" :id="input.keyLabel" placeholder="" value="">
        <textarea v-if="input.inputType == 'textarea'" class="form-control bg-light" :name="input.keyLabel" :id="input.keyLabel" rows="3"></textarea>
        <div class="d-flex flex-row fs-6 ">
            <span v-if="!input?.isRequired" class="">(optional)</span>

            <div class="flex-fill ai-input-count text-end">
                <span class="count">{{ count }}</span>/<span class="max">{{input.inputMaximumCharacters}}</span>
            </div>
        </div>
    </div>
</template>

<script>

const $ = window.$;

export default {
    name: "InputCounter",
    props: {
        input: Object
    }, 

    data() {
        return {
            count: 0
        };
    },

    computed: {
        maxed() {
            let cssClass = this.count > this.input?.inputMaximumCharacters? 'error' : '';
            return cssClass;
        }
    },

    methods: {
        hasError() {
            return this.count > this.input?.inputMaximumCharacters;
        }, 

        getValue() {
            return $(this.$el).find('.form-control')[0].value;
        }
    },

    mounted() {
        $(this.$el).find('.form-control').on('keyup', (e) =>{
            this.count = e.target.value?.length;
        });

        // $(this.$el).find('.form-control').on('input ', (e) =>{
        //     this.count = e.target.value?.length;
        // });
    }
    
};
</script>

<style>
.ai-input.error .form-control {
    border: solid red 1px !important;
    background-color: rgba(255, 0, 0, 0.1) ;
}
.ai-input.error .ai-input-count {
    color: red;
}

</style>
