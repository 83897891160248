/**
 * 
 * @see https://www.npmjs.com/package/vue-session
 */

var STORAGE = null;
var VueSession = {
    key: 'vue-session-key',
    flash_key: 'vue-session-flash-key',
    setAll: function(all){
        STORAGE.setItem(VueSession.key,JSON.stringify(all));
    }
}


VueSession.install = function(app, options) {
    app, options;


    // if(!window?.$session) {
    //     window.$session = {
    //         google_auth_url: 'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=31381570039-mqbi4gatl1te41jhc1l7uic3c9rs7t1o.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fsmpl.page%2Fwelcome&state&scope=email%20profile&approval_prompt=auto'
    //     }
    // }

    if(options && 'persist' in options && options.persist) {
        STORAGE = window.localStorage;
    } else {
        STORAGE = window.sessionStorage;
    }


    app.config.globalProperties.$session = {
        flash: {
            parent: function(){
                return app.config.globalProperties.$session;
            },
            get: function(key){
                var all = this.parent().getAll();
                var all_flash = all[VueSession.flash_key] || {};

                var flash_value = all_flash[key];

                this.remove(key);

                return flash_value;
            },
            set: function(key, value){
                var all = this.parent().getAll();
                var all_flash = all[VueSession.flash_key] || {};

                all_flash[key] = value;
                all[VueSession.flash_key] = all_flash;

                VueSession.setAll(all);
            },
            remove: function(key){
                var all = this.parent().getAll();
                var all_flash = all[VueSession.flash_key] || {};

                delete all_flash[key];

                all[VueSession.flash_key] = all_flash;
                VueSession.setAll(all);
            }
        },
        getAll: function(){
            var all = JSON.parse(STORAGE.getItem(VueSession.key));
            return all || {};
        },

        attach: function(object) {
            for (const key in object) {
                this.set(key, object[key])
            }
        },


        set: function(key,value){
            if(key == 'session-id') return false;
            var all = this.getAll();

            if(!('session-id' in all)){
                this.start();
                all = this.getAll();
            }

            all[key] = value;

            VueSession.setAll(all);
        },
        get: function(key){
            var all = this.getAll();
            return all[key];
        },
        start: function(){
            var all = this.getAll();
            all['session-id'] = 'sess:'+Date.now();

            VueSession.setAll(all);
        },
        renew: function(sessionId){
            var all = this.getAll();
            all['session-id'] = 'sess:' + sessionId;
            VueSession.setAll(all);
        },
        exists: function(){
            var all = this.getAll();
            return 'session-id' in all;
        },
        has: function(key){
            var all = this.getAll();
            return key in all;
        },
        remove: function(key){
            var all = this.getAll();
            delete all[key];

            VueSession.setAll(all);
        },
        clear: function(){
            var all = this.getAll();

            VueSession.setAll({'session-id': all['session-id']});
        },
        destroy: function(){
            VueSession.setAll({});
        },
        id: function(){
            return this.get('session-id');
        }
    }
};


export default VueSession;