

export default {

    data: {
        data: {},
        tmpl: null,
        loaded: false
    },

    computed: {},

    methods: {
        setup(context) {
            context
            // this.data = {...context};
            // if(this.data?.ID) {
            //     this.$store.getters.wp.getOpportunityTemplate(this.data.ID).then( (tmpl) => {
            //         this.tmpl = tmpl;
            //         this.loaded = true;
            //     });
            // }
        },

        reset() {
            this.data = {};
            this.tmpl = null;
        }
    }, 

    mounted() {
        this.data = {};
    }

}