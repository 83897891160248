<template lang="">
    <a class="btn btn-icon">
        <span class="material-icons-outlined">
        {{icon}}
        </span>

        <span class="">
            {{label}}
        </span>
    </a>
</template>
<script>
export default {
    
    props: {
        label: String,
        icon: {
            type: String, 
            default: 'check_circle'
        }
    }
}
</script>
<style scoped>
/* .active .btn-icon {
    background-color: var(--bg-primary);
} */
.btn-icon {
    width: 36px;
    height: 36px;
    background-color: var(--bg-secondary);
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    padding: 5px 0;
}
</style>