
/**
 * Cosntructor of the entry point. This class exposes Wordpress and PHP
 * methods to the front.
 * 
 * IMPORTANT: the use of this approach is unclear since this will allow
 * Javascript to run any command in Wordpress or PHP.
 * 
 */


import {Dispatcher} from './helper.js';
import $ from 'jquery';
// import axios from 'axios';


// const $ = window.$;
function WP(wpURL) { 
    this.WP_URL = wpURL || this.WP_URL;

    this.events = new Dispatcher();
    this.events.SUCCESS = 'onsuccess';
    this.events.ERROR = 'onerror';
    this.events.TOKEN_ERROR = 'ontokenerror';

    /**
     * Listen to reponse errors (e.g. invalid token)
     * 
     * this.events.on(this.events.ERROR, (response)=>{
     * if('data' in response && response.data == 403) {
     *     console.log('403', response);
     * }
     * );
     */


    /**
     * 
     * 
     * 
     * 
     * 
     */
    this.wp_signon = function(option) {
        return new Promise((resolve, reject)=> {
            reject;
            this.run('wp_signon', [option], null).then( ( data ) => {
                if(data != null && 'token' in data) {
                    this.token(data.token);
                }

                resolve(data);
            });
        });
    };



    this.wp_signout = function() {

        return new Promise((resolve, reject)=> {
            reject;
            this.run('wp_logout', [], null).then( ( data ) => {
                if(data) {
                    window.localStorage.removeItem(this.tokenName);
                }
                resolve(data);
            });
        });
    };


    // this.wp_get_current_user = function() {
    //     return new Promise((resolve, reject)=> {
    //         reject;
    //         this.run('wp_signon', [], null).then(data=>{
    //             resolve(data);
    //         });
    //     });
    // };



    // this.expose('wp_signon', this);
    // this.expose('wp_get_current_user', this);

}


WP.prototype = {
    /**
     * 
     */

    debug: false,


    tokenName: 'appcropolis.expose.token',



    /**
     * The Wordpress AJAX URL
     */
    // AJAX_URL: '/public/wp-admin/admin-ajax.php',


    WP_URL: '/public',

    WP_ADMIN_AJAX: 'wp-admin/admin-ajax.php',



    /**
     * The action registered by the Wordpress hook (add_action('wp_ajax_nopriv_expose', ...))
     */
    ACTION: 'expose',



    /**
     * Sends a request for running a method in Wordpress.
     * 
     * @param {string} method The method that you wish to run in the server. This
     *                      could be a Wordpress function or a PHP function.
     * 
     * @param {array} params A list of parameters that are required by the method 
     *                      you wish to run.
     *
     * @param {scope} scope The object in which the "method" will be called.
     *                      The scope for the method can also be specied when 
     *                      providing an array as a "method", e.g.
     * 
     *                      wp.run(['MyClass', 'theMethod'], ...).
     * 
     * @example
     * 
     * // #1: Get a single post from Wordpress
     * 
     * wp.run('get_post', [1], null).then(data=>{
     *      console.log(data);    
     * });
     * 
     *
     * // #2: Call PHP 'date'
     * 
     * wp.run('date', ['Y-m-d H:i:s'], null).then(data=>{
     *      console.log(data);    
     * });
     * 
     *
     * // #3: Call terminal list command
     * 
     * wp.run('exec', ['ls -al'], null).then(data=>{
     *      console.log(data);    
     * });
     */
    run: function(method, params, scope) {
        scope;
        return new Promise((resolve, reject)=> {
            let url = this.WP_URL + '/' + this.WP_ADMIN_AJAX;

            // axios({
            //     url: url,
            //     method: 'post',
            //     data: {
            //         action: this.ACTION,
            //         method: method,
            //         params: params, 
            //         token: this.token()
            //     }
            // })
            // .then(function (response) {
            //     // console.log(response);
            //     this.debug && console.log(response);
                
            //     if('success' in response && response.success === false) {
            //         resolve(null);
            //         this.events.emit(this.events.ERROR, response);
                    
            //     } else {
            //         resolve(response.data);
            //         this.events.emit(this.events.SUCCESS, response.data);
            //     }
            // })
            // .catch(function (error) {
            //     reject(error);
            // });

            url = url + '/' + method;

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    action: this.ACTION,
                    method: method,
                    params: params, 
                    token: this.token()
                }, 

            }).then((response)=> {
                this.debug && console.log(response);
                
                if('success' in response && response.success === false) {
                    resolve(response.data);
                    this.events.emit(this.events.ERROR, response);
                    
                } else {
                    resolve(response.data);
                    this.events.emit(this.events.SUCCESS, response.data);
                }
            }).catch((error)=>{
                reject(error);
            });
        });
    },



    token: function(key) {
        if(typeof key == 'undefined') {
            return window.localStorage[this.tokenName];
        } else {
            window.localStorage.setItem(this.tokenName, key);
        }
    },

    /**
     * Exposes a server side method to Javascript
     * 
     * @param {string} name The name of the server side method you wish to expose.
     *
     * @param {object} context The object or contex in which the method will be exposed.
     *                  if the context is not provided, the method will be added to the 
     *                  WP instance.
     * 
     * @param {string} alias The alais of the server side method you wish to expose.
                        This will allow you to use a different name in Javascript.
     * 
     * @example
     * 
     * // #1: Expose "scandir" to the window context.
     * 
     * wp.expose('scandir', window);
     *
     * // #1: Expose "scandir" to the your own app.
     * 
     * var myApp = {}
     * wp.expose('scandir', myApp);
     * myApp.scandir('./').then(data=>{
     *      console.log(data);
     * });
     *
     */
    expose: function(name, context=null, alias=null) {
        var self = this;
        context = context || this;
        name = alias || name;

        context[name] = async function() {
            let data = await self.run(name, arguments, null);
            return data;
        }

        return context[name];
    }, 


    /**
     * Wordpress get_post method.
     * 
     * @param {number} id The post ID.
     * 
     * @return {promise}
     * 
     * @example
     * 
     * #1: Get post ID 3
     * 
     * wp.get_post(3).then(data=>{
     *      console.log(data);    
     * });
     * 
     * #2: Using async awiat
     * (async function(){
     *      var post = await get_post(2);
     *      console.log(post);
     * })();
     * 
     */
    get_post: function(id) {
        return new Promise((resolve, reject)=> {
            reject;
            this.run('get_post', [id], null).then(data=>{
                resolve(data);
            });
        });
    },


    /**
     * Wordpress get_option method.
     * 
     * @param {string} option The option name.
     * 
     * @return {promise}
     * 
     * @example
     * 
     * #1: Get site URL option value.
     * 
     * wp.get_option('site_url').then(data=>{
     *      console.log(data);    
     * });
     */
    get_option: function(option) {
        return new Promise((resolve, reject)=> {
            reject;
            this.run('get_option', [option], null).then(data=>{
                resolve(data);
            });
        });
    },


    /**
     * Use PHP 'date' function to get a formatted date.
     * 
     * @param {string} format The format in which you wish the date to be returned.
     * 
     * @return {promise}
     * 
     * @example
     * 
     * #1: Get site URL option value.
     * 
     * wp.get_option('date').then(data=>{
     *      console.log(data);    
     * });
     */
    date: function(format) {
        return new Promise((resolve, reject)=> {
            reject;
            this.run('date', [format], null).then(data=>{
                resolve(data);
            });
        });
    }
};


export default WP;