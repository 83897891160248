

export default {
    ID: 0,
    version: '1.0.0',
    name: 'test',
    title: 'Test',
    excerpt: 'Test workbook',
    content: {
        forms: []
    }, 
    meta_input: {
        color: '#FFFFFF'
    }
}


