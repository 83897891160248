<template lang="">
    <div>
        <div v-if="opportiunityTmpl?.content?.hypothesis?.fields?.summary?.length">
            <p class="fs-5 pb-3">Use this template to create a business opportunity ({{ opportiunityTmpl?.title }}).</p>
            <div class="bg-light border-bottom border-top info overflow-scroll px-4.5">
                <p class="py-3">{{ opportiunityTmpl?.content?.hypothesis?.fields?.summary }}</p>
            </div>
        </div>
        <div v-if="opportiunityTmpl?.content?.hypothesis?.fields?.summary?.length === 0 && !loaded">
            loading...
        </div>
    </div>
</template>
<script>
import common from './ModalContent.js';

export default {
    name: 'OpportunityTmplInfo',

    data() {
        return common.data
    },

    computed: {...{
            templateName() {
                return this.data?.name;
            },
            opportiunityTmpl() {
                return this.tmpl;
            }
        }
    },

    methods: {
        ...{
            setup(tmpl) {

                // console.log('setup', tmpl);
                this.tmpl = tmpl;
                this.loaded = true;


                // this.data = {...context};
                // if(this.data?.ID) {
                //     this.$store.getters.wp.getOpportunityTemplate(this.data.ID).then( (tmpl) => {
                //         this.tmpl = tmpl;
                //         this.loaded = true;
                //     });
                // }
            },
        }
    }
}
</script>


<style scoped>
.info {
    max-height: 400px;
    margin: 0 -32px -8px;
}

</style>