<template>
    <div class="cmp-modal-notifier modal fade amk center from-center r8">
      <div class="modal-dialog">
        <div class="modal-content border-0 shadow-lg">
          <div class="modal-header border-0 px-3">
            <h4 class="modal-title font-size-heading-md font-weight-normal">{{ titleLabel }}</h4>
          </div>
          <div class="modal-body pt-0">
            <p class="mb-0" v-html="message"></p>

            <div class="form-group mt-4 mb-2" v-if="type == 'prompt'">
                <div ref="input" class="form-control text-input text-notifier"  style="overflow: hidden;" contenteditable="">{{value}}</div>
            </div>


            <div class="form-group mt-4 mb-2" v-if="type == 'password'">
                <input ref="input" type="password" class="form-control text-input" v-model="value"/>
            </div>

            <div class="form-group mt-4 mb-2" v-if="type == 'textarea'">
                <textarea rows="3" class="form-control text-input"  style="" v-model="value"></textarea>
            </div>

          </div>
          <div class="modal-footer px-3 border-0">
            <button type="button" class="btn btn-link text-decoration-none text-uppercase" 
                v-if="cancelLabel.length"
                @click="cancel()">{{ cancelLabel }}</button>
            <button type="button" class="btn btn-link text-decoration-none text-uppercase" @click="okay()">{{ okayLabel }}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "ModalNotifier",
    props: {
    },
    data() {
        return {
            message: '', 
            callback: null,
            type: null, 
            title: null,
            value: '', 
            ui: {
                labels: {
                    alert: {
                        TITLE: 'Warning',
                        OK: 'OK',
                        CANCEL: ''
                    },
                    message: {
                        TITLE: 'Message',
                        OK: 'OK',
                        CANCEL: ''
                    },
                    confirm: {
                        TITLE: 'Confirm',
                        OK: 'YES',
                        CANCEL: 'NO'
                    },
                    prompt: {
                        TITLE: 'Question',
                        OK: 'CONTINUE',
                        CANCEL: 'CANCEL'
                    },
                    password: {
                        TITLE: 'Password',
                        OK: 'OK',
                        CANCEL: 'CANCEL'
                    },
                    textarea: {
                        TITLE: 'Question',
                        OK: 'OK',
                        CANCEL: 'CANCEL'
                    },
                    default: {
                        TITLE: 'Message',
                        OK: 'ACCEPT',
                        CANCEL: 'CANCEL'
                    }
                }
            }
        }
    },


    computed: {
        okayLabel() {
            let type = this.type || 'default';
            let value = this.ui.labels[type].OK;
            return value;
        }, 

        titleLabel() {
            let type = this.type || 'default';
            let value = this.title || this.ui.labels[type].TITLE;
            return value;
        }, 

        cancelLabel() {
            let type = this.type || 'default';
            let value = this.ui.labels[type].CANCEL;
            return value;
        }
    },

    methods: {
        show() {
            window.$(this.$el).modal('show');
        },

        hide() {
            window.$(this.$el).modal('hide');
        },

        listen() {
            let $input = window.$(this.$refs.input);
            $input.unbind('input').bind('input', () => {
                let value = $input.text();
                this.value = value;
            });
        },


        okay() {
            let value = '' + this.value;
            this.hide();
            setTimeout(()=>{
                typeof this.callback == 'function' && this.callback.apply(null, [true, value]);
            }, 500);
        }, 

        cancel() {
            this.hide();
            setTimeout(()=>{
                typeof this.callback == 'function' && this.callback.apply(null, [false]);
            }, 500);
        }, 

        reset() {
            this.value = '';   
        }


    },

    created() {
        setTimeout(()=>{
            window.$(this.$el).on('shown.bs.modal', ()=> {
                if(this.type == 'prompt') {
                    this.listen();
                    this.$refs.input.focus();
                }
            });
            window.$(this.$el).on('hidden.bs.modal', ()=> {
                // console.log('hidden.bs.modal');
                this.reset();       
            });
        }, 0);
    },

    mounted() {  
    }
};
</script>

<style scoped>
.cmp-modal-notifier {
    --primary: #404fff;
}

.cmp-modal-notifier .modal-content {
    padding: .5rem 1rem!important;
    border-radius: 8px;
    margin-top: -15vh;
}

.cmp-modal-notifier .modal-title {
    font-size: 26px;
}

.cmp-modal-notifier .modal-body {
    font-size: 20px;
    color: var(--secondary);
}

.cmp-modal-notifier .modal-footer button {
    font-size: 18px;
    font-weight: 600;
}

.cmp-modal-notifier .modal-footer button:hover {
    text-decoration: none;
    background-color: var(--primary-light);
}

.cmp-modal-notifier .modal-footer button.btn-link {
    color: var(--primary);
}

</style>
