
class DispatcherEvent {
    constructor(eventName) {
        this.eventName = eventName;
        this.callbacks = [];
    }

    registerCallback(callback) {
        this.callbacks.push(callback);
    }

    unregisterCallback(callback) {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    // fire(data) {
    //     const callbacks = this.callbacks.slice(0);
    //     callbacks.forEach((callback) => {
    //         callback(data);
    //     });
    // }

     fire(/* data */) {
        const callbacks = this.callbacks.slice(0);
        callbacks.forEach((callback) => {
            callback.apply(null, arguments);
        });
    }
}

class Dispatcher {
    constructor() {
        this.events = {};
    }

    // emit(eventName, data) {
    //     const event = this.events[eventName];
    //     if (event) {
    //         event.fire(data);
    //     }
    // }


    emit(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            let args = arguments.length >= 2? Array.prototype.slice.call(arguments, [1]) : [data];
            event.fire.apply(event, args);
        }
    }

    on(eventName, callback) {
        let event = this.events[eventName];
        if (!event) {
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    off(eventName, callback) {
        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }
}


function Observable(target, react) {
    let handler = {
        get: function (target, prop, receiver) {
            target, prop, receiver
            // console.log('GET: ' + prop);
            return target[prop];
        },

        set: function (target, prop, value) {
            target, prop, value
            // console.log('SET: '+ prop);
            target[prop] = typeof value == 'object'? new Observable(value, react) : value;
            typeof react == 'function' && react.apply(null, [target, prop, value]);
            return true;
        },
    };

    let proxy = new Proxy(target, handler);

    for (let i in target) {
        if (target[i] instanceof Object && !Array.isArray(target[i])) {
            proxy[i] = new Observable(target[i], react);
        }


        if (Array.isArray(target[i])) {
            target[i].forEach((item, index) => {
                if (item instanceof Object) {
                    target[i][index] = new Observable(target[i][index], react);
                }
            });
        }
    }


    return proxy;
}




/**
 * 
 * 
 * # Javascript
 * import {observeDOM} from './plugins/utils.js';
 * 
 * setTimeout(()=>{
 * 	let element = window.document.getElementById('watcher');
 * 	observeDOM(element, ()=>{
 * 		console.log('%cCHANGED', 'color: red');
 * 	});
 * }, 1000);
 * 
 * # HTML
 * <noscript v-html="$root.current" ref="watcher" id="watcher"></noscript>
 */

var observeDOM = (function(){
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
  
    return function( obj, callback ){
      if( !obj || obj.nodeType !== 1 ) return; 
  
      if( MutationObserver ){
        // define a new observer
        var mutationObserver = new MutationObserver(callback)
  
        // have the observer observe foo for changes in children
        mutationObserver.observe( obj, { childList:true, subtree:true })
        return mutationObserver;

      } else if( window.addEventListener ){
        // browser support fallback
        obj.addEventListener('DOMNodeInserted', callback, false)
        obj.addEventListener('DOMNodeRemoved', callback, false)
      }
    }
  })()




/**
 * Control access to specific method based on conditions
 * 
 * @param {Object} scope 
 * @param {String} method 
 * @param {Function} resolve Check whether or not the method is allowed (return TRUE or FALSE otherwise)
 * @param {Function} reject The action to be taken if the method is not allowed
 * 
 * @example
 * var allowLogs = true;
 * bouncer(console, 'log', 
 *  function() {
 *       return allowLogs;
 *  }, 
 *  function() {
 *      alert('logs are not allowed');
 *  }
 * );
 * 
 * console.log('Hello'); // will log 'Hello'
 * 
 * allowLogs = false;
 * 
 * console.log('Hello'); // will alert 'logs are not allowed'
 * 
 */
 function bouncer(scope, method, resolve, reject) {
    let original = scope[method];
    scope[method] = function() {
        if(resolve()) {
            original.apply(scope, arguments)
        } else {
            reject();
        }
    }
}



//  function noDevTools(redirect) {
//     let div = document.createElement("div");
//     Object.defineProperty(div, "id", {
//         get: () => {
//           document.location.href = redirect;
//           return true;
//         },
//     });

//     function detectDevTool() {
//         console.log(div);
//     }

//     if (window.attachEvent) {
//         if (
//             document.readyState === "complete" ||
//             document.readyState === "interactive"
//         ) {
//             detectDevTool();
//             window.attachEvent("onresize", detectDevTool);
//             window.attachEvent("onmousemove", detectDevTool);
//             window.attachEvent("onfocus", detectDevTool);
//             window.attachEvent("onblur", detectDevTool);
//         } else {
//             setTimeout(arguments.callee, 0);
//         }
//     } else {
//         window.addEventListener("load", detectDevTool);
//         window.addEventListener("resize", detectDevTool);
//         window.addEventListener("mousemove", detectDevTool);
//         window.addEventListener("focus", detectDevTool);
//         window.addEventListener("blur", detectDevTool);
//     }
// }



module.exports = {

    /**
     * Delasys the execution of a function.
     * 
     * @param {Function} func 
     * @param {Number} delay 
     */
    debounce(func, delay) {
        let inDebounce, runner;
        let AsyncFunction = (async () => {}).constructor;


        if(func instanceof AsyncFunction === true) {
            runner = async function() {
                const context = this
                const args = arguments;
                clearTimeout(inDebounce);

                inDebounce = setTimeout(async () => await func.apply(context, args), delay)
                // inDebounce = setTimeout(() => func.apply(context, args), delay)
            }
        } else {
            runner = function() {
                const context = this
                const args = arguments
                clearTimeout(inDebounce)
                inDebounce = setTimeout(() => func.apply(context, args), delay)
            }
        }

        return runner;
        // return function() {
        //     const context = this
        //     const args = arguments
        //     clearTimeout(inDebounce)
        //     inDebounce = setTimeout(() => func.apply(context, args), delay)
        // }
    },



    // noDevTools: noDevTools,

    /**
     * 
     * @param {*} content 
     * @param {*} tokens 
     */
    strategyParser(content, tokens) {
        if(!content || !tokens) {
            return content;
        }


        /**
         * replace the tokens for which values are defined
         */
        for (let i in tokens) {
            let re = new RegExp("\\[" + i + "\\]", "g");
            let value = tokens[i] && tokens[i].length > 0? tokens[i] : i;
            let replaceWith = '<span class="token assigned" data-token="'+ i +'">'+ value +'</span>';
            content = content.replace(re, replaceWith);
        }

        /**
         * make remanining tokens clickable
         */
        let unassigned = content.match(/\[[^\s]*\.[^\s\]]+\]/g);

        
        !!unassigned && unassigned.forEach((token)=>{
            // token
            let tokenRegExp = token
                .replace('[', '\\[')
                .replace(']', '\\]')
                .replace(/\./, '\\.');

            let tokenName = token.replace(/(\[|\])/g, '');
            let re = new RegExp(tokenRegExp, "g");
            content = content.replace(re, '<span class="token unassigned" data-token="'+ tokenName +'">' + tokenName +'</span>');
        });


        return content;
    },


    /**
     * 
     * @param {*} source 
     * @param {*} callback 
     * 
     * 
     * @example
     * 
     * var el = document.getElementById('editor');
     * 
     * copyToClipboard(el, function(selection) {
     *      alert('content was copied to clipboard');
     *      selection.removeAllRanges();
     * });
     * 
     */
    copyToClipboard(source /** HTML element */, callback) {
        var range = document.createRange();
            range.selectNodeContents(source);

        var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

        document.execCommand("copy");

        typeof callback == 'function' && callback.apply(null, [sel]);
    },

    copyTextToClipboard(text /** HTML element */, callback) {
        let elem = document.createElement('textarea');
            elem.value = text;
        document.body.appendChild(elem);
        elem.select();

        document.execCommand('copy');
        document.body.removeChild(elem);

        typeof callback == 'function' && callback.apply(null);
    },

    validateEmail(email) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        .test(email);
    },

    bouncer, 


    Dispatcher, 

    DispatcherEvent, 

    Observable, 

    observeDOM
}