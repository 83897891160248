import WP from '@/plugins/appcropolis.expose.js';

const baseURL = /localhost:/.test(window.location.host) ? 'http://localhost' : window.location.origin;
// const wp = new WP(baseURL + '/public');
const wp = new WP(baseURL);
//   wp.WP_URL = baseURL;
wp.WP_ADMIN_AJAX = 'appc';
wp.rev = '2021.08.13.14.38';

/** Global */
wp.expose('version');
wp.expose('signOn');
wp.expose('signOut');
wp.expose('loginAs');
wp.expose('getAccessGroup');
wp.expose('grantAccessToUser');
wp.expose('revokeAccessToUser');

/** Opportunities */
wp.expose('createOpportunity');
wp.expose('createOpportunityFromLibrary');
wp.expose('getOpportunities');
wp.expose('getOpportunity');
wp.expose('getOpportunityData');
wp.expose('updateOpportunity');
wp.expose('updateOpportunityForm');
wp.expose('updateOpportunityFormField');
wp.expose('deleteOpportunity');
wp.expose('trashOpportunity');
wp.expose('renameOpportunity');
wp.expose('duplicateOpportunity');
wp.expose('createOpportunityTemplate');
wp.expose('downloadOpportunity');

/** Opportunity Templates */
wp.expose('getOpportunityTemplates');
wp.expose('getOpportunityTemplate');
wp.expose('getOpportunityTemplatesFromCache');

/** Strategy Templates */
wp.expose('getStrategyTemplates');
wp.expose('getStrategyTemplatesKind');

/** Strategies */
wp.expose('createStrategyFrom');
wp.expose('duplicateStrategy');
wp.expose('getStrategies');
wp.expose('getStrategyData');
wp.expose('getStrategiesData');
wp.expose('getStrategy');
wp.expose('getStrategyPageHTML');
wp.expose('deleteStrategy');
wp.expose('updateStrategy');
wp.expose('updateStrategyData');
wp.expose('updatePageStrategy');
wp.expose('createStrategyForOpportunity');
wp.expose('createAIStrategyForOpportunity');
wp.expose('createTemplateFromStrategy');
wp.expose('trashStrategy');
wp.expose('deleteStrategiesByOpportunityId');
wp.expose('unlinkStrategiesFromOpportunityId');

/** Companies */
wp.expose('createCompany');
wp.expose('deleteCompany');
wp.expose('updateCompany');
wp.expose('getCompany');
wp.expose('getCompanies');
wp.expose('getUserCompany');
wp.expose('updateUserCompany');


/** reports */


wp.expose('getIndicators');
wp.expose('generateSWOT');
wp.expose('generateSWOTObject');


/** admin */

wp.expose('getUsers');



/** App */

wp.expose('createApp');
wp.expose('getApp');
wp.expose('getApps');
wp.expose('updateApp');
wp.expose('deleteApp');
wp.expose('getWorkbooksInApp');
wp.expose('getAppsByWorkbookId');




/** Workbook */

wp.expose('createWorkbook');
wp.expose('getWorkbook');
wp.expose('getWorkbooks');
wp.expose('updateWorkbook');
wp.expose('deleteWorkbook');
wp.expose('getWorkbooksByAppId');

/** Forms */

wp.expose('createForm');
wp.expose('getForm');
wp.expose('getForms');
wp.expose('getFormsByWorkbookId');
wp.expose('updateForm');
wp.expose('deleteForm');
wp.expose('addFieldToForm');
wp.expose('updateFormField');


/** Entries */

wp.expose('createEntry');
wp.expose('createEntries');
wp.expose('getEntry');
wp.expose('getEntries');
wp.expose('updateEntry');
wp.expose('deleteEntry');
wp.expose('getEntriesByFormId');




/** others */

wp.expose('getTableOfContents');
wp.expose('sum');

export default wp;