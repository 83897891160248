<template lang="">
    <div class="px-2">
        <div class="mb-3">
            <label for="strategy-title" class="form-label">Name</label>
            <input v-model="data.title" class="form-control text-input" id="strategy-title" type="text">
            <small class="fs-6 text-muted">What would you like to call this strategy?</small>
        </div>  


        <div class="mb-3">
            <label for="strategy-description" class="form-label">Description</label>
            <textarea v-model="data.excerpt" class="form-control text-input" id="strategy-description" style="resize: none;"></textarea>
            <small class="fs-6 text-muted">Add a brief description to this strategy</small>
        </div> 


        <div v-if="showAdvanced">
            <div class="input-group mb-3">
                <span class="input-group-text" style="width: 75px">Key</span>
                <input v-model="data.source_key" class="form-control text-input" id="source_key" type="text" style="border-left: 1px solid #ced4da !important;">
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" style="width: 75px">Project</span>
                <input v-model="data.source_project" class="form-control text-input" id="source_project" type="text" style="border-left: 1px solid #ced4da !important;">
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text" style="width: 75px">Page</span>
                <input v-model="data.source_page" class="form-control text-input" id="source_page" type="text" style="border-left: 1px solid #ced4da !important;">
            </div>

        </div>

        <div>
            <a class="btn btn-flat btn-sm ps-1 pe-2 py-0 rounded-pill text-primary"
                @click.prevent="showAdvanced = !showAdvanced"
                >SHOW 
                <span v-if="!showAdvanced">
                    <span class="material-icons pull-left " style="line-height: 20px;">
                    expand_more
                    </span>
                    MORE</span>
                <span v-else>
                    <span class="material-icons pull-left " style="line-height: 20px;">
                    expand_less
                    </span>
                    LESS</span>
                
            </a>
        </div>
    </div>
</template>
<script>
export default {

    name: 'EditStrategyPage',

    data() {
        return {
            showAdvanced: false,
            data: {
            }
        }
    },
    
    methods: {
        validate() {
            let isValid = false;
            if(this.data.title?.length > 0) 
            {
                isValid = true;
            }

            if(!isValid) {
                alert('Please fill out all required fields');
            }

            return isValid;
        }
    },

    async mounted() {
        await this.$store.dispatch('fetchOpportunities');
    },
}
</script>