const options = {

    GENERIC: {
        title: "Generic",
        name: "generic",
        type: "item",
        classes: '',
        icon: 'check_box_outline_blank',
        disabled: false,
    },
    DIVIDER: {
        title: "Divider",
        name: "divider",
        type: "divider",
        classes: '',
        icon: '',
        disabled: false,
    },
    OPEN: {
        title: "Open",
        name: "open",
        type: "item",
        classes: '',
        icon: 'open_in_new',
        disabled: false,
    },
    EDIT: {
        title: "Edit",
        name: "edit",
        type: "item",
        classes: '',
        icon: 'edit',
        disabled: false,
    },
    DETAILS: {
        title: "Details",
        name: "details",
        type: "item",
        classes: '',
        icon: 'info',
        disabled: false,
    },
    DELETE: {
        title: "Delete",
        name: "delete",
        type: "item",
        classes: 'text-danger',
        icon: 'delete',
        disabled: false,
    },
    DOWNLOAD: {
        title: "Download",
        name: "download",
        type: "item",
        classes: '',
        icon: 'file_download',
        disabled: false,
    },
    DUPLICATE: {
        title: "Duplicate",
        name: "duplicate",
        type: "item",
        classes: '',
        icon: 'content_copy',
        disabled: false,
    },
    MOVE_TO: {
        title: "Move To...",
        name: "moveTo",
        type: "item",
        classes: '',
        icon: 'vertical_distribute',
        disabled: false,
    },
    SHARE: {
        title: "Share",
        name: "share",
        type: "item",
        classes: '',
        icon: 'share',
        disabled: false,
    },
}


export default {
    options: options,

    dropdowns: {
        BASIC: [ options.OPEN, options.EDIT, options.DELETE ],
        COMPLETE: [ options.OPEN, options.EDIT, options.DETAILS, options.DELETE ]
    }, 

}