<template lang="">
    <div class="px-2">
        <div class="mb-3">
            <label for="data-title" class="form-label">Name</label>
            <input v-model="data.title" class="form-control text-input" id="data-title" type="text">
            <small class="fs-6 text-muted">What would you like to call this form?</small>
        </div>  


        <div class="mb-3">
            <label for="data-description" class="form-label">Description</label>
            <textarea v-model="data.excerpt" class="form-control text-input" id="data-description" style="resize: none;"></textarea>
            <small class="fs-6 text-muted">Add a brief description of your form.</small>
        </div> 

        <div class="mb-3">
            <label for="data-type" class="form-label">Type</label>
            <input v-model="data.type" class="form-control text-input" id="data-type" type="text">
            <small class="fs-6 text-muted">The type you wish to capture (e.g. "customer") —singular</small>
        </div>  

        <div class="mb-3">
            <label for="data-group" class="form-label">Group</label>
            <input v-model="data.group" class="form-control text-input" id="data-group" type="text">
            <small class="fs-6 text-muted">Use a single keyword to define a group.</small>
        </div> 

        <input v-model="data.workbook" class="form-control text-input" id="form-workbook" type="hidden"> 
    </div>
</template>
<script>
export default {

    name: 'ModalContentForm',

    data() {
        return {
            data: {
            }
        }
    },
    

    methods: {
        validate() {
            return true;
        }
    },
}
</script>