/**
 * Default plugin values
 * 
 * 
 */
export default {
    /**
     * This should be dynamically generated and must be unique across all plugins
     * regardless of the user (conside using a timestamp in the generation of the ID value)
     */
    id: '*',

    /**
     * The version of OutletJS for which the plugin was create. 
     */
    version: '1.0.0',

    /**
     * The name of the plugin. Consider using Java package name convention.
     */
    name: 'com.appcropolis.test',

    /**
     * The name of the plugin that will be displayed in the UI.
     */
    title: 'My Plugin',
    description: "This is a test plugin.",
    author: 'info@appcropolis.com',

    /**
     * The URL from where to load the plugin application.
     */
    canvasURL: 'https://someURL.com/test.html',

    /**
     * Expected to be a valid image (jpeg, png, gif) with the dimensions of
     * 1280 x 640 pixels (2:1 ratio).
     */
    posterURL: 'https://someURL.com/poster.jpg',

    /**
     * Addtional information such us license, support, contact, etc.
     */
    infoURL: 'https://someURL.com/legal.html',

    /**
     * Expected to be a valid image (jpeg, png, gif) with the dimensions of
     * 512 x 512 pixels.
     */
    iconURL: 'https://dummyimage.com/72x72/FF9D9D/FF9D9D',
    
    code: null,
    
    /**
     * This will indicate the UI container inside which the plugin will be rendered.
     * Valid options are:
     * 
     * - iframe         The plugin will be instanciated using an HTMLIFrameElement
     *                  that must be provided by the application. The plugin then
     *                  will appear to be part of the application.
     * 
     * - hidden         Although the plugin will be instanciated inside an iframe,
     *                  The application does not have to provide the HTMLIFrameElement. 
     *                  Outlet will be responsible for creating the iframe and append 
     *                  it to the DOM in a way that it is invisible.
     * 
     * - modal          Outlet will use its internal modal window (Bootstrap) to instaniate
     *                  the plugin inside an iframe located inside the modal body.
     * 
     * - left-panel     will use its internal panel (Bootstrap) to instanciate the plugin
     *                  on the left side of the screen.
     * 
     * - right-panel    will use its internal panel (Bootstrap) to instanciate the plugin
     *                  on the right side of the screen.
     * 
     * - window         The plugin will be intantiated inside a new browser popp up window.
     * 
     * - contextmenu    The plugin will be intantiated inside context menu UI element
     */
    ui: 'modal',/* iframe, fullscreen, window, hidden, modal, contextmenu */

    /**
     * A UI identifier. For example, if the plugin UI is a "iframe", the target should be a 
     * DOM query selector address (e.g. ".content #iframe"). If the UI is set to "window", then
     * the target value will be used to assign a name to the popup window (e.g. myplugin)
     */
    target: '#myplugin',


    /**
     * Whether or not the plugin is allow to read data that may be passed at initialization
     * time and overwrite such data. Valid values: 'read', 'write'
     * 
     * NOTE: standalone, untrusted plugins should not have any permissions.
     */
    permissions: 'write',
    
    /**
     * This will determine whether or not the plugin can be instatiated. Valid statuses are:
     * 
     * - ready          The plugin has been installed but it has not been activated.
     * - active         The plugin can be instantiated
     * - inactive       The application has access to the plugin data object and it won't   
     *                  Instancite the plugin. Any data stored in the server is still available
     * - reset          The application may choose to set the plugin to its default state.
     *                  When this is done, the plugin stored data is remove. The "reset" and the
     *                  "ready" states are virtually the same. "reset" will simply let the
     *                  application know that the plugin was reset.
     * - error          If during the plugin initialization process an error is detected,
     *                  the state will be set to "error".
     */
    status: 'active',

    /**
     * The application may choose to protect a plugin to prevent the user from deleting
     * it. The "protected" flag will help the application identify which plugin should 
     * not offer a UI option to remove plugin.
     */
    protected: false,


    /**
     * Additional configuration settings
     */
    options: {
        /** The color of the modal container (e.g. #404fff) */
        color: '#404fff',

        /** 
         * The theme can be 'dark' or 'light'. If the "color" property is set to a dark-like
         * color, then theme should be set to "light". For light-like "color", "light" should
         * be used instead.
         */
        theme: 'light',

        /**
         * Whether or not to allow the plugin to invoke the "bridge.fullscreen" method.
         */
        allowFullscreen: false,

        /**
         * Whether or not to allow the plugin to invoke the "bridge.popout" method.
         */
        allowPopout: false,

        /**
         * Whether or not the plugin UI (modal, left-panel, right-panel) can be dismissed
         * when the user clicks outside of the UI container.
         */
        dismissable: false, 

        /**
         * Can the plugin be invoked as a standalone. In some scenarios the plugin will
         * need initialization data that is provided by the application. If the plugin
         * can function without initialization data, the standalone must be true.
         */
        standalone: true,
    }
};