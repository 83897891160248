<template lang="">
    <div>
        <Register
            ref="register"
            :callback="register"
        ></Register>

        <Update
            ref="update"
            :callback="update"
        ></Update>

        <Select
            ref="select"
            :collection="plugins"
        ></Select>

        <Plugin
            ref="plugin"
        ></Plugin>

        <Notify
            ref="notifier"
        ></Notify>
    </div>
</template>
<script>

import outlet from "./outlet.js";
import Bridge from "./bridge.js";
import Register from "./modal/Register";
import Update from "./modal/Update";
import Select from "./modal/Select";
import Plugin from "./modal/Plugin";
import Notify from "./modal/Notify";

/** events */
var $events = window.$({});
    $events.READY = 'ready';
    $events.PLUGINS_LOADED = 'pluginsloaded';
    $events.PLUGIN_OPENED = 'pluginopened';
    $events.PLUGIN_CLOSED = 'pluginclosed';
    $events.PLUGIN_LOADED = 'pluginloaded';
    $events.PLUGIN_UPDATED = 'pluginupdated';
    $events.PLUGIN_UNREGISTERED = 'pluginunregistered';



export default {
    components: {
        Register,
        Update,
		Select,
		Plugin,
        Notify
    },

    data() {
        return {
            plugins: [], 
            version: outlet.version,
            name: outlet.name,
            product: outlet.product,
            which: '',
            lastOpened: null, 
            marketplaceURL: 'https://outletjs.com/marketplace/'
        };
    },

    methods: {


		/**
		 * 
		 * @param {obect} notice 
		 * @param {obect} notice.type valid values: alert, confirm
		 * @param {obect} notice.title
		 * @param {obect} notice.value Default value to show in prompt mode
		 * @param {obect} notice.message
		 * @param {function} notice.callback
		 */
		notify(notice, callback) {

			if( typeof notice == 'string') {
				notice = {
					type: 'message',
					message: notice, 
					callback: callback || function(){}
				}
			}
			/** TODO: find a better way to point to "notifier" reference */
			var notifier = this.$refs.notifier;
				notifier.type = notice.type;
				notifier.title = notice?.title;
				notifier.value = notice?.value;
				notifier.callback = notice.callback;
				notifier.message = notice.message;

			notifier.show();
		},

        /** 
         * Open modal (bootstrap)
         * 
         * @param {String} witch Valid values: register, plugin, select
         */
        show(whitch, data) {
            this.whitch = whitch;
            if(whitch == 'plugin') {
                this.open(data);
            } else {
                this.$refs[whitch].show();
            }
        },


        hide(whitch, callback) {
            whitch = whitch || 'plugin';

            // alert('2: Outlet.which: '+ whitch)

            this.$refs[whitch].hide(callback);
            setTimeout(()=>{
                this.whitch = whitch;
            }, 500)
        },

        async register(data) {
            if (!data) {
                this.show('register');
                return;
            }

            let plugins = await outlet.register(data);
            this.plugins = plugins;

            this.hide('register', ()=>{
                // TODO: fire PLUGIN_REGISTERED
                this.$events.trigger(this.$events.PLUGINS_LOADED, [this.plugins]);
            });
        },

        async unregister() {
            this.select(async (plugin)=>{
                this.$root.$outlet.notify({
                    type: 'confirm',
                    message: `Are you sure you want to delete the extension <b>${plugin.title}</b>?`,
                    callback: async (okay) =>{
                        if(okay) {
                            let plugins = await outlet.unregister(plugin);
                            this.plugins = plugins;
                            this.hide('register', ()=>{
                                this.$events.trigger(this.$events.PLUGIN_UNREGISTERED, [this.plugins]);
                            });
                        }
                    }
                });
            });
        },

        /**
         * 
         * @param {Object} data Plugin data (single plugin)
         * 
         */
        async update(data) {
            if (!data) {
                this.select(async (plugin)=>{
                    this.$refs.update.open({...plugin})
                });
                return;
            }

            let plugins = await outlet.update(data);
            this.plugins = plugins;

            this.hide('update', ()=>{
                this.$events.trigger(this.$events.PLUGIN_UPDATED, [this.plugins]);
            });
        },


        sendTo(data, callback) {
			var scope = this;
			this.select(async (plugin)=>{
				let bridge = await this.open(plugin);

				bridge.send('data', [data]);
                
				bridge.expose('update', function(reponse) {
					scope.$refs.plugin.hide(()=>{
						typeof callback == 'function' && callback.apply(null, [reponse])
					});
					return reponse;
				});
			});
		},

        /**
         * Opens a plugin selector modal. When a plugin is selected, the data
         * is passed to the callback function. If a plugin is not selected, the
         * callback function is not called.
         */
		select(callback) {
            this.$refs.select.callback = callback;
			this.$refs.select.show();
		},


		open(plugin) {
            if (!plugin) {
                this.select(async (plugin)=>{
                    this.open(plugin);
                });
                return;
            }
            

			return new Promise((resolve)=>{
                this.$refs.plugin.data = plugin;
                
				let url = plugin.canvasURL;
                
				let iframe = this.$refs.plugin.iframe(url, ()=>{
					let bridge = Bridge.create(iframe, { autoresize: true });
                    /**
                     * TODO: set the connection id automatically (no need to call setup)
                     */
                    bridge.setup();
                    
                    this.$refs.plugin.bridge(bridge);
					this.$refs.plugin.show();
                    this.$events.trigger(this.$events.PLUGIN_OPENED, [bridge, iframe, plugin]);

                    this.lastOpened = {...plugin};

					resolve(bridge);
				});
			});
		}, 


        last() {
            this.open(this.lastOpened);
        }, 

        /**
         * 
         */
        marketplace(callback) {
            let url = this.marketplaceURL;
			return new Promise((resolve)=>{
                (async function() {
                    let bridge = await outlet.marketplace(url);

                    window.marketplace = bridge;

                    if(typeof callback == 'function') {
                        bridge.expose('data', function(extension) {
                            callback.apply(null, [extension]);
                            return extension
                        });
                    }
                    resolve(bridge);
                })();
            });
        },

    },

    created() {
        // this.$root.$outlet = this;
        this.$events = $events;
    },

    mounted() {
        outlet.init().then(() => {
            this.plugins = outlet.plugins();
            this.$events.trigger(this.$events.PLUGINS_LOADED, [this.plugins]);
        });

        this.$events.bind(this.$events.REGISTER_PLUGIN, (e, data) => {
            this.register(data);
        });

        window.outlet = this;
    },
};
</script>
<style lang="">
    
</style>