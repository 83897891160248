<template>
    <footer class="app-footer py-3 border-top text-muted">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <span
                            >&copy; {{ year }} Appcropolis
                            LLC</span
                        >
                        <span class="px-1">&middot;</span>
                        <a href="#" @click.prevent="">Terms</a>
                        <span class="px-1">&middot;</span>
                        <a href="#" @click.prevent="" v-show="$root.isUserLogged">Logout</a>
                        <router-link href="#"  to="/login" v-show="!token" class="pointer-events-none text-decoration-none text-muted">Login</router-link>
                        <a href="#" @click.prevent="logout()" v-show="token">Logout</a> 
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>


export default {
    name: 'FooterMain',

    data() {
        return {
            year: (new Date().getFullYear())
        }
    },

    computed: {
        token() {
            return this.$store.getters.token;
        }
    }, 

    methods: {
        logout() {
            this.$root.logoutIntent();
        }
    }

}

</script>