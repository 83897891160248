<template lang="">
    <div class="border-0 offcanvas offcanvas-end panel-help shadow" tabindex="-1" id="panel-help">
        <div class="offcanvas-header p-0">
            <ul class="nav nav-tabs w-100">
                <li class="nav-item">
                    <a class="nav-link px-4.5 fw-500 tab-questions active" data-bs-toggle="tab" data-bs-target="#questions">Knowledge Base</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link px-4.5 fw-500 tab-notifications" data-bs-toggle="tab" data-bs-target="#notifications">Notifications</a>
                </li>
                <li class="nav-item d-none">
                    <a class="nav-link px-4.5 fw-500 tab-answer" data-bs-toggle="tab" data-bs-target="#answer">Answer</a>
                </li>
            </ul>
            <button type="button" class="btn-close end-0 pe-5 position-absolute text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body p-4 px-4.5">



            <div class="tab-content">
                <div class="tab-pane active" id="questions">
                    <!-- Search box -->
                    <div class="mb-4 mt-2">
                        <form @submit.prevent=""
                            class="position-relative my-2 my-lg-0 me-5 w-100 "
                        >
                            <div class="d-flex flex-row">

                                <input
                                    ref="term"
                                    @keydown.enter.prevent=""
                                    v-model="term"
                                    class="form-control mr-sm-2 border shadow-sm d-block --btn-pill position-relative text-term"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                />

                                <button
                                    class="btn btn-outline-primary end-0 my-2 my-sm-0 position-absolute px-4 rounded-pill text-muted"
                                    type="submit"
                                    v-if="false"
                                    @click.prevent="term = ''"
                                >
                                    Search
                                </button>


                                <button
                                    class="bg-transparent btn end-0 my-2 my-sm-0 position-absolute rounded-circle text-muted top-0"
                                    type="submit"
                                    @click.prevent="term = ''"
                                >
                                    <i class="fa fa-search me-1 mt-2" v-if="term.length === 0"></i>
                                    <i class="fa fa-times  me-1 mt-2" v-if="term.length > 0"></i>
                                </button>

                            </div>
                        </form>
                    </div>

                    <!-- questions -->
                    <div>
                        <div v-for="(section, index) in data" :key="index" class="pb-2">
                            <h3 class="font-size-22 py-2 lh-base">{{section.title}}</h3>
                            <ul class="list-unstyled">
                                <li v-for="(question, q) in section.content" :key="q" class="lh-lg d-flex">
                                    <span class="material-icons-outlined pull-left me-2 text-primary" style="transform: translateY(3px)">
                                    article
                                    </span>
                                    <a href="#" @click.prevent="fetch(question)" class="text-primary text-decoration-none hover:text-undeline">{{ question.title }}</a>
                                </li>
                            </ul>
                        </div>

                        <div v-if="data.length === 0 && term.length > 0">
                            <div class="alert alert-warning" role="alert">
                                No results found for "<b>{{term}}</b>".
                            </div>
                        </div>
                    </div>
                </div>


                <!-- answer -->
                <div class="tab-pane panel-animation" id="answer">
                    <div class="d-flex flex-row">
                        <div>
                            <a @click.prevent="showTab('.tab-questions')" 
                                style="margin-left: -10px; margin-top: 4px"
                                class="btn btn-primary btn-sm font-size-16 me-2 px-1 me-3 rounded-pill">
                                <span class="material-icons pull-left">
                                arrow_back
                                </span>
                            </a>
                        </div>
                        <div class="">
                            <h2>{{answer?.title}}</h2>
                            <div v-html="answer?.content"></div>
                        </div>
                    </div>
                </div>


                <!-- notifications -->
                <div class="tab-pane" id="notifications">
                    <div>
                        <div class="alert alert-warning text-center" role="alert">
                            You have no notifications at this time.
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            term: '',
            answer: null,
        }
    }, 

    computed: {
        data() {
            let term = this.term.trim().replace(/\s+/, ' ');
            let sections = JSON.parse(JSON.stringify( this.$store.getters.tableOfContents ));

            if(term.length) {
                let words = term.replace(/\s*/, '').toLowerCase().split(" ");
                let regexpString = '(' + words.join('|') + ')';
                let regexp = RegExp( regexpString, 'g' );

                console.log(regexpString);
                    
                sections.forEach((section) => {
                    let contentFound = []
                    let content = section.content;
                    content.forEach((question) => {
                        let searchable = (question.title + ' ' + question.content).toLowerCase();
                        if(regexp.test(searchable)) {
                            contentFound.push(question);
                        }
                    });
                    section.content = contentFound;
                });

                sections = sections.filter((section)=>{
                    return section.content.length > 0;
                });

            }

            return sections;
        }
    }, 


    methods: {

        showTab(selector) {
        console.log('selector: ' + selector)
            var tabEl = this.$el.querySelector(selector);
            var tab = new window.bootstrap.Tab(tabEl);
                tab.show();
        },


        fetch(question) {
            question;
            this.answer = null;
            this.showTab('.tab-answer');
            this.answer = {
                title: question.title,
                content: question.content
            }
        }, 

        focusOn(selector, delay) {
            delay = delay || 100;
            setTimeout(()=>{
                this.$el.querySelector(selector).focus();
            }, delay);
        }
    },

    mounted() {
        window.help = this;
        let panel = this.$el;
        panel.addEventListener('show.bs.offcanvas', ()=> {
            this.$store.dispatch('fetchTableOfContents');
        });

    },

    
}
</script>
<style scope>
.panel-help {
    width: 500px;  
    transition: transform .2s ease-in-out !important;
} 

/* .panel-animation {
    transition: all 0.5s;
    transition-delay: 0s;
    transform: translateY(100px);
}
.panel-animation.active {
    transform: translateY(0);
} */


.text-term {
    padding: 0.75rem 1rem;
}

.hover\:text-undeline:hover {
        text-decoration: underline!important;
}
</style>