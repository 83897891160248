/*

pluginObject.activate;
pluginObject.deactivate;
pluginObject.remove;
pluginObject.details;
pluginObject.data;
pluginObject.isActive;



const outlet = {

    events: events,

    plugins: [],

    async init() {
        let url = SERVICES.GET_PLUGINS_LOCAL;
        this.plugins = await this.getPlugins(url);
        return this.plugins;
    },

    validate(pluginObject) {
        // verify data integrity
        // verify permissions
        return pluginObject;
    },

    install(pluginObject) {
        // submit request to the server
        // update the list of plugin
    },

    use(pluginObject) {

    },


    // browse() {

    // },


    load(pluginURL, context) {
        // plugin is not in the registry (database) and it will only be loaded on the fly
        // read only
        // id is assign dynamically
        // valid context is form
        // ui will be set to window, or modal
    },



    mount(name, slector, callback) {
        // identify a canvas
        // create bridge proxy
        // mount
        // return bridge proxy
    },

    unmount(name) {

    },

    destroy(name) {

    },

    activate() {

    },

    deactivate() {

    }
}
*/


import Local from './local';
import Bridge from './bridge';
import defaultPluginObject from './extension.js';
const local = Local.create('local-data');

// const store = {};


/**
 * 
 */
// (function (root, factory) {
//     // if (typeof define === 'function' && define.amd) {
//     //     define([], factory(root));

//     // } else 
    
//     if (typeof exports === 'object') {
//         module.exports = factory(root);

//     } else {
//         /**
//          * Expose the name of the main objetc (e.g. "outlet")
//          */
//         root.outlet = factory(root);
//     }
// })(typeof global !== 'undefined' ? global : this.window || this.global, 


var outlet = (function (root) {
    'use strict';

    /** global variables */
    var window = root;
    // var $ = window.$;
    var supports = 'querySelector' in document && 'addEventListener' in window;
    var version = '2021.42';
    var plugins = [];


    /** services */
    var SERVICES = {
        GET_PLUGINS_MARKETPLACE: '/services/tbd',
        GET_PLUGINS_LOCAL: '/services/getPlugins',
        GET_PLUGIN_INFO: '/services/getPlugin',
        REGISTER_PLUGIN: '/services/registerPlugin',
    };


    /** +--------------------------+ */
    /** |       CONSTRUCTOR        | */
    /** +--------------------------+ */

    /** 
     * constructor 
     * 
     */
    var outlet = function () {
        /**
         * Public properties goes here
         */
    };
    

    /** protectect properties */
    var protectedProps = {
        name: 'OutletJS',
        version: version,
        product: `OutletJS v.${version}; © 2021 Appcropolis, LLC`
    };

    for (let i in protectedProps) {
        Object.defineProperty(outlet.prototype, i, {
            value: protectedProps[i],
            writable: false
        });
    }



    /** +--------------------------+ */
    /** |      HELPER METHODS      | */
    /** +--------------------------+ */


    var deepFreeze = obj => {
        Object.keys(obj).forEach(prop => {
            if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop]);
        });
        return Object.freeze(obj);
    };
    deepFreeze

    var guid = () => {
        return Number(new Date()) + '' + ('' + Math.random()).substr(-6);
    };


    /**
     * Allow chanability. Executes a function and returns a context
     * 
     * @param {Function} func
     * @param {Object} context
     */
     var chainable = (function (func, args, context) {
        if (!supports) return;

        context = func.apply(context, args) || context;

        return context;
    }).bind(outlet.prototype);

    /** +--------------------------+ */
    /** |     PRIVATE METHODS      | */
    /** +--------------------------+ */


    // var version = (function () {
    //     if (!supports) return;
    //     return currentVersion;
    // }).bind(outlet.prototype);






    var getPluginsLocal = (function () {

        SERVICES


        return new Promise((resolve, reject) => {
            reject
            setTimeout(() => {
                // let extension = {...defaultPluginObject, ...{id: guid()} }
                let data = local.getAll();

                if(!data?.plugins) {
                    local.set('plugins', []);
                }
                
                data = local.getAll();

                resolve(data.plugins);
                
            }, 200);
            // $.ajax({
            //     url: SERVICES.GET_PLUGINS_LOCAL, 
            //     type: 'post',
            //     success(response, status, xhr) {
            //         if(response?.success) {
            //             resolve(response?.data);
            //         } else {
            //             reject(reject);
            //         }
            //     }, 
            //     error() {
            //         reject(arguments);
            //     }
            // });
        })
    }).bind(outlet.prototype);



    var update = (function (data) {
        return new Promise((resolve, reject) => {
            data = validate(data);
            if(!data) {
                reject(); return;
            }
            
            setTimeout(() => {
                let localData = local.getAll();

                for(let i=0; i<localData.plugins.length; i++) {
                    if(data.id == localData.plugins[i].id) {
                        localData.plugins[i] = data;
                        break;
                    }
                }

                local.setAll(localData);
                plugins = localData.plugins;
                resolve(plugins);
            }, 250 /** fake delay */);
        })
    }).bind(outlet.prototype);



    var register = (function (data) {
        return new Promise((resolve, reject) => {
            data = validate(data);
            if(!data) {
                reject(); return;
            }
            
            setTimeout(() => {
                let extension = {...defaultPluginObject, ...data, ...{id: guid()} }
                /** call service */
                let localData = local.getAll();
                    localData.plugins.push(extension);
                local.setAll(localData);
                plugins = localData.plugins;
                resolve(plugins);
            }, 250 /** fake delay */);
        })
    }).bind(outlet.prototype);

    

    var unregister = (function (data) {
        return new Promise((resolve, reject) => {
            data = validate(data);
            if(!data) {
                reject(); return;
            }
            
            setTimeout(() => {
                /** call service */
                let localData = local.getAll();
                    localData.plugins = localData.plugins.filter((plugin)=>{
                        return plugin.id != data.id
                    });

                local.setAll(localData);

                plugins = localData.plugins;

                resolve(plugins);
            }, 250 /** fake delay */);
        })
    }).bind(outlet.prototype);





    var validate = (function (data) {
        if (!data?.title || data.title.trim().length === 0) {
            return false;
        }

        data.title = data.title.trim();
        data.name = data?.name || data.title
            .trim()
            .replace(/\s/, "-")
            .replace(/-+/, "-")
            .toLowerCase();

        return data;

    }).bind(outlet.prototype);





    /**
     * Open and connect popup window. Since the window may contain a different domain,
     * It is not possible to listen to the window onload event. A pull mechanism is
     * implement to poll whether or not the connection has been stablished. Every 1/2
     * of a second, for a total of 10 seconds an interval will try to ping the open window. 
     * 
     * @param {Object} options
     * @param {String} options.url
     * @param {String} options.name
     * 
     * 
     */
    var popup = (function (options) {
        let timer, beacon, checkEvery = 500, maxAttempts = 20, currentAttempt = 1, bridge, win;

        return new Promise((resolve, reject)=> {
            // (1) Open a new browser window
            win = window.open(options.url, options.name);

            // (2) Attempt to setup connection every "500" milliseconds
            timer = setInterval(async ()=>{
                // (3) Check attemp, time out and reject promise if needed
                if(currentAttempt > maxAttempts) {
                    clearInterval(timer);
                    if(!beacon) {
                        reject();
                    }
                } else {
                    // console.log('setInterval', currentAttempt);
                    currentAttempt += 1;
                }
                
                // (4) Attempt connecting to the bridge
                bridge.setup();

                // (5) Probe connection (get a beacon). If the connection cannot be stablished
                // this block of code will not go beyond "beacon = await..."
                setTimeout(async() => {
                    beacon = await bridge.send('bridge.ping');
                    // console.log('setTimeout', bridge);
                    // console.log('%cbridge.ping DONE', 'color: #ffffff; background: green;', beacon);
                    // (6) if the beacon is defined the connection was stablished, then stop timer and resolve promise with bridge.
                    clearInterval(timer);
                    resolve(bridge);
                }, 0);

            }, checkEvery);


            bridge = Bridge.create(win);
            /**
             * TODO: set the connection id automatically (no need to call setup)
             */

            (window.location.hostname == 'localhost') && (window.popup = bridge);
        });

    }).bind(outlet.prototype);




    // var marketplaceConnect = (function (popup) {

        // bridge.expose('message', function(html) {
        //     $('.editor').html(html);
        //     return html;
        // });


        // $('.editor').on('input', ()=> {
        //     let html = $('.editor').html();

        //     console.log(html);

        //     bridge.send('update', [html]);
        // });
    
        // setTimeout(()=>{
        //     bridge.send('update', [ $('.editor').html() ]);
        // }, 0);

        /**
         * bridge.$events.bind('message', function(e, data) {
         *     console.log(data)
         * }); 
         */

    // }).bind(outlet.prototype);



    /** +--------------------------+ */
    /** |     PUBLIC METHODS       | */
    /** +--------------------------+ */

    // outlet.prototype.version = function () {
    //     return version;
    // };

    outlet.prototype.plugins = function () {
        // let pluginsFrozen = deepFreeze({...plugins})
        // return pluginsFrozen;
        return plugins;
    };




    outlet.prototype.init = function () {
        let args = arguments;

        return new Promise((resolve) => {
            chainable(async () => {
                plugins = await getPluginsLocal();
                resolve(this);
            }, args, this);
        });
    };




    outlet.prototype.register = function (data) {
        data = data || {};
        data.id = guid()
        
        let args = arguments;

        return new Promise((resolve) => {
            chainable(async () => {
                let plugins = await register.apply(null, [data]);
                resolve(plugins);
            }, args, this);
        });
    };


    outlet.prototype.unregister = function (plugin) {
        let args = arguments;

        return new Promise((resolve) => {
            chainable(async () => {
                let plugins = await unregister.apply(null, [plugin]);
                resolve(plugins);
            }, args, this);
        });
    };



    outlet.prototype.update = function (data) {
        let args = arguments;

        return new Promise((resolve) => {
            chainable(async () => {
                let plugins = await update.apply(null, [data]);
                resolve(plugins);
            }, args, this);
        });
    };


    /**
     * 
     * Opens a popup window with the marketplace application (extensions)
     * and allow selecting an extension to be added.
     * 
     * @param {String} url The URL of the marketplace site
     * 
     * @return {Promise} This will resolve passing the plugin configuration as a parameter.
     * 
     *  
     */
    outlet.prototype.marketplace = function (url) {
        // let args = arguments;

        return new Promise((resolve) => {
                            // let seed = ('' + Math.random()).replace(/(0|\.)/g, '').substr(0, 6)
                            // let plugin = {...defaultPluginObject, ...{
                            //     name: 'extension-' + seed,
                            //     id: seed,
                            //     title: 'My Extension ' + seed,
                            //     description: 'This is extension ' + seed,
                            //     ui: 'modal'
                            // }}
                            // resolve(plugin);


            (async function() {
                let data = {};
                let options = {...{
                    url: url,
                    name: 'marketplace',
                }, ...data}

                let bridge = await popup(options);

                // alert('outlet.prototype.marketplace > bridge');

                resolve(bridge);


            })();

            // chainable(async () => {
            //     let plugins = await update.apply(null, [data]);
            //     resolve(plugins);
            // }, args, this);
        });
    };

    
    return new outlet();
})(window);


export default outlet
// outlet.init().then(o => console.log(o.plugins()));
// outlet.product;