
export default {

    props: {
        /**
         * The UI element label
         */
        label: {
            type: String,
            default: 'Button'
        },
        
        /**
         * A unique identifier
         */
        uid: [String, Number],

        /**
         * Whether or not to show a preloader after a click event
         */
        loader: { 
            type: Boolean,
            default: false
        },

        /**
         * The function that will be called when clicked
         */
        onClick: { type: Function },

         /**
          * The function that will be called when clicked
          */
        onOption: { type: Function },

        /**
         * CSS class names to add to the root HTML element.
         */
        cssClasses: {
            type: String, 
            default: null
        },

        /**
         * Name of the Google Material Font Icon. 
         */
        icon: {
            type: String, 
            default: ''
        }, 

        /**
         * Name of the Google Material Font Icon. 
         */
        iconPosition: {
            type: String, 
            default: 'left'
        }, 

        /**
         * An array of items to be render if the component expects it
         */
        collection: {
            type: Array, 
            default: []
        }, 

        /**
         * The parent component
         */
        context: {
            type: Object
        }, 


    }, 


    data: {
        TIMEOUNT: 5000,
        isLoading: false, 
        loaderTimeout: null
    },


    computed: {
        classes() {
            let output = this.cssClasses? this.cssClasses : '';
                output += this.isLoading? ' loading' : '';
            return output;
        }
    },


    methods: {
        action(data) {
            if(this.onClick == null) {
                console.warn('onClick has not been defined', this.context)
                return;
            }

            data = data || this.uid || this.label;

            if(this.onClick && this.isLoading === false) {
                this.loading(true);
                this.onClick?.(data, this.context);
            }
        }, 


        option(data) {   
            if(this.onOption == null) {
                return;
            }

            if(this.onOption) {
                this.onOption?.(data, this.context);
            }
        }, 

        loading(flag) {
            if(this.loader === false) {
                return;
            }
            
            this.isLoading = flag;
            this.loaderTimeout = setTimeout(() => {
                if(this.isLoading === true) {
                    this.done();
                }
            }, this.TIMEOUNT);
        },

        done() {
            clearTimeout(this.loaderTimeout);
            this.loaderTimeout = null;
            this.loading(false);
        }
    }

}