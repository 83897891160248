


export default {

		/**
		 * This method will overwrite different functions depending on user's capabilities.
		 * 
		 * IMPORTANT: this will be invoked from the "$capabilities" pluing.
		 */
		apply(app) {
			/**
			 * opportunities.create
			 */
             app.$capabilities.overwriteIf(app.$capabilities.userCan('opportunities.create'), false, app, 'createOpportunityIntent', function() {
				let remaining = app.$capabilities.quota('opportunities');
				let message = `You have "${remaining}" opportunities remaining in your account. Would you like to add more opportunities?`;
				app.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							app.gotoPlans();
						}
					}
				});
			});


			/**
			 * strategies.create
			 */
             app.$capabilities.overwriteIf(app.$capabilities.userCan('strategies.create'), false, app, 'createStrategyIntent', function() {
				let remaining = app.$capabilities.quota('strategies');
				let message = `You have "${remaining}" strategies remaining in your account. Would you like to add more strategies?`;
				app.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							app.gotoPlans();
						}
					}
				});
			});


			/**
			 * invitations.create
			 */
             app.$capabilities.overwriteIf(app.$capabilities.userCan('invitations.create'), false, app, 'grantAccessToUser', function() {
				let remaining = app.$capabilities.quota('invitations');
				let message = `You have "${remaining}" invitations remaining in your account. Would you like to add more invitations?`;
				app.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							app.gotoPlans();
						}
					}
				});
			});



			/**
			 * invitations.read
			 */
             app.$capabilities.overwriteIf(app.$capabilities.userCan('invitations.read'), false, app, 'loginAs', function() {
				let message = `Your account does not have access to invitations. Would you like to access invitations?`;
				app.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							app.gotoPlans();
						}
					}
				});
			});


			/**
			 * invitations.delete
			 */
             app.$capabilities.overwriteIf(app.$capabilities.userCan('invitations.delete'), false, app, 'revokeAccessToUser', function() {
				let message = `Your account is not allowed to delete invitations. Would you like to have access to this feature?`;
				app.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							app.gotoPlans();
						}
					}
				});
			});
			
		}
}