<template lang="">
    <div class="border-bottom border-top" :style="{ margin: '0 -32px -16px', height: '240px', 'overflow-y': 'scroll'}">
        <ul class="list-group list-group-flush">
            <li v-for="(item, index) in data?.items" :key="index" class="list-group-item px-4"
                :class="[item?.name == current?.selection?.name? 'active' : '']"
                >
                <a href="#" class="px-1 text-reset text-decoration-none" @click.prevent="select(item)">{{item?.title}}</a>
            </li>
        </ul>
    </div>
</template>
<script>

export default {

    name: 'ModalContentSelector',

    data() {
        return {
            data: {
                items: [],
            },
            current: {
                selection: null
            }
        }
    },
    

    methods: {
        validate() {
            return true;
        }, 

        select(item) {
            this.current.selection = item
        }
    },
}
</script>