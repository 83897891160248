<template lang="">
    <div>
        <p class="fs-5 pb-3">Choose a the opportunity file you wish to upload (must end in .appc)</p>
        <p class="d-flex w-100">
            <input class="text-file border btn ellipsis flex-fill fs-5 me-2 position-relative text-start"
                :class="[opportunity?.ID? 'text-dark' : 'text-muted disabled']"
                v-model="currentOpportunityTitle"
                />
            
            <button class="btn-upload btn btn-primary text-center onverflow-hidden position-relative flex-grow-0 flex-shrink-0">
                <input type="file" name="opportunity-file" class="position-absolute top-0 start-0 w-100 h-100">
                <span>Choose File</span>
            </button>
        </p>
    </div>
</template>
<script>
import common from './ModalContent.js';

export default {
    name: 'UploadOpportunity',

    data() {
        return {...common.data, ...{
            opportunity: null
        }};
    },

    computed: {...{
            currentOpportunityTitle: {
                get() {
                    let title = 'No file chosen';
                    if(this.opportunity?.post_title) {
                        title = this.opportunity.post_title;
                    }
                    return title;
                }, 
                set(newVal) {
                    console.log(newVal)
                    this.opportunity.post_title = newVal;
                }
            }
        }
    },

    methods: {
        ...{
            setup(context) {
                context
            },

            reset() {
                this.opportunity = null;
            },

            getFile(event) {
                const input = event.target
                if ('files' in input && input.files.length > 0) {
                    let contentTarget = document.querySelector('.text-file');
                    this.placeFileContent(contentTarget, input.files[0]);
                }
            },

            placeFileContent(target, file) {
                this.readFileContent(file).then(async(appc) => {
                    let data = await this.readAPPC(appc);
                    this.opportunity = data;
                }).catch(error => console.log(error));
            },

            readFileContent(file) {
                const reader = new FileReader()
                return new Promise((resolve, reject) => {
                    reader.onload = event => resolve(event.target.result)
                    reader.onerror = error => reject(error)
                    reader.readAsText(file)
                })
            },

            async readAPPC(appc) {
                let data = await this.$store.dispatch('appcToObject', appc);
                return data;
            },
        }
    }, 

    mounted() {
        console.log('>>> uploader');
        this.$el.querySelector('input[type="file"]').addEventListener('change', this.getFile);
    }
}
</script>


<style scoped>
.btn-upload {
    width: 140px;
    max-width: 140px;
}

.btn-upload input[type="file"] {
    opacity: 0;
    cursor: pointer;
}

textarea {

}

</style>