<template>
    <div class="dropdown d-inline">
        <ButtonCircle class="dropdown-toggle no-carret" data-bs-toggle="dropdown" 
            icon="more_horiz"
            ></ButtonCircle>

        <ul class="dropdown-menu shadow border-minimal dropdown-menu-end border-radius-8 px-0 py-2 mt-2">
            <li v-for="(item, index) in collection" :key="index"
                class=""
                >

                <hr v-if="item.type == 'divider'" class="bg-light dropdown-divider">

                <h6 v-if="item.type == 'header'" class="dropdown-header">{{item.title}}</h6>

                <a v-if="item.type == 'item'" href="#" class="dropdown-item px-3 transition-speed-5x hover:bg-light text-truncate"
                    :class="[item.disabled == true? item.classes + ' disabled' : item.classes]"
                    @click.prevent="action(item, context)">

                    <span v-if="item.icon && item.icon.length > 0" class="material-icons-outlined pull-left me-2">
                        {{item.icon}}
                    </span>
                    <span>{{item.title}}</span>
                </a>    
            </li>
        </ul>

    </div>
</template>

<script>

import common from './Common.js';


export default {
    name: 'OptionsDropdown', 

    props: { 
        ...common.props, 
        ...{
            collection:  Array, 
            onOpen: Function,
            onClose: Function,
        } 
    }, 

    data() {
        return { ...common.data }
    },

    computed: { ...common.computed }, 


    methods: { 
        ...common.methods },
    

    mounted() {
        let dropdown = this.$el;

        dropdown?.addEventListener('shown.bs.dropdown', ()=> {
            if(this.action !== null && this.onOpen) {
                this.onOpen(this)
            }
        });

        dropdown?.addEventListener('hidden.bs.dropdown', ()=> {
            if(this.action !== null && this.onClose) {
                this.onClose(this)
            }
        });
    }
}
</script>

<style lang="css" scoped>

.dropdown-menu {
    min-width: 200px;
    margin-top: 2px !important;
}
.dropdown-menu .dropdown-item-last:hover {
   background-color: transparent !important;
}

.dropdown-item.disabled {
    opacity: 0.5;
}
/* 
.hover\:btn-outline-primary:hover {
    color: #ffffff !important;
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
} */
</style>