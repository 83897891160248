<template lang="">
    <div class="d-flex flex-wrap ai-usecases ps-3 pt-3">
        <div class="card shadow-sm mb-4" v-for="(item, index) in usecases" :key="index"
            @click.prevent="select(item)"
            :class="[item.slug == current?.selection? 'active' : '']"
            >
            <div class="card-body rounded-5 p-2rem">
                <!-- <img :src="`https://storage.googleapis.com/rytr-me/image/type/` + item.image" class="rounded-circle mb-3" alt=""> -->
                <img :src="`/resources/images/usecases/` + item.image" class="rounded-circle mb-3" alt="">
                <h5 class="card-title text-black">{{ item.name }}</h5>
                <p class="card-text fs-6">{{ item.description }}</p>
            </div>
        </div>
    </div>
</template>
<script>

import usecases from "@/assets/data/ai-usecases.js";


export default {

    name: 'ModalContentAIStrategies',

    data() {
        return {
            current: {
                selection: null,
                item: null
            }
        }
    },

    computed: {
        usecases() {
            return usecases.filter( usecase => usecase.enabled )
        }
    },
    

    methods: {

        validate() {
            return true;
        }, 

        select(item) {
            this.current.item = item;
            this.current.selection = item.slug;
        }
    },
}
</script>

<style scoped>

.p-2rem {
    padding: 2rem!important;
}
.rounded-5 {
    border-radius: 1rem!important;
}

.ai-usecases {
    max-height: 650px;
    overflow: scroll;
}
.ai-usecases .card {
    width: 32%;
    margin-right: 1%;
    background-color: transparent;
    transition: all 0.15s;
}

.ai-usecases .card:hover {
    background-color: #fcfcfd;
    border-color: #aaa;
    cursor: pointer;
    box-shadow: 0 4px 8px #404fff2b !important;
}
.ai-usecases .card.active {
    pointer-events: none;
    background-color: #404fff;
    border-color: #404fff;
    color: #fff;
}


.ai-usecases .card img {
    width: 64px;
    padding: 1px;
}
</style>