<template>
    <div class="px-2">
        <form @submit.prevent="">
            <div class="form-group">
                <label for="">Enter value for 
                    <a href="#" tabindex="-1" class="bg-light px-2 rounded-pill text-primary text-decoration-none"
                        @click.prevent="gotoForm()">{{data.token}}</a></label>

                <div ref="input" class="form-control text-input my-3" contenteditable="">
                </div>
                <p class="text-start">
                    <a href="#" tabindex="-1"
                        class="btn btn-flat btn-pill btn-sm lh-1 py-0 py-1 text-dark me-2"
                        @click.prevent="gotoForm()">OPEN FORM</a>

                    <a href="#" tabindex="-1"
                        class="btn btn-flat btn-pill btn-sm lh-1 py-0 py-1 text-dark"
                        @click.prevent="unlink()">UNLINK</a>
                </p>
            </div>
        </form>
    </div>
</template>

<script>

// import FormGroupsByFields from '@/components/forms/FormGroupsByFields.js'

import DefaultOpportunity from '@/store/DefaultOpportunity.json';


export default {
    name: "ModalContentField",
    emits: ["unlink"],

    data() {
        return {
            data: {
                token: null,
                value: null
            }
        }
    },

    computed: {
        formData() {
            let path = null;

            if(!this.data.token || !this.data.token?.match(/\./)) {
                return path;
            }

            let formAndField = this.data.token.split('.');
            let formName  = formAndField[0];
            let opportunity = this.$store.getters.currentStrategy.opportunity_name;
            let forms = DefaultOpportunity;
            let form  = forms[formName];
            let formGroup = form?.group;

            path = formName == 'company'? 
                '/settings/company' : 
                '/edit/opportunities/' + opportunity +'/' + formGroup +'/'+ formName;


            return {
                path: path,
                name: formName,
                group: formGroup,
            };
        }, 
    },

    methods: {
        validate() {
            let isValid = true;
            return isValid;
        }, 

        setup(data) {
            this.data = {...this.data, ...data};
            this.$refs.input.textContent = this.data.value;

            this.$refs.input.addEventListener('input', ()=> {
                this.data.value = this.$refs.input.textContent;
            }, false);
        },

        close() {
            window.$('.modal').modal('hide');
        },

        gotoForm() {
            let path = this.formData.path;
            if(path) {
                this.close();
                setTimeout(()=>{
                    this.$root.$router.push( {path: path} );
                }, 500); 
            }
        },

        unlink() {
            window.$(this.$el).trigger('unlink', [this.data]);
            setTimeout(this.close, 500);
        },

        reset() {
            this.current = {
                fieldName: 'field_name',
                fieldValue: 'field_value',
                callback: null
            };
        },

        resize() {
            this.$refs.title.style.height = 'auto';

            window.$(this.$refs.title).animate({
                height: this.$refs.title.scrollHeight + 'px'
            }, 200);
        },

    },


    mounted() {
        // console.log(DefaultOpportunity);

        // window.field = this;
        // this.reset();

        // setTimeout(()=>{
        //     window.$(this.$el).on('hide.bs.modal', ()=> {
        //         setTimeout(()=>{
        //             this.resize();
        //         }, 200);
        //     });
        //     window.$(this.$el).on('shown.bs.modal', ()=> {
        //         this.resize();
        //         setTimeout(()=>{
        //             this.$refs.title.focus();
        //         }, 200);
        //     });
        // }, 0);
    }
};
</script>

<style lang="css" scoped>

textarea {
    min-height: 47px;
}
textarea.error {
    border: solid #e91e63 1.5px !important;
}

</style>
