<template lang="">
    <div class="px-2">
        <div class="mb-3">
            <label for="form-title" class="form-label">Name</label>
            <input v-model="data.title" class="form-control text-input" id="form-title" type="text">
            <small class="fs-6 text-muted">What would you like to call this field?</small>
        </div>  

        <div class="mb-3">
            <label for="form-key" class="form-label">Key</label>
            <input v-model="data.key" class="form-control text-input" id="form-key" type="text">
            <small class="fs-6 text-muted">What "key" name would you like to use?</small>
        </div>  


        <div class="mb-3">
            <label for="form-description" class="form-label">Description</label>
            <textarea v-model="data.description" class="form-control text-input" id="form-description" style="resize: none;"></textarea>
            <small class="fs-6 text-muted">Add a brief description of the form field.</small>
        </div> 

        <div class="mb-3">
            <label for="form-hint" class="form-label">Hint</label>
            <input v-model="data.hint" class="form-control text-input" id="form-hint" type="text">
            <small class="fs-6 text-muted">Add a brief description of the form field.</small>
        </div> 

        <div class="mb-3">
            <label for="form-type" class="form-label">Type</label>
            <select class="form-select" id="form-type" v-model="data.type">
                <option value="text">Text</option>
                <option value="textarea">Paragraph</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="color">Color</option>
                <option value="email">Email</option>
                <option value="url">URL</option>
                <option value="tel">Phone</option>
            </select>
            <small class="fs-6 text-muted">Select the type of data.</small>
        </div> 

        <input v-model="data.name" class="form-control text-input" id="form-name" type="hidden"> 
    </div>
</template>
<script>
export default {

    name: 'ModalContentFormField',

    data() {
        return {
            data: {
            }
        }
    },
    

    methods: {
        validate() {
            return true;
        }
    },
}
</script>