<template lang="">
    <div class="px-2">
        <div class="mb-3">
            <label for="data-title" class="form-label">Name</label>
            <input v-model="data.title" class="form-control text-input" id="data-title" type="text">
            <small class="fs-6 text-muted">What would you like to call this workbook?</small>
        </div> 

         

        <div class="mb-3">
            <label for="data-description" class="form-label">Description</label>
            <textarea v-model="data.excerpt" class="form-control text-input" id="data-description" style="resize: none;"></textarea>
            <small class="fs-6 text-muted">Add a brief description of your workbook.</small>
        </div>   

        <div class="mb-3">
            <input class="form-control text-input" type="hidden" id="data-color" v-model="data.color">

            <label for="data-description" class="form-label">Color</label>
            <div>
                <a v-for="(color, index) in colors" :key="index" 
                    @click.prevent="current.color = data.color = color"
                    :class="[color == data.color? 'active' : '']"
                    class="btn p-0 btn-color rounded-circle d-inline-block me-2" :style="{'background-color': color}">
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    name: 'ModalContentWorkbook',

    data() {
        return {
            data: {
            },
            current: {
                color: '#AAD6FC'
            },
            colors: [
                '#AAD6FC',
                '#D6FDEF',
                '#FFF3CB',
                '#FFD393',
                '#FFB1F4',
                '#C6C5FF',
                '#DEF0FF',
                '#FFFFFF',
                // '#f8cdd0',
            ]
        }
    },


    methods: {
        validate() {
            return true;
        }
    },
}
</script>

<style scoped>

.btn-color {
    transition: all 0.2s;
    border: solid 3px #eeeeee;
    width: 38px;
    height: 38px;
    background-color: white;
    box-sizing: content-box;
    outline: solid transparent 1.5px;
}
.btn-color.active {
    border-color: white;
    outline: solid #e0e4ff 2px;
}
</style>