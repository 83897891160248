<template lang="">
    <a class="btn btn-primary appui-btn-main"
        :class="[classes]" 
        @click="action(uid)">

        <span style="margin-left: -4px" class="material-icons-outlined pull-left pe-2" v-if="icon?.length > 0 && iconPosition === 'left'">
            {{icon}}
        </span>

        <span style="line-height: 26px">
            <slot></slot>
        </span>

        <div v-if="isLoading" class="spinner-border spinner-border-sm ms-2" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <span style="margin-right: -4px" class="material-icons-outlined pull-right ps-2" v-if="icon?.length > 0 && iconPosition === 'right'">
            {{icon}}
        </span>
    </a>
</template>
<script>

import common from './Common.js';

export default {
    props: { ...common.props }, 

    data() {
        return { ...common.data }
    },

    computed: { ...common.computed }, 


    methods: { ...common.methods }
}
</script>
<style>


.appui-btn-main {
    /* display: inline-block; */
}
</style>