<template lang="">
    <div>
        <p class="fs-5 pb-3">{{ context?.message || 'Select the file you wish to upload.' }}</p>
        <p class="d-flex w-100">
            <input class="text-file border btn ellipsis flex-fill fs-5 me-2 position-relative text-start disabled"
                :class="[file? 'text-dark' : 'text-muted']"
                v-model="fileName"
                />
            <button class="btn-upload btn btn-primary text-center onverflow-hidden position-relative flex-grow-0 flex-shrink-0">
                <input type="file" name="file-chooser" class="position-absolute top-0 start-0 w-100 h-100">
                <span>{{ context?.buttonLabel || 'SELECT FILE' }}</span>
            </button>
        </p>
    </div>
</template>
<script>
import common from './ModalContent.js';

export default {
    name: 'ModalContentUploader',

    data() {
        return {
            ...common.data,
            ...{
                /** set by the "setup" method in the modal parent component */
                context: {},
                content: null,
                file: null,
            }
        };
    },

    computed: {...{

            // message() {
            //     console.log('message', this.context);
            //     return this.context?.message || '???';
            // }, 

            // chooseLabel() {
            //     return this.context?.chooseLabel || 'XXX';
            // }, 

            fileName: {
                get() {
                    let title = this.context?.noFileLabel || 'No file chosen';
                    if(this.file?.name) {
                        title = this.file?.name;
                    }
                    return title;
                }, 
                set(newVal) {
                    console.log(newVal)
                    this.opportunity.post_title = newVal;
                }
            }
        }
    },

    methods: {
        ...{
            setup(context) {
                this.context = context;
            },

            reset() {
                this.opportunity = null;
            },

            getFile(event) {
                const input = event.target
                if ('files' in input && input.files.length > 0) {
                    let contentTarget = document.querySelector('.text-file');
                    this.placeFileContent(contentTarget, input.files[0]);
                }
            },

            placeFileContent(target, file) {
                target
                this.readFileContent(file).then(async(content) => {
                    // let data = await this.readAPPC(appc);
                    this.content = content;
                    this.file = file;
                }).catch(error => console.log(error));
            },

            readFileContent(file) {
                const reader = new FileReader()
                return new Promise((resolve, reject) => {
                    reader.onload = event => resolve(event.target.result)
                    reader.onerror = error => reject(error)
                    reader.readAsText(file)
                })
            },

            // async readAPPC(appc) {
            //     let data = await this.$store.dispatch('appcToObject', appc);
            //     return data;
            // },
        }
    }, 

    mounted() {
        this.$el.querySelector('input[type="file"]').addEventListener('change', this.getFile);
    }
}
</script>


<style scoped>
.btn-upload {
    width: 140px;
    max-width: 140px;
}

.btn-upload input[type="file"] {
    opacity: 0;
    cursor: pointer;
}


</style>