<template>
    <div
        id="modal-generic"
        class="cmp-modal-generic mw-100 modal fade amk center from-center r8"
        data-cmp="ModalGeneric"
        tabindex="-1"
        v-if="render"
    >
        <div class="modal-dialog"
            :class="['modal-' + data.size]"
        >
            <div class="modal-content border-0 shadow-lg" style="padding-bottom: 0px !important">
                <div class="modal-header border-0 px-3 pb-0">
                    <h4 v-if="title?.length" class="modal-title font-size-heading-md font-weight-normal">{{ title }}</h4>
                </div>
                <div class="modal-body">
                     <component ref="content" v-bind:is="component"></component>
                </div>

                <div class="modal-footer --px-3 border-0">
                    <button type="button" 
                        class="btn btn-link text-decoration-none text-uppercase" 
                        :class="[data.cancel?.disabled && 'disabled']"
                        v-if="data.cancel?.visible"
                        @click="cancel()">
                    {{ cancelLabel }}</button>

                    <button type="button" 
                        class="btn btn-link text-decoration-none text-uppercase" 
                        :class="[data.okay?.disabled && 'disabled']"
                        v-if="data.okay?.visible"
                        @click="okay()">
                        {{ okayLabel }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ModalContentTest from  './content/ModalContentTest.vue';
import OpportunityTmplInfo from  './content/OpportunityTmplInfo.vue';
import ModalContentDummy from  './content/ModalContentDummy.vue';
import UploadOpportunity from  './content/UploadOpportunity.vue';
import CreateStrategyContent from  './content/CreateStrategyContent.vue';
import ModalContentWorkbook from  './content/ModalContentWorkbook.vue';
import ModalContentForm from  './content/ModalContentForm.vue';
import ModalContentFormField from  './content/ModalContentFormField.vue';
import ModalContentOpportunity from  './content/ModalContentOpportunity.vue';
import ModalContentPageStrategy from  './content/ModalContentPageStrategy.vue';
import ModalContentField from  './content/ModalContentField.vue';
import ModalContentSelector from  './content/ModalContentSelector.vue';
import ModalContentUploader from  './content/ModalContentUploader.vue';
import ModalContentAIStrategies from  './content/ModalContentAIStrategies.vue';
import ModalContentAIGenerate from  './content/ModalContentAIGenerate.vue';
import ModalContentAIHistory from  './content/ModalContentAIHistory.vue';
import NewAIFormulaModalContent from  './content/NewAIFormulaModalContent.vue';
import EditUserAIFormulaModalContent from  './content/EditUserAIFormulaModalContent.vue';
import RunUserAIFormulaModalContent from  './content/RunUserAIFormulaModalContent.vue';

export default {
    name: "ModalContainer",

    components: {
        ModalContentTest,
        OpportunityTmplInfo,
        ModalContentDummy,
        UploadOpportunity,
        CreateStrategyContent,
        ModalContentWorkbook,
        ModalContentForm,
        ModalContentFormField,
        ModalContentPageStrategy,
        ModalContentOpportunity,
        ModalContentField,
        ModalContentSelector,
        ModalContentUploader,
        ModalContentAIStrategies,
        ModalContentAIGenerate, 
        ModalContentAIHistory,
        NewAIFormulaModalContent,
        EditUserAIFormulaModalContent,
        RunUserAIFormulaModalContent,
    },

    data() {
        return {
            render: true, 

            data: {},

            defaults: {
                title: '',

                /**
                 * The name of the modal content component
                 */
                content: '',

                context: {},

                size: null,

                /**
                 * The function to process the content from the modal.
                 * This will be passed to the "okay" callback function when
                 * user clicks "ok".
                 *
                 * @param {Vue} cmp The current modal component
                 * 
                 * 
                 * var options = {
                 *      okay: {
                 *          callback(component) {
                 *              console.log(component);
                 *          }
                 *      }, 
                 *      validator(component) {
                 *          return true;
                 *      }
                 * };
                 */
                // process: (cmp)=>{ return cmp},

                okay: {
                    label: 'OK',
                    visible: true,
                    disabled: false
                },
                cancel: {
                    label: 'CANCEL',
                    visible: true,
                    disabled: false
                }
            }
        };
    },

    computed: {
        component() {
            return this.data.content;
        }, 

        title() {
            return this.data.title;
        }, 

        okayLabel() {
            return this.data.okay.label;
        }, 

        cancelLabel() {
            return this.data.cancel.label;
        }, 

        // size() {
        //     let value = '';
        //     if(this.data?.size && this.data.size != null&& ['sm', 'lg', 'xl'].includes(this.data.size)) {
        //         value = 'modal-' + this.data.size;
        //     }
        //     return value;
        // }
    },

    methods: {


        open(data) {
            this.reset();
            this.setup(data);
            this.show();
        },

        setup(data) {
            this.data = data;
            setTimeout( ()=> {
                this.content().options = data;
            }, 0);
        },

        content() {
            return this.$refs.content;
        },

        show() {
            window.$(this.$el).modal('show');
        },

        hide() {
            window.$(this.$el).modal('hide');
        },

        validate() {
            let isValid = true;
            if(this.$refs?.content?.validate) {
                isValid = this.$refs.content.validate.apply(this.$refs.content, [this.$refs.content]);
            }

            return isValid;
        },

        okay() {
            if(this.validate()) {
                this.hide();
                setTimeout(()=>{
                    typeof this.data.okay.callback == 'function' && this.data.okay.callback.apply(null, [this.$refs.content]);
                }, 0);
            }
        }, 

        cancel() {
            // this.data.autohide && 
            this.hide();
            setTimeout(()=>{
                typeof this.data.cancel.callback == 'function' && this.data.cancel.callback.apply(null, [this.$refs.content]);
            }, 0);
        }, 

        /**
         * TODO: move this method to an utility class
         */
        merge(target, source) {
            for(let i in source) {
                if(target[i] && target[i] instanceof Object && source[i] instanceof Object) {
                    target[i] = this.merge(target[i], source[i])
                } else {
                    target[i] = source[i];
                }
            }

            return target;
        },

        reset() {
            this.data = JSON.parse( JSON.stringify(this.defaults) );
        }, 
    },



    mounted() {
        window.$(this.$el).on('shown.bs.modal', ()=> {
            // this.content(ModalContentTest);
        });

        window.$(this.$el).on('hidden.bs.modal', ()=> {
            this.reset();
        });

        window.modalContainer = this;
    },
};
</script>

<style scoped></style>
