// See: https://github.com/evanplaice/jquery-csv


import csv from "./js/jquery.csv";

export default {
    install: (app, options) => {
        options

        app.mixin({
            beforeCreate() {
                this.$csv = csv
            }
        })
    }
}