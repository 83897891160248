
import forms from "./forms.js";

forms

export default {
    ping() {
        console.log('workbook...');
    }, 

    /* Workbook methods */

    /**
     * 
     * @param {*} data 
     */
    async create(data) {
        console.log('Create Workbook', data)
    },

    async get(name) {
        console.log('Get Workbook', name)

    }, 

    async update(name, data) {
        console.log('Update Workbook', name, data)
    }, 

    async delete(name) {
        console.log('Delete Workbook', name)
    }, 

    async getAll() {
        console.log('Get All Workbooks')
    }, 


    /* Form methods */

    /**
     * Get all forms inside the Workbook
     */
    getForms() {
        console.log('Get all forms')
    },

    /**
     * Get details of a single form
     */
    async getForm(name) {
        console.log('Get form', name)
    },

    /**
     * Add a new form at the end of the form list
     */
    async addForm(data) {
        console.log('Add form', data)
    }, 


    /**
     * Update all form pointers (use if forms order is changed)
     */
    async updateForms(name, data) {
        console.log('Update form', name, data)
    },

    /**
     * Remove form rom the Workbook
     */
    async removeForm(name) {
        console.log('Remove form', name)
    }, 

    /**
     * Remove orphan entries after a form has been deleted
     */
    async cleanFormData(name) {
        console.log('Clean form data', name)
    }
}