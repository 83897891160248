
const local = {
    key: 'local-data',
    
    persist: true,

    storage: function(presist) {
        let store;
        presist = presist || this.persist;
        if (presist) {
            store = window.localStorage;
        } else {
            store = window.sessionStorage;
        }
        return store;
    },


    get: function (key) {
        var all = this.getAll();
        return all[key];
    },


    set: function (key, value) {
        var all = this.getAll();
        all[key] = value;
        this.setAll(all);
    },


    getAll: function () {
        var all = JSON.parse(this.storage().getItem(this.key));
        return all || {};
    },


    setAll: function(all){
        this.storage().setItem(this.key, JSON.stringify(all));
    },


    attach: function (object) {
        for (const key in object) {
            this.set(key, object[key])
        }
    },


    has: function (key) {
        var all = this.getAll();
        return key in all;
    },


    remove: function (key) {
        var all = this.getAll();
        delete all[key];

        this.setAll(all);
    },


    destroy: function () {
        this.storage().removeItem(this.key);
    }
}

const Local = {
    create: function(key) {
        let instance = {};
        for(let i in local) {
            if(typeof local[i] == 'function') {
                instance[i] = function() {
                    return local[i].apply(instance, arguments);
                }
            } else {
                instance[i] = local[i];
            }
        }

        instance.key = key;
        return instance;
    }
}

export default Local;