
import defaultForm from './DefaultForm.js';
import wp from '@/services/WordpressService.js';
import { EventBus } from './EventBus.js';

export default {

    /**
     * 
     * @param {Function} store.commit
     *
     * @param {String} data.post_title
     * @param {String} data.post_excerpt
     * @param {String} data.post_content
     * @param {Object} data.meta_input
     * @param {String} data.meta_input.workbook
     *
     */
    async createForm({ commit }, data) {
        console.log('>>> createForm', data)

        commit('SET_LOADING_STATE', true);
        let form =  {...defaultForm, ...data};
        let response = await wp.createForm(form);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        await this.dispatch('getForms');

        EventBus.trigger(EventBus.events.FORM_CREATED, [response]);

        return response;
    },



    /**
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {Boolean} forceRefresh 
     * 
     * @returns 
     */
    async getForms({ commit , state }, forceRefresh=false) {
        let collection;

        if(state.forms?.length > 0 && forceRefresh === false) {
            collection = state.forms;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getForms();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_FORMS', collection);

        return collection;
    },




    /**
     * This action is used in "view: Apps"
     * 
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {Boolean} data.workbookId 
     * @param {Boolean} data.forceRefresh 
     * 
     * @returns 
     */
     async getFormsByWorkbookId({ commit , state }, { workbookId, forceRefresh=false }) {
        let collection;

        console.log('getFormsByWorkbookId', arguments);

        if(state.forms?.length > 0 && forceRefresh === false) {
            collection = state.forms;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getFormsByWorkbookId(workbookId);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_FORMS', collection);

        return collection;
    },
    

    // async fetchForm(context, name) { context; return await this.dispatch('getForm', name)},

    
    async getForm({ commit , state }, name, meta=true) {
        let form;

        if(typeof state.active.forms[name] != 'undefined') {
            form = state.active.forms[name];
        } else {
            commit('SET_LOADING_STATE', true);
            form = await wp.getForm(name, meta);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_FORM', {
            name: name, 
            data: form
        });

        commit('SET_CURRENT_FORM', form );

        return form;
    }, 




    /**
     * 
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {*} request.ID 
     * @param {*} request.title 
     * @param {*} request.name 
     * @param {*} request.content 
     * @param {*} request.excerpt 
     * @param {*} request.meta 
     * 
     * @returns 
     */
    async getEntriesByFormId({ commit , state }, request) {
        state

        commit('SET_LOADING_STATE', true);
        let entries = await wp.getEntriesByFormId(request.ID);
        commit('SET_LOADING_STATE', false);

        commit('SET_ENTRIES', entries);

        return entries;
    }, 



    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     *
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.field The field to be updated
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    // async updateOpportunityFormField({ commit , state, getters }, request) {
    //     if(getters?.currentOpportunity?.content?.[request.form]?.fields) {
    //         state.current.opportunity.content[request.form].fields[request.field] = request.value;
    //     }

    //     commit('SET_LOADING_STATE', true);
    //     await wp.updateOpportunityFormField(request);
    //     commit('SET_LOADING_STATE', false);
    // }, 



    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    // async updateOpportunityForm({ commit , state }, request) {
    //     commit , state;
    //     commit('SET_LOADING_STATE', true);
    //     await wp.updateOpportunityForm(request);
    //     commit('SET_LOADING_STATE', false);
    // }, 




    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.ID Name of the form
     * @param {Object} request.post_title
     * @param {Object} request.post_excerpt
     *
     *
     */
    async updateForm({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.updateForm(request);
        commit('SET_LOADING_STATE', false);

        commit('SET_FORM', {
            name: response.name, 
            data: response
        });

        EventBus.trigger(EventBus.events.FORM_UPDATED, [response]);

        return response;
    }, 

    /**
     * 
     * @param {Object} context 
     * @param {Object} context.state 
     * @param {Function} context.commit
     * 
     * @param {Object} data 
     * @param {Number} data.ID
     * @param {String} data.name
     * @param {String} data.title
     */
    async deleteForm({ commit, state }, data) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.deleteForm(data.ID, false /** forceDelete */);
        commit('SET_LOADING_STATE', false);

        // /** remove if strategy is current */
        // (state.current.data?.name == data.name) && (state.current.data = null);

        // /** remove if strategy is in active list */
        (state.active.forms[data.name]) && delete(state.active.forms[data.name]);

        EventBus.trigger(EventBus.events.FORM_DELETED, [response]);
        return true;
    }, 




    /**
     * 
     * @param {Object} context 
     * @param {Object} context.state 
     * @param {Function} context.commit
     * 
     * @param {Object} data 
     * @param {Number} data.form_id
     * @param {String} data.field
     */
    async addFieldToForm({ state, commit }, data) {
        state;
        if(!data?.field || !data?.form_id) {
            console.warn('WARNING: missing data', data);
            return;
        }

        commit('SET_LOADING_STATE', true);
        let response = await wp.addFieldToForm(data.form_id, data.field);
        commit('SET_LOADING_STATE', false);

        commit('SET_FORM', {
            name: response.name,
            data: response,
        });

        EventBus.trigger(EventBus.events.FORM_FIELD_ADDED, [response]);
        return response;
    },






    /**
     * 
     * @param {Object} context 
     * @param {Object} context.state 
     * @param {Function} context.commit
     * 
     * @param {Object} data 
     * @param {Number} data.form_id
     * @param {String} data.field
     * 
     * @return {Object} The entie form object
     */
     async updateFormField({ state, commit }, data) {
        state;
        if(!data?.field || !data?.form_id) {
            console.warn('WARNING: missing data', data);
            return;
        }

        commit('SET_LOADING_STATE', true);
        let response = await wp.updateFormField(data.form_id, data.field);
        commit('SET_LOADING_STATE', false);

        commit('SET_FORM', {
            name: response.name,
            data: response,
        });

        EventBus.trigger(EventBus.events.FORM_FIELD_UPDATED, [response]);
        return response;
    },






    /**
     * 
     * @param {*} context 
     * @param {*} context.state 
     * @param {*} context.commit
     * 
     * @param {*} request 
     * @param {*} request.ID
     * @param {*} request.name
     * @param {*} request.title
     */
    // async trashOpportunity({ state, commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.trashOpportunity(request.name);
    //     commit('SET_LOADING_STATE', false);

    //     /** remove if strategy is current */
    //     (state.current.opportunity.name == request.name) && (state.current.opportunity = null);

    //     /** remove if strategy is in active list */
    //     (state.active.opportunities[request.name]) && delete(state.active.opportunities[request.name]);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_TRASHED, [response]);
    //     return true;
    // }, 







    /**
     * @param {Function} commit
     * @param {Object} request
     * @param {Object} request.ID 
     * @param {Object} request.post_title
     *
     *
     */
    // async renameOpportunity({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.renameOpportunity(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_RENAMED, [response]);
    // }, 





    // async duplicateWorkbook({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.duplicateWorkbook(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.WORKBOOK_DUPLICATED, [response]);
    // }, 





    // async createOpportunityTemplate({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.createOpportunityTemplate(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_TEMPLATE_CREATED, [response]);
    // }, 




    // async fetchOpportunityTemplates({ commit , state }) {
    //     let collection;

    //     if(state.opportunityTemplates.length > 0) {
    //         collection = state.opportunityTemplates;
    //     } else {
    //         commit('SET_LOADING_STATE', true);
    //         collection = await wp.getOpportunityTemplatesFromCache();
    //         commit('SET_LOADING_STATE', false);
    //     }

    //     commit('SET_OPPORTUNITY_TEMPLATES', collection);

    //     return state.opportunityTemplates;
    // },






}