const ENABLED = false;

/**
 * Manage current user capability. This helps assess whether or not the user
 * has permissions to create, read, update, or delete specific type of data. 
 * The data fors which the user must be authorized includes:
 *  
 * <ul>
 *  <li>opportunities</li>
 *  <li>strategies</li>
 *  <li>ai</li>
 *  <li>invitations</li>
 * </ul>
 * 
 */
export default {
    install: async (app) => {


        let initialized = null;

        let ACCOUNTS = {
            /**
             * The user has no ability to intereact with tha application. This will be the case if the
             * user has:
             * - Cancelled his/her account
             * - Been suspected
             * - Payments are missing
             */
            UNASSIGNED: 'unassigned',

            /**
             * Ther user can only view previouslu created content. No other interaction are allowed.
             * This will be appropriate account type for someone who is allowed to review his/her account.
             */
            VIEW_ONLY: 'viewOnly',

            /**
             * Consider a scenario in which a prospect wants to evaluate the application but it is not
             * clear if the application is good for him/her. An evaluation account is intended to expire
             * in 2 hours and it may have limited features or access. The goal is to allow someone to 
             * evluate the application, while preventing the user from simply using the application
             * without paying.
             */
            EVALUATION: 'evaluation',

            /**
             * Ideal for allowing someone to use the application for 24 hours. This is recommended for
             * potencial buyers, or for scenarios in which the user is doing a guided demo (test drive),
             * and he/she is the person using the application under supervision (assisted). A "TEST"
             * account should become an "UNASSIGNED" after 24 hours.
             */
            TEST: 'test',

            /**
             * The user has paid to used the application but he/she may be entitle to a full refund.
             * This accoutntype will be appropriate if the user pays for a limited time use of the 
             * application. The application may disable some of its functionality if the account type 
             * is "TRIAL". If the user cancels his/her account during the trial period, a new account
             * type must be set at ther server level, otherwise the application will automatically 
             * change the type "TRIAL" to a type "SUBSCRIBER" once the trial has expired.
             */
            TRIAL: 'trial',

            /**
             * Regular account. The user is a paying subscriber an can use the application based
             * the allowance associated to the type of subscription. 
             */
            SUBSCRIBER: 'subscriber',

            /**
             * A user that can act on behalf a subscriber. A "CONTRIBUTOR" has the same right and 
             * restriction of the subscriber on behalf he/she is acting.
             */
            CONTRIBUTOR: 'contributor',

            /**
             * An administrator with full access and no restrictions to use the application.
             */
            ADMIN: 'admin',
        };

        let defaultCapabilities = {
            /**
             * Account type or plan name
             * 
             * @property {String} accountType The type of account the use has. Valid values are:
             * 
             * <ul>
             *  <li>evaluation</li>
             *  <li>test</li>
             *  <li>trial</li>
             *  <li>subscriber</li>
             *  <li>admin</li>
             * </ul>
             */
            name: ACCOUNTS.UNASSIGNED,

            /**
             * The date and time when the account was activated.
             */
            activation: null,

            /**
             * The date and time when the account will no longer be active. If the value is set to NULL,
             * the account will never expire.
             */
            expiration: null,


            /**
             * Whether or not the account has been paused. The user can choose to pause the account.
             * This will result in th account not been charged.
             * 
             * TODO: This will require payment integration.
             */
            paused: false,

            /**
             * The usage or allowance that stablished how much of the application capabilities the
             * user can use. This is a dictionary that will capture the user's capabilities as a 
             * number that will indicate a limit. A value of zero (0), means that the user cannot
             * create new entries; a value of minus one (-1), means that the user has no limit for 
             * the number of entries he/she can create; any other number will represent the limit
             * for new entries that the use can create. 
             */
            quota: {
                opportunities: 10,
                strategies: 0,
                ai: 0,
                invitations: 0
            },
    
            opportunities: {
                create: true,
                read: false, 
                udpate: false,
                delete: false,
            },
            
            strategies: {
                create: false,
                read: false, 
                udpate: false,
                delete: false,
            },

            ai: {
                create: false,
                read: false, 
                udpate: false,
                delete: false,
            },

            invitations: {
                create: false,
                read: false, 
                udpate: false,
                delete: false,
            },
        };


        /**
         * Current user capabilities
         */
        let capabilities = {};


        app.config.globalProperties.$capabilities = {



            async init(callback) {
                // console.log('%c$capabilities.init()', 'color: green');
                // console.log('INITIALIZED', initialized);

                    defaultCapabilities

                let scope = this;
                let $store = app.config.globalProperties.$store;


                // fisrt time init() is called.
                if(initialized === null) {
                    initialized = false;
                    capabilities = await $store.getters.wp.run('getCurrentUserCapabilities', []);
                    capabilities = scope.verify(capabilities);
                    initialized = true;
                }

                // only runs after the first time is 'initialized' is not equal true.
                // ths will re-run the function (recursise call) until "initialized" 
                // is set to 'true'
                if(!initialized) {
                    setTimeout( async () => {
                        await this.init(callback);
                    }, 500);
                    return;
                }

                // 'initialized' is 'true', invoke 'callback'.
                typeof callback == 'function' && callback.apply(capabilities);

                // if(window.cbn) {
                //     window.cbn += 1;
                // } else {
                //     window.cbn = 1;
                // }
                // console.log('CALLBACK '+ window.cbn, 'color: green');


                

                // TODO: request data from the server
                // TODO: store data in $session

                // return new Promise(function(resolve) {


                    // capabilities = defaultCapabilities;
                    // capabilities = scope.verify(capabilities);

                    // if(!initialized) {
                    //     setTimeout(()=>{
                    //         resolve(capabilities);
                    //         typeof callback == 'function' && callback.apply(null);
                    //     }, 100);
                    // } else {
                    //     resolve(capabilities);
                    // }
                // });
            },


            /**
             * Verify the integrity of the capabilities object.
             * 
             * @param {Object} capabilities All user's capabilities 
             * 
             * @param {Function} success A callback function executed if there are not issue with the capability object.
             * 
             * @param {Function} error A callback function executed if there are issues with the capability object.
             * 
             * @returns {Object} the capabilities object.
             */
            verify(capabilities, success, error) {
                // console.log('%c$capabilities.verify()', 'color: green');
                success, error;
                return capabilities;
            },


            /**
             * Get current user's all capabilities.
             * 
             * @returns {Object} capabilities
             *                   capabilities.opportunities{...}
             *                   capabilities.strategies{...}
             *                   capabilities.ai{...}
             *                   capabilities.invitations{...}
             */
            getAll() {
                // console.log('%c$capabilities.getAll()', 'color: green');
                /**
                 * Prevent modification from an instance of "capabilities"
                 */
                let readOnly = JSON.parse(JSON.stringify(capabilities))
                return readOnly;
            }, 


            /**
             * Get current user's capabilities.
             * 
             * @param {String} cap The individual capability you want to know (e.g. "opportunities.create"). Valid values are:
             * 
             *  opportunities.create
             *  opportunities.read
             *  opportunities.update
             *  opportunities.delete
             * 
             *  strategies.create
             *  strategies.read
             *  strategies.update
             *  strategies.delete
             * 
             *  ai.create
             *  ai.read
             *  ai.update
             *  ai.delete
             * 
             *  invitations.create
             *  invitations.read
             *  invitations.update
             *  invitations.delete
             * 
             * @example
             * 
             * let capabilityKey = 'invitations.create';
             * let capabilityValue = app.$capabilities.get(capabilityKey);
             * console.log(`Can user invite others? %{capabilityValue}`); // the return value will be "true" or "false".
             * 
             * @returns {Boolean} Return TRUE if the user has the capability to perform set task, otherwise it returns FALSE. Example:
             * 
             * {
             *  module: 'opportunity',
             *  action: 'create'
             * }
             * 
             */
             hasCapability(cap) {
                // console.log('%capp.$capabilities.hasCapability('+ cap +')', 'color: green');

                let value = null;
                let identifier = this.parseCapability(cap);

                if(!identifier) {
                    return value;
                }

                if(identifier.module in capabilities && identifier.action in capabilities[identifier.module]) {
                    value = capabilities[identifier.module][identifier.action];
                }

                return value;
            }, 


            /**
             * Extract the module and capability values from a capablity string identifyer.
             * 
             * @private
             * 
             * @param {cap} cap Parses the capability string identifier (e.g. 'ai.delete')
             * @returns {Object} A dictionary with the module and capability values. Example:
             * 
             *                  object.module
             *                  object.action
             * 
             *                  NOTE: This will return null if the string identifyer is invalid.
             * 
             */
            parseCapability(cap) {
                // console.log('%c$capabilities.parseCapability()', 'color: green', cap);
                if(cap && typeof cap  == 'string' && cap.indexOf('.') > -1) {
                    let [module, action] = cap.split('.').splice(0,2);
                    if(module.length && action.length) {
                        return {module, action};
                    }
                }

                return null;
            },


            /**
             * Returns the current user's account type.
             */
            name() {
                return capabilities?.name;
            },


            /**
             * Allow accessing the quota object. This will help the application determine whether or not
             * the user can still create new entries of different objects (e.g. opportunities, estrategies)
             * 
             * @param {String} entry The object for which the quota is inquired (e.g. opportunities, ai, invitations)
             * 
             * @return {Object, Number} A copy of the quota object or the value (Number) of the individual quota.
             */
            quota(entry) {
                // console.log('%c$capabilities.quota('+ entry +')', 'color: green');
                let value = entry && entry in capabilities.quota? capabilities.quota[entry] : null;
                if(!entry) {
                    let readOnly = JSON.parse(JSON.stringify(capabilities.quota));
                    return readOnly;
                } else {
                    return value;
                }
            },

            /**
             * This method updates quota information (e.g. opportunities, invitations, ai). The values must be updated
             * in the server side first. "update" is intended for the sole purpose of syncing the UI with the database.
             * 
             * IMPORTANT: update can only be used to substract from the quota.
             * 
             * @param {String} module The object that you wish to update (e.g. opportunities, invitations, ai)
             * @param {Number} amount The amount to be subsctrated. In the case of the "ai" module, the amount represents the number 
             *                          of tokens to be substracted.
             */
            update(module, amount) {
                let done = false, 
                    action = amount >= 0? 'up' : 'down',
                    currentAmount = module in capabilities.quota? capabilities.quota[module] : 0;

                switch (action) {
                    case 'up':
                        capabilities.quota[module] += amount;
                        done = true;
                    break;

                    case 'down':
                        if(currentAmount <= 0) {
                            capabilities.quota[module] = 0;
                        } else {
                            capabilities.quota[module] += amount;
                            done = true;
                        }
                    break;
                }

                return done;
            },


            /**
             * Find out if the current user has the right to perform CRUD actions on a given
             * data (e.g. opportunities, strategies, invitations).
             * 
             * @param {Sting} cap The individual capability you want to know (e.g. "opportunities.create")
             * @param {Function} resolve A function that will be executed if the user has the given capability
             * @param {Function} reject A function that will be executed if the user DOES NOT HAVE the given capability
             * 
             * @example
             * 
             * app.$capabilities.userCan('opportunities.create', 
             *  function() {
             *      alert('Yay! You can create opportunities');
             *  }, 
             *  function() {
             *      alert('Sorry. You are not allowed to create opportunities');
             *  }
             * );
             * 
             * if(isUserAllowedToCreateNewOpportunities) {
             *  // create new opportunity
             * }
             * 
             * @returns {Boolean}
             */
            userCan(cap, resolve, reject) {
                // console.log('%c$capabilities.userCan('+ cap +')', 'color: green');

                let capObj = this.parseCapability(cap); /* {module, action} */
                let quota = this.quota(capObj.module);
                let allowed = this.hasCapability(cap);
                    /* if the action is "create" but the user does not have a quota, then the user is not allowed */
                    allowed = capObj.action == 'create' && quota === 0? false : allowed;

                if(allowed) {
                    let allCaps = this.getAll();
                    resolve && resolve.apply(null, [allCaps]);
                } else {
                    reject && reject.apply(null);
                }
                return allowed;
            }, 

            
            /**
             * Overwrite a method in a given object scope. This action will be persistent untill the 
             * application is refresh. In that case the condiction will be evaluated again.
             * 
             * @param {Boolean} condition A flag to determine whether or not a method should be replaced.
             * @param {Boolean} flag A flag (TRUE, FALSE) against which the "contdition" will be compared.
             * @param {Object} scope The object from which a methos may be overwritten.
             * @param {String} method The name of the method to be overwritten.
             * @param {Function} replace The new function that will be ran instead.
             * 
             * @return {Boolean} Whether or not the method was overwritten. TRUE means the method was overwritten.
             * 
             * @example 
             * 
             * var allowSum = false;
             * var mustBe = true;
             * var obj = { sum: function (a, b) { return a+b} }
             * app.$capabilities.overwriteIf(allowSum, mustBe, obj, 'sum', function() {
             *  alert('Oops! you are not allow to use this method');
             * })
             */
            overwriteIf(condition, flag, scope, method, replace) {
                // console.log('%c$capabilities.overwriteIf('+ condition +', '+ flag +')', 'color: green');
                if(!ENABLED) {
                    return true;
                }

                if(condition === flag) {
                    scope[method] = function() {
                        return replace.apply(scope, arguments);
                    }
                    return true;
                } else {
                    return false;
                }
            }
        };


        // await app.config.globalProperties.$capabilities.init( (caps) => {
        //     caps
        //     // console.log('%c$capabilities.init()::callback', 'color: green', capabilities);
        //     // let app = app?._component;
        //     // let methods = app?._component?.methods;
        // });
    }
}