<template lang="">
    <div class="px-2">
        <form @submit.prevent="">
            <div class="form-group mb-3">
                <label for=""
                    >Name this offer</label
                >
                <textarea
                    ref="title"
                    rows="1"
                    class="form-control text-input"
                    v-model="data.title"
                ></textarea>

                <input
                    type="hidden"
                    class="form-control"
                    v-model="data.name"
                />
                <small lass="form-text text-muted"
                    >(e.g. "Startups selling SaaS solutions")</small
                >
            </div>
            <div class="form-group mb-3">
                <label for="">I want to help...</label>
                <textarea
                    ref="excerpt"
                    rows="1"
                    class="form-control text-input"
                    v-model="data.excerpt"
                ></textarea>
                <small lass="form-text text-muted"
                    >(e.g. "Creating websites that make people buy with confidence.")</small
                >
            </div>
        </form>
    </div>
</template>
<script>
export default {

    name: 'ModalContentOpportunity',

    data() {
        return {
            showAdvanced: false,
            data: {
                title: '',
                name: '',
                excerpt: ''
            }
        }
    },
    
    methods: {
        validate() {
            let isValid = false;
            if(this.data.title?.length > 0) 
            {
                isValid = true;
            }

            if(!isValid) {
                alert('Please fill out all required fields');
            }

            return isValid;
        }
    },

    mounted() {
        // setTimeout(()=>{
        //     window.$(this.$el).on('hide.bs.modal', ()=> {
        //         setTimeout(()=>{
        //             this.resize();
        //         }, 200);
        //     });
        //     window.$(this.$el).on('shown.bs.modal', ()=> {
        //         this.resize();
        //         setTimeout(()=>{
        //             this.$refs.title.focus();
        //         }, 200);
        //     });
        // }, 0);
    },
}
</script>