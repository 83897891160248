
import defaultApp from './DefaultApp.js';
import wp from '@/services/WordpressService.js';
import { EventBus } from './EventBus.js';

export default {

    /**
     * 
     * @param {Function} store.commit
     *
     * @param {String} data.post_title
     * @param {String} data.post_excerpt
     * @param {String} data.post_content
     *
     */
    async createApp({ commit }, data) {
        console.log('>>> createApp', data)

        commit('SET_LOADING_STATE', true);
        let app =  {...defaultApp, ...data};
        let response = await wp.createApp(app);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        await this.dispatch('getApps');

        EventBus.trigger(EventBus.events.OPPORTUNITY_CREATED, [response]);

        return response;
    },



    /**
     * 
     * @param {Function} store.commit
     * @param {Object} store.state
     * 
     * @param {Boolean} forceRefresh 
     * 
     * @returns 
     */
    async getApps({ commit , state }, forceRefresh=false) {
        let collection;

        if(state.apps?.length > 0 && forceRefresh === false) {
            collection = state.apps;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getApps(true); // pass true to include metadata
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_APPS', collection);

        return collection;
    },



    async getApp({ commit , state }, name) {
        let app;

        if(typeof state.active.apps[name] != 'undefined') {
            app = state.active.apps[name];
        } else {
            commit('SET_LOADING_STATE', true);
            app = await wp.getApp(name);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_APP', {
            name: name, 
            data: app
        });

        commit('SET_CURRENT_APP', app );

        return app;
    }, 


    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     *
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.field The field to be updated
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    // async updateOpportunityFormField({ commit , state, getters }, request) {
    //     if(getters?.currentOpportunity?.content?.[request.form]?.fields) {
    //         state.current.opportunity.content[request.form].fields[request.field] = request.value;
    //     }

    //     commit('SET_LOADING_STATE', true);
    //     await wp.updateOpportunityFormField(request);
    //     commit('SET_LOADING_STATE', false);
    // }, 



    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    // async updateOpportunityForm({ commit , state }, request) {
    //     commit , state;
    //     commit('SET_LOADING_STATE', true);
    //     await wp.updateOpportunityForm(request);
    //     commit('SET_LOADING_STATE', false);
    // }, 




    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.ID Name of the form
     * @param {Object} request.post_title
     * @param {Object} request.post_excerpt
     *
     *
     */
    async updateApp({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.updateApp(request);
        commit('SET_LOADING_STATE', false);

        commit('SET_APP', {
            name: response.name, 
            data: response
        });

        EventBus.trigger(EventBus.events.APP_UPDATED, [response]);

        return response;
    }, 

    /**
     * 
     * @param {Object} context 
     * @param {Object} context.state 
     * @param {Function} context.commit
     * 
     * @param {Object} app 
     * @param {Number} app.ID
     * @param {String} app.name
     * @param {String} app.title
     */
    async deleteApp({ state, commit }, app) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.deleteApp(app.ID, false /** forceDelete */);
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current.app?.name == app.name) && (state.current.app = null);

        /** remove if strategy is in active list */
        (state.active.apps[app.name]) && delete(state.active.apps[app.name]);

        EventBus.trigger(EventBus.events.APP_DELETED, [response]);
        return true;
    }, 




    
    async getWorkbooksInApp({ commit , state }, id) {
        let collection;

        // if(state.apps.length > 0 && forceRefresh === false) {
        //     collection = state.apps;
        // } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getWorkbooksInApp(id); // pass true to include metadata
            commit('SET_LOADING_STATE', false);
        // }

        state.views.apps.workbooks = collection

        commit('SET_VIEW_DATA', {
            view: 'apps',
            data: state.views.apps
        });

        return state.views.apps.workbooks;
    },

    
    async getAppsByWorkbookId({ commit }, id) {
        let collection;

        // if(state.apps.length > 0 && forceRefresh === false) {
        //     collection = state.apps;
        // } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getAppsByWorkbookId(id); // pass true to include metadata
            commit('SET_LOADING_STATE', false);
        // }

        return collection;
    },



    /**
     * 
     * @param {*} context 
     * @param {*} context.state 
     * @param {*} context.commit
     * 
     * @param {*} request 
     * @param {*} request.ID
     * @param {*} request.name
     * @param {*} request.title
     */
    // async trashOpportunity({ state, commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.trashOpportunity(request.name);
    //     commit('SET_LOADING_STATE', false);

    //     /** remove if strategy is current */
    //     (state.current.opportunity.name == request.name) && (state.current.opportunity = null);

    //     /** remove if strategy is in active list */
    //     (state.active.opportunities[request.name]) && delete(state.active.opportunities[request.name]);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_TRASHED, [response]);
    //     return true;
    // }, 







    /**
     * @param {Function} commit
     * @param {Object} request
     * @param {Object} request.ID 
     * @param {Object} request.post_title
     *
     *
     */
    // async renameOpportunity({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.renameOpportunity(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_RENAMED, [response]);
    // }, 





    // async duplicateWorkbook({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.duplicateWorkbook(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.WORKBOOK_DUPLICATED, [response]);
    // }, 





    // async createOpportunityTemplate({ commit }, request) {
    //     commit('SET_LOADING_STATE', true);
    //     let response = await wp.createOpportunityTemplate(request);
    //     commit('SET_LOADING_STATE', false);

    //     EventBus.trigger(EventBus.events.OPPORTUNITY_TEMPLATE_CREATED, [response]);
    // }, 




    // async fetchOpportunityTemplates({ commit , state }) {
    //     let collection;

    //     if(state.opportunityTemplates.length > 0) {
    //         collection = state.opportunityTemplates;
    //     } else {
    //         commit('SET_LOADING_STATE', true);
    //         collection = await wp.getOpportunityTemplatesFromCache();
    //         commit('SET_LOADING_STATE', false);
    //     }

    //     commit('SET_OPPORTUNITY_TEMPLATES', collection);

    //     return state.opportunityTemplates;
    // },






}