<template lang="">
	<div class="modal fade amk center from-center r8" id="modal-select-plugin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
		<div class="modal-dialog ">
			<div class="modal-content border-0 shadow-lg">
				<div class="modal-header border-0">
					<h5 class="modal-title fs-4 font-size-heading-md font-weight-normal">{{ title }}</h5>
					<!-- <button 
                        @click="close" 
                        type="button" class="btn-close" aria-label="Close"></button> -->
				</div>
				<div class="modal-body border p-0 overflow-scroll border-end-0 border-start-0">
                    <div class="list-group list-group-flush">
                        <a v-for="(plugin, index) in collection" :key="index" href="#" 
                            @click="action(plugin)"
                            class="list-group-item list-group-item-action px-4 py-3 --d-flex"
                            >
                                <div v-if="plugin?.iconURL?.match(/^http/)" class="d-inline-block">
                                    <img :src="plugin.iconURL" class="rounded-3 plugin-icon"/>
                                </div>
                                <div v-else-if="plugin?.iconURL?.length > 0" class="plugin-icon bg-plugin d-inline-block rounded-3 text-center">
                                    <span class="material-icons">
                                    {{plugin.iconURL}}
                                    </span>
                                </div>
                                <div v-else class="d-inline-block">
                                    <img src="https://dummyimage.com/72x72/FF9D9D/FF9D9D" class="rounded-3"/>
                                </div>


                                <div class="px-3 fs-6 d-inline-block position-absolute">
                                    <div class="mb-0 fw-bold">
                                    {{plugin.title}}
                                    </div>
                                    <div class="text-muted">
                                    {{plugin.description}}
                                    </div>
                                </div>
                            </a>
                    </div>
				</div>
				<div class="modal-footer px-3 border-0">
					<button @click="close" type="button" class="btn btn-link text-decoration-none text-uppercase">CLOSE</button>
					<!-- <button @click.prevent=""  type="button" class="disabled btn btn-primary">Select</button> -->
				</div>
			</div>
		</div>
	</div>
    <div>
    </div>
</template>
<script>


export default { 
    props: {
        collection: Object,
    },

    data() {
        return {
            $modal: null,
            /** 
             * must be set from outside
             */
            callback: null,

            title: 'Select Extension',
        }
    },


    methods: {
        action(plugin) {
            this.hide(()=>{
                typeof this.callback == 'function' && this.callback.apply(null, [plugin]);
                this.reset(); 
            })
        }, 

        show() {
            window.$('#modal-select-plugin').modal('show');
        },

        hide(callback) {
            window.$('#modal-select-plugin').modal('hide');
            setTimeout(()=>{
                typeof callback == 'function' && callback.apply(null);
                this.reset();
            }, 500)
        }, 

        close() {
            this.hide(()=>{
                this.reset();
            })
        },

        reset() {
            this.callback = null;
        }
    },

    mounted() {
        this.$modal = window.$('#modal-select-plugin')
        // console.log(this.$modal);
    }
}
</script>
<style scoped>
    .modal-body {
        margin: 0 -16px;
        max-height: 480px;
    }

    .modal-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .plugin-icon {
        width: 72px;
        height: 72px;
    }
    .bg-plugin {
        background-color: rgb(192 215 251);
    }
    .material-icons {
        zoom: 1.5;
        margin-top: 25%;
    }

</style>