import './style.css';
import UIHelper from "./UIHelper.js";
import AppLogo from "./components/AppLogo.vue";
// import CoreOfferLogo from "./components/CoreOfferLogo.vue";
// import SalesmachineLogo from "./components/SalesmachineLogo.vue";
import ButtonCircle from "./components/ButtonCircle.vue";
import ButtonMain from "./components/ButtonMain.vue";
import PlaceholderBox from "./components/PlaceholderBox.vue";
import Entry from "./components/Entry.vue";
import HeaderMain from "./components/HeaderMain.vue";
import FooterMain from "./components/FooterMain.vue";
import OptionsDropdown from "./components/OptionsDropdown.vue";
import ListGroup from "./components/ListGroup.vue";

export default {
    install: (app, options) => {
        options
        app.component('AppLogo', AppLogo);
        // app.component('CoreOfferLogo', CoreOfferLogo);
        // app.component('SalesmachineLogo', SalesmachineLogo);
        app.component('ButtonCircle', ButtonCircle);
        app.component('PlaceholderBox', PlaceholderBox);
        app.component('ButtonMain', ButtonMain);
        app.component('Entry', Entry);
        app.component('HeaderMain', HeaderMain);
        app.component('FooterMain', FooterMain);
        app.component('OptionsDropdown', OptionsDropdown);
        app.component('ListGroup', ListGroup);

        app.mixin({
            data() {
                return {}
            },

            created() {
                /**
                 * IMPORTANAT: you must include the <Outlet> tag in the App.vue and assign
                 * a reference with the value of "outlet"
                 */
                this.$ui = {
                    helper: UIHelper
                };
            }
        })
    }
}