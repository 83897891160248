<template lang="">
    <div class="--d-flex --flex-row overflow-scroll ai-history border-top">
        <!-- Sidebar: Parameters -->
        <!-- <div class="params overflow-scroll pe-3 border-end">
            <small class="fs-6 d-block pt-3">{{ current?.usecase?.caption }}</small>
            <form @submit.prevent="" class="h-100 pt-3 form-generator">

                <InputCounter 
                    class="mb-3 context-input" v-for="(input, index) in current?.usecase?.contextInputs"
                    :id="input?._id" 
                    :ref="input?._id" 
                    :input="input" 
                    :key="index"></InputCounter>

                <button type="submit" 
                    class="btn btn-primary btn-pill btn-generate py-2 w-100"
                    @click="generate"
                    :class="[allowSubmit? '' : 'disabled']"
                    >
                    <div class="spinner-border spinner-border-sm me-3" role="status"
                        v-if="isLoading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    
                    Generate Content</button>
            </form>
        </div> -->

        <!-- Results -->
        <div class="results px-3 flex-fill">
            <h4 class="fs-5 text-dark py-3">Results {{ results?.length }}</h4>
            
            <div v-for="(entry, index) in results" 
                :key="index" 
                :id="`usecase-${index}`"
                class="usecase-entry pt-2 pb-3 px-3 mb-5 border rounded-3">

                <!-- column right -->
                <div>
                    <div class="text-end pt-1 mb-3">
                        <a class="btn btn-light px-1 py-0 me-2 btn-delete-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="remove(entry, 'usecase-' + index, $event)">
                            <span class="material-icons-outlined float-start" :style="{
                                'margin-top': '-2px'
                            }">
                            delete
                            </span>
                        </a>
                        <a class="btn px-3 py-0 btn-select-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select($event)">
                            <span>
                                SELECT
                            </span>
                        </a>    
                        <a class="btn btn-light text-primary px-3 py-0 btn-deselect-strategy"
                            :style="{'padding-top': '1px !important'}"
                            :target="`usecase-${index}`"
                            @click="select(null)">
                            <span>
                                DESELECT
                            </span>
                        </a>
                    </div>

                    <div class="text-content w-100" v-html="entry.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// import InputCounter from "@/components/forms/fields/InputCounter.vue";

const $ = window.$;

export default {

    name: 'ModalContentAIHistory',
    components: {
        // InputCounter
    },

    data() {
        return {
            current: {
                usecase: null,
                selection: null,
                html: ''
            },
            results: [
            ],
            allowSubmit: false,
            isLoading: false
        }
    },


    methods: {
        select(event) {

            if(!event) {
                this.current.html = '';
                this.current.selection = null;
                $('.usecase-entry').removeClass('active');
                return;
            }

            let target = $(event.currentTarget).attr('target');

            $('.ai-history .usecase-entry').removeClass('active');
            $('.ai-history .usecase-entry[id="'+ target +'"]').addClass('active');

            this.current.html = $('.ai-history .usecase-entry[id="'+ target +'"] .text-content').html();
            this.current.selection = {text: this.current.html};
        },



        async remove(usecase, elId, event) {
            usecase, elId, event

            if(!confirm('Are you sure you want to delete this entry?')) {
                return;
            }

            let deleted = await this.$store.getters.wp.run('deleteAIStrategy', [usecase.ID]);

            if(deleted) {
                for(let i=0; i<this.results.length; i++) {
                    if(this.results[i].ID == usecase.ID) {
                        this.results.splice(i, 1);
                        break;
                    }
                }
                this.$root.toast('Success! AI suggestion was deleted.');

            } else {
                alert('ERROR: unable to delete content.');
            }
        },

        addContent(text) {
            this.select(null);
            this.results.unshift({
                text: text
            });


            setTimeout(()=>{
                // scroll the results DIV to the top
                $('.ai-history .results')[0].scrollTop = 0;

                // call select and pass a fake event object
                let btn = $('.ai-history .usecase-entry:first .btn-select-strategy');
                this.select({
                    currentTarget: btn
                })
            }, 100);
        },


        inProgress(flag) {
            flag
            // if(flag) {
            //     $('.btn-generate').addClass('disabled');
            //     $('.ai-history').addClass('loading');
            //     this.isLoading = true;
            // } else {
            //     $('.btn-generate').removeClass('disabled');
            //     $('.ai-history').removeClass('loading');
            //     this.isLoading = false;
            // }
        },


        validate() {
            return (this.current?.html?.length ? true : false);
        },

        // validateInput() {
        //     let valid = true;
        //     let inputs = this.current?.usecase?.contextInputs;

        //     if(inputs?.length === 0) {
        //         return false;
        //     }

        //     inputs.forEach((item)=>{
        //         let ref = this.$refs[item._id];
        //         if(ref.getValue()?.length === 0 || ref.hasError()) {
        //             valid = false;
        //         }
        //         // console.log(ref);
        //     });

        //     this.allowSubmit = valid;
        //     return valid;
        // }, 



        async init() {
            // this.validateInput();
            // let inputs = $('.ai-history .context-input');
            //     inputs.each((i, item)=>{
            //         $(item).on('keyup', ()=>{
            //             this.validateInput();
            //         });
            //     });
            
            let opportunityId = this.$store.getters.currentOpportunity?.ID;

            // NOTE: if there is not a current opportunity, look in "opportunities" by "name"
            if(!opportunityId && this.$route.params?.opportunity) {
                this.$store.getters.opportunities.map((item)=>{
                    if(item.name == this.$route.params?.opportunity) {
                        opportunityId = item.ID;
                    }
                });
            }

            this.results = await this.$root.AIGetHistory(opportunityId);
        },

    },

    mounted() {
        setTimeout(()=> {
            this.init();
        }, 500);
    }
}
</script>

<style>

.modal-dialog.modal-AIHistory {
    max-width: 720px;
}

.ai-history {
    max-height: 650px;
    min-height: 650px;
    overflow: hidden;
}


.ai-history .usecase-entry .text-content {
    font-size: 16px;
}


.ai-history .usecase-entry .text-content h1, 
.ai-history .usecase-entry .text-content h2, 
.ai-history .usecase-entry .text-content h3, 
.ai-history .usecase-entry .text-content h4, 
.ai-history .usecase-entry .text-content h5, 
.ai-history .usecase-entry .text-content ul, 
.ai-history .usecase-entry .text-content ol, 
.ai-history .usecase-entry .text-content pre, 
.ai-history .usecase-entry .text-content p {
    margin-bottom: 0.8rem;
}

.ai-history .usecase-entry .text-content h1 { font-size: 2em; }
.ai-history .usecase-entry .text-content h2 { font-size: 1.5em; }
.ai-history .usecase-entry .text-content h3 { font-size: 1.17em; }
.ai-history .usecase-entry .text-content h4 { font-size: 1em; }
.ai-history .usecase-entry .text-content h5 { font-size: 0.83em; }


.ai-history .results {
    width: 100%;
}


.ai-history .usecase-entry {
    transition: all 0.15s;
    background-color: #f5f9ff;
}

.ai-history .usecase-entry:hover {
    border-color: #aaa;
    box-shadow: 0 4px 8px #404fff2b !important;
}

.ai-history .usecase-entry.active {
    border-color: #404fff !important;
    background-color: #404fff;
    color: #fff;
}
.ai-history .usecase-entry .text-content {
    cursor: pointer;
    border: dotted 1px transparent;
    pointer-events: none;
}

.ai-history .usecase-entry:hover .text-content {
    border-color: rgba(255, 255, 255, 0.2);
}
.ai-history .usecase-entry:hover .text-content .material-icons-outlined {
    font-size: 28px !important;
}



/* .ai-history .usecase-entry .btn-select-strategy {
    display: inline-block;
}

.ai-history .usecase-entry.active .btn-select-strategy {
    display: none;
}

.ai-history .usecase-entry .btn-deselect-strategy {
    display: none;
}

.ai-history .usecase-entry.active .btn-deselect-strategy {
    display: inline-block;
} */



/* buttons: delete */
.ai-history .usecase-entry .btn-delete-strategy {
    color: var(--secondary);
}
.ai-history .usecase-entry .btn-delete-strategy:hover {
    color: var(--danger) !important;
}
/* buttons: select */
.ai-history .usecase-entry .btn-select-strategy {
    display: inline-block;
    background-color: #dceaff;
}
.ai-history .usecase-entry .btn-select-strategy:hover {
    color: #fff;
    background-color: #404fff;
}
.ai-history .usecase-entry.active .btn-select-strategy {
    display: none;
}
/* buttons: deselect */
.ai-history .usecase-entry .btn-deselect-strategy {
    display: none;
}

.ai-history .usecase-entry.active .btn-deselect-strategy {
    display: inline-block;
}



</style>